import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import { LoadableImage } from '../../../../../../Components';
import { LoadableImageEnum } from '../../../../../../Enums';
import { CopyToClipboard } from '../../../../../../ReusableComponents/UtilityComponents';

const translationPath = 'Users';

const UserSlider = ({ item, index }) => {
  const { t } = useTranslation('UsersView', {
    keyPrefix: translationPath,
  });
  return (
    <div className='sliderUser'>
      <div className='users-card-component-wrapper'>
        <div className='users-card-wrapper' key={`userCardRef${index + 1}`}>
          <div className='cards-wrapper'>
            <div className='cards-header'>
              <div>
                <LoadableImage
                  type={LoadableImageEnum.div.key}
                  classes='user-cover-image'
                  alt={t(`user-image`)}
                />
              </div>
              <div className='d-flex-column'>
                <div className='px-2 item-wrapper name-wrapper'>
                  {item.userId && (
                    <div className='copyId textcard'>
                      <div className='user-id'>
                        <span className='itemid'>
                          {t(`ID`)}
                          {' : '}
                        </span>
                      </div>
                      <Tooltip title={t(`copy`)}>
                        <CopyToClipboard
                          data={item && item.userId}
                          childrenData={item && item.userId}
                        />
                      </Tooltip>
                    </div>
                  )}
                  <span className='item-header'>{item && item.fullName}</span>
                </div>
                <div className='item-wrapper'>
                  <span className='item-header px-2 textcard'>
                    {t(`Username`)}:
                  </span>
                  <span className='item-body'>{item && item.userName}</span>
                </div>
                {item.userTypes && item.userTypes.length > 0 && (
                  <div className='item-wrapper'>
                    <span className='item-body c-secondary'>
                      {item.userTypes &&
                        item.userTypes.map((subItem, subIndex) => (
                          <span
                            key={`userRolesRef${(subIndex + 1) * (index + 1)}`}
                          >
                            {subItem && subItem.rolesName}
                            {subIndex < (item && item.userTypes.length - 1) && (
                              <span className='pr-1'>,</span>
                            )}
                          </span>
                        ))}
                    </span>
                  </div>
                )}
                {item.policy && (
                  <div>
                    <div className='item-wrapper'>
                      <span className='item-body c-success'>
                        <span>{item?.policy?.policyName}</span>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='cards-body'>
              <div className='item-wrapper'>
                <span className='item-header'>
                  <span className='mdi mdi-phone px-2 icon' />
                </span>
                <span className='item-body'>
                  {(item && item.phoneNumber) || t(`N/A`)}{' '}
                </span>
              </div>
              <div className='item-wrapper flex-nowrap'>
                <div className='texts-truncate d-flex'>
                  <span className='item-header'>
                    <span className='mdi mdi-email-outline px-2 icon' />
                  </span>
                  <span
                    className='item-body texts-truncate d-inline-block'
                    title={(item && item.email) || t(`N/A`)}
                  >
                    {(item && item.email) || t(`N/A`)}
                  </span>
                </div>
              </div>
              <div className='item-wrapper'>
                <span className='item-header'>
                  <span className='mdi mdi-whatsapp px-2 icon' />
                </span>
                <span className='item-body'>
                  {(item && item.whatsAppNumber) || t(`N/A`)}
                </span>
              </div>
              <div className='item-wrapper'>
                <span className='item-header'>
                  <span className='mdi mdi-map-marker px-2 icon' />
                  <span className='textcard'>
                    {t(`nationality`)}:
                  </span>
                </span>
                <span className='item-body'>
                  {(item && item.nationalityName) || t(`N/A`)}
                </span>
              </div>
              <div className='item-wrapper'>
                <span className='item-header'>
                  <span className='mdi mdi-calendar-blank px-2 icon' />
                  <span className='textcard'>
                    {t(`register`)}:
                  </span>
                </span>
                <span className='item-body'>
                  {(item.createdOn &&
                    moment(item && item.createdOn)
                      .locale(i18next.language)
                      .format('DD/MM/YYYY')) ||
                    t(`N/A`)}
                </span>
              </div>

              <div className='item-wrapper'>
                <span className='item-header'>
                  <span className='mdi mdi-account-check px-2 icon' />
                  <span className='textcard'>
                    {' '}
                    {t(`Status`)}:
                  </span>
                </span>
                <span className='item-body'>
                  {item && item.userStatus === 'Active'
                    ? t(`Active`)
                    : (item && item.userStatus === 'Pending'
                        ? t(`Pending`)
                        : t(`Deactive`)) ||
                      t(`N/A`)}
                  <span
                    className={
                      item && item.userStatus === 'Active'
                        ? 'state-online '
                        : 'state-offline '
                    }
                  />
                </span>
              </div>
              <div className='item-wrapper'>
                <span className='item-header'>
                  <span className='mdi mdi-database px-2 icon'>
                    <span className='textcard'>
                      {t(`data-source`)}:
                    </span>
                    <span className='item-body'>
                      {(item && item.dataSource) || t(`N/A`)}
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserSlider;
