import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
} from '@material-ui/core';
import { PostTeamServices } from '../../../../../../Services/Team';
import { showSuccess } from '../../../../../../Helper';
import { XeleAutocomplete } from '../../../../../../Components';
import { OrganizationUserSearch } from '../../../../../../Services/userServices';

export const AddTeamTreeDialog = (props) => {
  const searchTimer = useRef(null);
  const { t } = useTranslation('TeamView');
  const [teamName, setTeamName] = React.useState('');
  const [usersData, setUsersData] = React.useState([]);
  const [selectedTeamManager, setSelectedTeamManager] = React.useState(null);
  const [isLoading, setIsLoading] = useState({ users: false });

  const handleSave = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await PostTeamServices({
      teamsName: teamName,
      businessGroupsId: parseInt(props.businessGroupsid),
      managerId: selectedTeamManager && selectedTeamManager.id,
    });
    props.close() || setTeamName('');
    props.reloadData();
    showSuccess(t('AddTeamDialog.NotificationAddTeam'));
  };

  const getUsers = async (name) => {
    setIsLoading((loading) => ({ ...loading, users: true }));
    const res = await OrganizationUserSearch({ name });
    if (res && res.result) {
      setUsersData(res.result);
      setIsLoading((loading) => ({ ...loading, users: false }));
    }
  };

  useEffect(() => {
    getUsers('');
  }, []);

  return (
    <Dialog
      className='add-team-dialog-wrapper'
      open={props.open}
      onClose={props.close}
      aria-labelledby='form-dialog-title'
    >
      <form noValidate onSubmit={handleSave}>
        <DialogTitle id='form-dialog-title'>
          {t('AddTeamDialog.BusinessGroupin')}
          {' '}
          {props.name}
        </DialogTitle>
        <DialogContent>
          <Grid container className='input-wrapper'>
            <Grid item xs={12} className='mb-3'>
              <label className='label-wrapper'>
                {t('AddTeamDialog.TeamName')}
              </label>
              <div className='text-field-wrapper'>
                <TextField
                  className='inputs theme-solid  theme-form-builder'
                  id='name'
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                  fullWidth
                  size='small'
                  variant='outlined'
                />
              </div>
            </Grid>
            <Grid>
              <div className='team-manager-wrapper'>
                <XeleAutocomplete
                  isLoading={isLoading.users}
                  labelValue={t('AddTeamDialog.TeamManager')}
                  multiple={false}
                  data={usersData || []}
                  value={selectedTeamManager}
                  displayLabel={(option) => option.fullName || ''}
                  withoutSearchButton
                  onInputKeyUp={(e) => {
                    const newValue = e.target.value;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      getUsers(newValue);
                    }, 700);
                  }}
                  onChange={(event, newValue) => {
                    setSelectedTeamManager(newValue);
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.close || setTeamName('')}
            className='btns theme-solid bg-cancel'
          >
            {t('AddTeamDialog.Cancel')}
          </Button>
          <Button
            disabled={teamName === ''}
            onClick={handleSave}
            variant='contained'
            className='btns theme-solid'
          >
            {t('AddTeamDialog.Add')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
