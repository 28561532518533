import React, { Children, useCallback, useEffect, useState } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { NotFoundLayout } from '../../Layouts/NotFoundLayout/NotFoundLayout';

export const SwitchRoute = ({ routes }) => {
  const [route, setRoute] = useState(null);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const defaultRoute = routes.find((f) => f.default);
    setRoute({ ...defaultRoute });

    if (getIsAllowed(defaultRoute?.roles ?? [])) setRoute({ ...defaultRoute });
    else {
      const rArray = routes.sort((a, b) => a.id - b.id);
      rArray.forEach((aRoute) => {
        if (getIsAllowed(aRoute.roles) || !(aRoute.roles?.length > 0)) {
          setRoute(aRoute);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes]);

  const getIsAllowed = useCallback(
    (roles) =>
      roles.length === 0 ||
      roles.some(
        (permission) =>
          loginResponse?.permissions?.findIndex(
            (item) => item.permissionsId === permission.permissionsId
          ) !== -1
      ),
    [loginResponse]
  );
  useEffect(() => {
    if (loginResponse?.userId && !location.pathname.startsWith('/home')) {
      navigate('/home');
    } else if (
      !loginResponse?.userId &&
      location.pathname.startsWith('/home')
    ) {
      navigate('/account/login');
    }
  }, [loginResponse?.userId]);
  return (
    <>
      {route && (
        <Routes>
          {Children.toArray(
            routes.map((value) => {
              if (!value.isRoute) return null;
              if (
                value.roles &&
                Array.isArray(value.roles) &&
                value.authorize &&
                getIsAllowed(value.roles)
              ) {
                return (
                  <Route path={value.path} element={<value.component />} />
                );
              } else if (!value.authorize) {
                return (
                  <Route path={value.path} element={<value.component />} />
                );
              } else if (
                (!value.roles ||
                  !Array.isArray(value.roles) ||
                  value.roles.length === 0) &&
                value.authorize
              ) {
                return (
                  <Route path={value.path} element={<value.component />} />
                );
              }
              return null;
            })
          )}
          <Route
            path={'*'}
            element={<Navigate to={route?.layout + route.path} />}
          />
          {/* <Route path='*' element={<NotFoundLayout />} /> */}
        </Routes>
      )}
    </>
  );
};

SwitchRoute.propTypes = {
  routes: PropTypes.array.isRequired,
};
