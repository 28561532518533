import React, { useCallback, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import './ShareTemplatesDialog.scss';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  ButtonBase,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import { Spinner } from '../../Components';
import {
  getPublicDownloadableLink,
  showError,
  showSuccess,
} from '../../Helper';
import ShareViaEmailForm from './DialogForms/ShareViaEmailForm';
import { PickMethodForm } from './DialogForms/PickMethodForm';
import ShareViaWhatsAppForm from './DialogForms/ShareViaWhatsAppForm';
import { config } from '../../config';
import { ShareMethodsDataEnum } from '../../Enums';
import { UploadPdfFileTemplate } from '../../Services/DynamicTemplateFormServices';

export const ShareTemplatesDialog = ({
  theme,
  open,
  onSave,
  close,
  templateId,
  unitId,
  unitIds,
  owners,
}) => {
  const parentTranslationPath = 'UnitsProfileManagementView';
  const translationPath = '';
  const { t } = useTranslation([parentTranslationPath, 'Shared'],  {
    keyPrefix: translationPath,
  });
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  const pathName = window.location.pathname.split('/home/')[1].split('/')[0];
  const isSaleModule =
    pathName === 'lead-owner-units-sale' ||
    pathName === 'units-sales' ||
    pathName === 'zero-matching-sale' ||
    pathName === 'listing-shortage';
  const isLeaseModule =
    pathName === 'lead-owner-units-lease' ||
    pathName === 'units-lease' ||
    pathName === 'zero-matching-lease';

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [selected, setSelected] = useReducer(reducer, {
    shareVia: null,
    initialWhatsappMsgBody: '',
    whatsappMsgBody: null,
    isUnitOwnerChecked: false,
    recipient: {
      Email: {
        leads: [],
        systemUsers: [],
      },
      WhatsApp: {
        lead: null,
        systemUser: null,
      },
    },
  });

  const getLeadsWithMissingEmails = () => {
    const leadList = selected.recipient?.Email?.leads
      ? selected.recipient?.Email?.leads.filter(
          (item) => !item.contactEmailAddress
        )
      : [];

    if (leadList.length != 0) {
      const leadNamesList = leadList.map(
        (item) => item.lead?.contact_name?.name
      );
      const leadNamesString = leadNamesList.reduce(
        (result, item) => result + item + ' , ',
        ''
      );
      return leadNamesString;
    }
  };

  const getRecipientWithMissingWhatsAppNo = () => {
    const isNumberExist =
      selected.recipient?.WhatsApp?.systemUser?.whatsAppNumber ||
      selected.recipient?.WhatsApp?.lead?.contactWhatsApp;
    return !isNumberExist;
  };

  const getUsersWithMissingEmails = () => {
    const usersList = selected.recipient?.Email?.systemUsers
      ? selected.recipient?.Email?.systemUsers.filter((item) => !item.email)
      : [];

    if (usersList.length != 0) {
      const userNamesList = usersList.map((item) => item.fullName);
      const userNamesString = userNamesList.reduce(
        (result, item) => result + item + ' , ',
        ''
      );
      return userNamesString;
    }
  };

  const WhatsAppFormSchema = Joi.object({
    recipient: Joi.any()
      .custom((value, helpers) => {
        if (
          !selected.recipient?.WhatsApp?.lead &&
          !selected.recipient?.WhatsApp?.systemUser
        )
          return helpers.error('state.required');

        if (getRecipientWithMissingWhatsAppNo())
          return helpers.error('recipient-With-Missing-WhatsAppNo-error');

        return value;
      })
      .messages({
        'state.required': `Recipient is required (one at least)`,
        'recipient-With-Missing-WhatsAppNo-error': `There Are recipient With Missing WhatsApp Number`,
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(selected);

  const EmailFormSchema = Joi.object({
    recipient: Joi.any()
      .custom((value, helpers) => {
        if (
          selected.recipient?.Email?.leads?.length === 0 &&
          selected.recipient?.Email?.systemUsers?.length === 0 &&
          !selected.isUnitOwnerChecked
        )
          return helpers.error('state.required');

        if (getLeadsWithMissingEmails() || getUsersWithMissingEmails())
          return helpers.error('recipient-With-Missing-Email-error');

        return value;
      })
      .messages({
        'state.required': `Recipient is required (one at least)`,
        'recipient-With-Missing-Email-error': `There Are recipient With Missing Email Address`,
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(selected);

  const saveHandler = async (event) => {
    event.preventDefault();

    if (
      selected.shareVia?.key === ShareMethodsDataEnum.Email.key &&
      EmailFormSchema?.error?.message
    ) {
      showError(t(`${EmailFormSchema.error.message}`));
      return;
    } else if (
      selected.shareVia?.key === ShareMethodsDataEnum.WhatsApp.key &&
      WhatsAppFormSchema?.error?.message
    ) {
      showError(t(`${WhatsAppFormSchema.error.message}`));
      return;
    }

    if (selected.shareVia?.key === ShareMethodsDataEnum.Email.key)
      shareViaEmail();
    else if (selected.shareVia?.key === ShareMethodsDataEnum.WhatsApp.key)
      shareViaWhatsApp();
  };

  const getWhatsAppDocUrl = async () => {
    setIsLoadingSpinner(true);

    const res = await UploadPdfFileTemplate({
      unitId,
      templateId,
      unitOperationTypeId: 430,
    });
    if (!(res && res.status && res.status !== 200)) {
      setIsLoadingSpinner(false);
      const fileId = typeof res === 'string' ? res : '';
      const URL = fileId ? getPublicDownloadableLink(fileId) : '';
      const URLWithComment = `\nPlease find the document you requested here: \n\n ${URL}`;
      return URLWithComment;
    }
    setIsLoadingSpinner(false);
  };

  const shareViaWhatsApp = async () => {
    const WhatsAppMsg =
      selected.whatsappMsgBody || selected.initialWhatsappMsgBody || '';
    const UrlEncodedMessage = encodeURIComponent(WhatsAppMsg);
    const whatsAppNumber =
      selected.recipient?.WhatsApp?.systemUser?.whatsAppNumber ||
      selected.recipient?.WhatsApp?.lead?.contactWhatsApp ||
      null;
    const UrlToWhatsApp = `https://wa.me/${whatsAppNumber}?text=${UrlEncodedMessage}`;

    window.open(UrlToWhatsApp);
  };

  const getShareAPIBody = () => {
    const mappedSystemUsers =
      (selected.recipient?.Email?.systemUsers &&
        selected.recipient?.Email?.systemUsers.map((item) => item?.id)) ||
      [];
    const mappedLeads =
      (selected.recipient?.Email?.leads &&
        selected.recipient?.Email?.leads.map((item) => item?.leadId)) ||
      [];

    const leadIdSale = owners.leadOwner?.leadId || owners.unitOwner?.id;
    const leadIdLease =
      owners.leaseLeadOwner?.leadId || owners.leaseUnitOwner?.id;

    if (selected.isUnitOwnerChecked && isSaleModule)
      mappedLeads.push(leadIdSale);
    else if (selected.isUnitOwnerChecked && isLeaseModule)
      mappedLeads.push(leadIdLease);

    return {
      unitId: unitId,
      unitIds: unitIds,
      systemTemplateId: templateId,
      systemUsersId: mappedSystemUsers,
      leadsId: mappedLeads,
      serverName: config.server_name,
      apiKey: config.SendKey,
    };
  };

  const shareViaEmail = async () => {
    setIsLoadingSpinner(true);

    const body = getShareAPIBody();
    const response = unitIds ? '' : [];
    if (response === true) {
      showSuccess(t('template-email-sent-successfully'));
      onSave();
    } else {
      showError(t('template-email-sent-failed'));
    }

    setIsLoadingSpinner(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          close();
        }}
        className='Parking-management-dialog-wrapper ShareTemplateDialog'
      >
        <Spinner isActive={isLoadingSpinner} isAbsolute />
        <form noValidate onSubmit={saveHandler}>
          <DialogTitle id='alert-dialog-slide-title'>
            <span>{t(`share-template`)}</span>
            <span className='fz-14'>{`${
              selected.shareVia ? ` (Via ${selected.shareVia?.key} )` : ''
            }`}</span>
          </DialogTitle>
          <DialogContent>
            {!selected.shareVia ? (
              <PickMethodForm
                activeCard={selected.shareVia}
                onActiveCardChange={(item) =>
                  setSelected({ id: 'shareVia', value: item })
                }
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            ) : null}
            {selected.shareVia?.key === ShareMethodsDataEnum.Email.key ? (
              <ShareViaEmailForm
                selected={selected}
                setSelected={setSelected}
                unitOwner={owners.unitOwner}
                leaseUnitOwner={owners.leaseUnitOwner}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            ) : null}
            {selected.shareVia?.key === ShareMethodsDataEnum.WhatsApp.key ? (
              <ShareViaWhatsAppForm
                selected={selected}
                setSelected={setSelected}
                getWhatsAppDocUrl={getWhatsAppDocUrl}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            ) : null}
          </DialogContent>
          {selected.shareVia ? (
            <DialogActions>
              <ButtonBase
                onClick={() => close()}
                className={`btns ${
                  theme === 'primary'
                    ? 'theme-solid bg-cancel'
                    : 'theme-propx outlined'
                }`}
              >
                {t(`cancel`)}
              </ButtonBase>
              <ButtonBase
                className={`btns ${
                  theme === 'primary' ? 'theme-solid' : 'theme-propx solid'
                }`}
                type='submit'
              >
                {t(`send`)}
              </ButtonBase>
            </DialogActions>
          ) : null}
        </form>
      </Dialog>
    </div>
  );
};
ShareTemplatesDialog.propTypes = {
  activeItem: PropTypes.instanceOf(Object),
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isedit: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(['primary', 'propx']),
};
ShareTemplatesDialog.defaultProps = {
  activeItem: null,
  theme: 'primary',
};
