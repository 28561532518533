import React, { forwardRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { XelePagination } from '../PaginationComponent/XelePagination';

const Transition = forwardRef((props, ref) => (
  <Slide direction='down' ref={ref} {...props} />
));
Transition.displayName = 'DialogComponent';
export const XeleDialog = ({
  isOpen,
  wrapperClasses = '',
  titleClasses = '',
  contentClasses = '',
  footerClasses = '',
  closeClasses = 'btns-icon theme-solid bg-danger mx-2 mb-2',
  nextPreviousWrapperClasses = 'd-flex-v-center-h-between flex-wrap p-2',
  previousClasses = 'btns theme-outline',
  nextClasses = 'btns theme-solid bg-secondary',
  saveCancelWrapperClasses = 'd-flex-v-center-h-end flex-wrap p-2',
  cancelWrapperClasses = 'd-inline-flex-center',
  cancelClasses = 'btns theme-transparent c-primary',
  saveWrapperClasses = 'd-inline-flex-center',
  saveClasses = 'btns theme-solid bg-primary',
  titleTextClasses = '',
  titleText,
  saveText = 'save',
  cancelText = 'cancel',
  closeIsDisabled = false,
  previousIsDisabled = false,
  nextIsDisabled = false,
  cancelIsDisabled = false,
  saveIsDisabled = false,
  dialogTitle,
  dialogContent,
  dialogActions,
  translationPath = '',
  parentTranslationPath = '',
  translationPathShared = 'Shared:',
  maxWidth = 'md',
  saveType = 'submit',
  pageIndex = 0,
  totalCount = 0,
  pageSize = 25,
  disableBackdropClick = false,
  reSize = false,
  dialogPaginationWrapperClasses = '',
  formWrapperClasses = '',
  subTitleClass = '',
  subTitle = '',
  SmothMove,
  onCloseClicked,
  onNextClicked,
  onPreviousClicked,
  onCancelClicked,
  onSaveClicked,
  onSubmit,
  onPageIndexChanged,
  onPageSizeChanged,
  onSizeClicked,
  onSaveAndContinueClicked,
  disableEnforceFocus = false,
  headingIcon,
  cancelType,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared'], {
    keyPrefix: translationPath,
  });

  return (
    <Dialog
      className={`dialog-wrapper ${wrapperClasses}`}
      onClose={(_event, reason) => {
        if (disableBackdropClick && reason !== 'backdropClick') {
          onCloseClicked?.();
          onCancelClicked?.();
        }
      }}
      open={isOpen}
      disableEnforceFocus={disableEnforceFocus}
      maxWidth={maxWidth}
      TransitionComponent={(SmothMove && Transition) || undefined}
      transitionDuration={(SmothMove && 700) || undefined}
    >
      <form
        id={titleText || dialogTitle || subTitle || titleClasses || ' '}
        className={`w-100 ${formWrapperClasses}`}
        noValidate
        onSubmit={onSubmit}
      >
        <DialogTitle className={`dialog-title-wrapper ${titleClasses}`}>
          {(!dialogTitle && (
            <>
              {!headingIcon ? (
                <span className={`dialog-title-text ${titleTextClasses}`}>
                  {t(`${titleText}`)}
                  {subTitle && (
                    <span className={subTitleClass}>{subTitle}</span>
                  )}
                </span>
              ) : (
                headingIcon
              )}
              <div>
                {reSize && (
                  <Button
                    className={`close-btn-wrapper ${SizeClasses}`}
                    onClick={onSizeClicked}
                  >
                    <span className='mdi mdi-move-resize-variant' />
                  </Button>
                )}
                {onCloseClicked && (
                  <Button
                    className={`close-btn-wrapper ${closeClasses}`}
                    onClick={onCloseClicked}
                    disabled={closeIsDisabled}
                  >
                    <span className='mdi mdi-close' />
                  </Button>
                )}
              </div>
            </>
          )) ||
            dialogTitle}
        </DialogTitle>
        <DialogContent className={`dialog-content-wrapper ${contentClasses}`}>
          {dialogContent || undefined}
        </DialogContent>
        <DialogActions className={`dialog-footer-wrapper ${footerClasses}`}>
          {dialogActions ||
            ((onNextClicked || onPreviousClicked) && (
              <div
                className={`next-previous-wrapper ${nextPreviousWrapperClasses}`}
              >
                {(onPreviousClicked || previousType === 'submit') && (
                  <Button
                    className={previousClasses}
                    type={previousType}
                    onClick={onPreviousClicked}
                    disabled={previousIsDisabled}
                  >
                    <span>{t(`${translationPathShared}back`)}</span>
                  </Button>
                )}
                {(onNextClicked || nextType === 'submit') && (
                  <Button
                    className={nextClasses}
                    type={nextType}
                    onClick={onNextClicked}
                    disabled={nextIsDisabled}
                  >
                    <span>{t(`${translationPathShared}next`)}</span>
                  </Button>
                )}
              </div>
            ))}
          {(onPageIndexChanged || onPageSizeChanged) && (
            <div
              className={`dialog-pagination-wrapper ${dialogPaginationWrapperClasses}`}
            >
              <div className='dialog-pagination-content'>
                <XelePagination
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  totalCount={totalCount}
                  perPageText='row-per-page'
                  translationPath=''
                  parentTranslationPath='Shared'
                  onPageIndexChanged={onPageIndexChanged}
                  onPageSizeChanged={onPageSizeChanged}
                />
              </div>
            </div>
          )}
          {dialogActions ||
            ((onCancelClicked || onSaveClicked) && (
              <div
                className={`save-cancel-wrapper ${saveCancelWrapperClasses}`}
              >
                {(onCancelClicked || cancelType === 'submit') && (
                  <div className={`cancel-wrapper ${cancelWrapperClasses}`}>
                    <Button
                      className={`cancel-btn-wrapper ${cancelClasses}`}
                      type={cancelType}
                      id={t(
                        `${cancelText || null}_${
                          translationPath || null
                        }cancel-btn`
                      )}
                      onClick={onCancelClicked}
                      disabled={cancelIsDisabled}
                    >
                      <span>
                        {t(
                          `${
                            (cancelText === 'cancel' &&
                              translationPathShared) ||
                            translationPath
                          }${cancelText}`
                        )}
                      </span>
                    </Button>
                  </div>
                )}

                {onSaveAndContinueClicked && (
                  <div className={`save-wrapper ${saveWrapperClasses}`}>
                    <Button
                      className={`save-btn-wrapper ${cancelClasses}`}
                      type={saveType}
                      id={t(`${translationPath || null}_save-btn`)}
                      onClick={() => onSaveAndContinueClicked(true)}
                      disabled={saveIsDisabled}
                    >
                      <span>{t(`save and Continue`)}</span>
                    </Button>
                  </div>
                )}
                {(onSaveClicked || saveType === 'submit') && (
                  <div className={`save-wrapper ${saveWrapperClasses}`}>
                    <Button
                      className={`save-btn-wrapper ${saveClasses}`}
                      type={saveType}
                      id={t(
                        `${saveText || null}${
                          translationPath || null
                        }_save-btn_submit`
                      )}
                      onClick={onSaveClicked}
                      disabled={saveIsDisabled}
                    >
                      <span>
                        {t(
                          `${
                            (saveText === 'save' && translationPathShared) ||
                            translationPath
                          }${saveText}`
                        )}
                      </span>
                    </Button>
                  </div>
                )}
              </div>
            ))}
        </DialogActions>
      </form>
    </Dialog>
  );
};

XeleDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  wrapperClasses: PropTypes.string,
  formWrapperClasses: PropTypes.string,
  titleClasses: PropTypes.string,
  contentClasses: PropTypes.string,
  footerClasses: PropTypes.string,
  closeClasses: PropTypes.string,
  SizeClasses: PropTypes.string,
  nextPreviousWrapperClasses: PropTypes.string,
  previousClasses: PropTypes.string,
  nextClasses: PropTypes.string,
  cancelWrapperClasses: PropTypes.string,
  cancelClasses: PropTypes.string,
  saveWrapperClasses: PropTypes.string,
  saveCancelWrapperClasses: PropTypes.string,
  saveClasses: PropTypes.string,
  titleTextClasses: PropTypes.string,
  titleText: PropTypes.string,
  saveText: PropTypes.string,
  cancelText: PropTypes.string,
  closeIsDisabled: PropTypes.bool,
  previousIsDisabled: PropTypes.bool,
  nextIsDisabled: PropTypes.bool,
  cancelIsDisabled: PropTypes.bool,
  saveIsDisabled: PropTypes.bool,
  dialogTitle: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
    PropTypes.node,
  ]),
  dialogContent: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
    PropTypes.node,
  ]),
  dialogActions: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
    PropTypes.node,
  ]),
  onCloseClicked: PropTypes.func,
  onNextClicked: PropTypes.func,
  onPreviousClicked: PropTypes.func,
  onCancelClicked: PropTypes.func,
  onSaveClicked: PropTypes.func,
  onSubmit: PropTypes.func,
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  translationPathShared: PropTypes.string,
  maxWidth: PropTypes.string,
  saveType: PropTypes.string,
  cancelType: PropTypes.string,
  nextType: PropTypes.string,
  previousType: PropTypes.string,
  subTitleClass: PropTypes.string,
  subTitle: PropTypes.string,
  pageIndex: PropTypes.number,
  totalCount: PropTypes.number,
  pageSize: PropTypes.number,
  onPageIndexChanged: PropTypes.func,
  onPageSizeChanged: PropTypes.func,
  dialogPaginationWrapperClasses: PropTypes.string,
  SmothMove: PropTypes.bool,
  reSize: PropTypes.bool,
  onSizeClicked: PropTypes.func,
  disableBackdropClick: PropTypes.bool,
  disableEnforceFocus: PropTypes.bool,
  onSaveAndContinueClicked: PropTypes.func,
};
