import { Fab, Tooltip } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { XeleInput } from '../../../../Components';
import { useTitle } from '../../../../Hooks';
import {
  listTreContacts,
  listTree,
  listTree2,
  listTreeProperties,
} from '../ReportsBuilderEnums/ReportsBuilderEnums';
import { GetAllFormFieldsByFormId } from '../../../../Services/FormsServices';

export const TreeFieldsView = ({
  translationPath,
  parentTranslationPath,
  setDragItem,
  setdrageisnide,
  Data = [],
}) => {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });
  useTitle(t(`ReportsBuilder`));
  const [isCollapseData, setisCollapseData] = useState(false);
  // const [isLoadingProgress, setIsLoadingProgress] = useState(false);
  const [state, setState] = useState([]);
  const [inshalstate, setinshalstate] = useState([]);
  const [openclose, setOpenClose] = useState([]);
  const LoacalColumusResultTemp =
    JSON.parse(localStorage.getItem('ColumusResultTemp')) || [];

  const getAllFormFieldsByFormIdAPI = useCallback(
    async (id, name, tableName, formId) => {
      // setIsLoadingProgress(true);
      // eslint-disable-next-line prefer-const
      let result = await GetAllFormFieldsByFormId(id);

      if (!(result && result.status && result.status !== 200)) {
        if (id !== (73 && 72)) {
          listTree.map((data) =>
            result.push({
              formFieldId: id,
              formFieldName: data.formFieldName,
              formFieldKey: data.formFieldKey,
              searchableKey: data.searchableKey,
              displayPath: data.displayPath,
              uiWidgetType: data.uiWidgetType,
              formFieldTitle: `${data.formFieldTitle} ${name || ''}`,
            })
          );
        }
        if (id === 1 || id === 2) {
          listTreContacts.map((data) =>
            result.push({
              formFieldId: id,
              formFieldName: data.formFieldName,
              formFieldKey: data.formFieldKey,
              searchableKey: data.searchableKey,
              displayPath: data.displayPath,
              uiWidgetType: data.uiWidgetType,
              formFieldTitle: `${data.formFieldTitle} ${name || ''}`,
            })
          );
        }
        // eslint-disable-next-line no-constant-condition
        if (id === 3) {
          listTreeProperties.map((data) =>
            result.push({
              formFieldId: id,
              formFieldName: data.formFieldName,
              formFieldKey: data.formFieldKey,
              searchableKey: data.searchableKey,
              displayPath: data.displayPath,
              uiWidgetType: data.uiWidgetType,
              formFieldTitle: `${data.formFieldTitle} ${name || ''}`,
            })
          );
        }

        if (id === 7 || id === 6) {
          listTree2.map((data) =>
            result.push({
              formFieldId: id,
              formFieldName: data.formFieldName,
              formFieldKey: data.formFieldKey,
              searchableKey: data.searchableKey,
              displayPath: data.displayPath,
              uiWidgetType: data.uiWidgetType,
              formFieldTitle: `${data.formFieldTitle} ${name || ''}`,
            })
          );
        }
        setState((items) => {
          items.push({
            id,
            name,
            result,
            tableName,
          });
          return [...items];
        });
        setinshalstate((items) => {
          items.push({
            id,
            name,
            result,
            tableName,
          });
          return [...items];
        });
      } else setState([]);
      // setIsLoadingProgress(false);
    },
    []
  );
  // eslint-disable-next-line consistent-return
  const onClickedArrow = (name) => {
    if (name) {
      const Response = inshalstate.filter((value) => value.name === name);
      return Response;
    }
  };

  const onDropHandler = (items, tableName) => {
    const returnedTarget = Object.assign(items, { tableName });
    setdrageisnide?.(false);
    setDragItem?.(returnedTarget);
  };

  const onclick = (item, index) => {
    if (!openclose.includes(index)) {
      setOpenClose((data) => {
        data.push(index);
        return [...data];
      });
    } else {
      setOpenClose((data) => {
        data.splice(data.indexOf(index), 1);
        return [...data];
      });
    }
  };

  const countSheep = (limit) => {
    for (let i = 1; i < limit; i += 1) {
      if (openclose.find((element) => element === i + 1)) return;
      setOpenClose((data) => {
        data.push(i);
        return [...data];
      });
    }
  };

  const CollapseData = () => {
    setisCollapseData(!isCollapseData);
    if (!isCollapseData) countSheep(Data && Data.systemReportForms.length + 1);
    else setOpenClose([]);
  };

  const searchHandler = (value) => {
    const Array = [];
    // eslint-disable-next-line no-unused-expressions
    state &&
      state.map((item) => {
        Array.push({
          id: item.id,
          name: item.name,
          tableName: item.tableName,
          result: item.result
            .filter(
              (x) =>
                x.uiWidgetType !== 'UploadFiles' && x.formFieldKey !== 'map'
            )
            .filter((word) =>
              word.formFieldTitle.toLowerCase().includes(value.toLowerCase())
            ),
        });
      });
    setinshalstate(Array || []);
    countSheep(Data && Data.systemReportForms.length + 1);
  };
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions, react/prop-types, max-len
    Data &&
      Data.systemReportForms.map((item) =>
        getAllFormFieldsByFormIdAPI(
          item.formId,
          item.form,
          item.tableName,
          item.formId
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data]);

  const ItemIfExists = (items, tableName, name) => {
    const finalvalue =
      LoacalColumusResultTemp.findIndex(
        (item) => item.searchKey === (items.searchableKey || items.displayPath)
      ) !== -1 &&
      LoacalColumusResultTemp.find(
        (item) =>
          item.searchKey === (items.searchableKey || items.displayPath) &&
          tableName.toLowerCase() === item.category.toLowerCase()
      );

    return finalvalue;
  };

  return (
    <div className='TreeFieldsView-container'>
      <div className='d-flex-column-center mt-2 title-h'>
        <span className='main-title '> {t(`Fields`)}</span>
      </div>
      <XeleInput
        idRef='DatasystemReportFormsRef'
        inputPlaceholder={t(`search-fields`)}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        onKeyUp={(e) => searchHandler(e.target.value)}
        startAdornment={<span className='mdi mdi-magnify mdi-24px c-gray' />}
        endAdornment={
          <div className='mx-2'>
            {/* {
              ((!isCollapseData) && (

                <span className='mdi mdi-arrow-expand ' onClick={CollapseData} />
              )) ||
              <span className='mdi mdi-arrow-collapse' onClick={CollapseData} />
            } */}

            {(!isCollapseData && (
              <Tooltip size='small' title={t(`open`)} onClick={CollapseData}>
                <Fab>
                  <span className='mdi mdi-arrow-expand ' />
                </Fab>
              </Tooltip>
            )) || (
              <Tooltip size='small' title={t(`close`)} onClick={CollapseData}>
                <Fab>
                  <span className='mdi mdi-arrow-collapse' />
                </Fab>
              </Tooltip>
            )}
          </div>
        }
      />
      <div />
      <div className='d-flex-column TreeFieldsView'>
        <div className='c-danger'>{t(`drag-and-drop`)}</div>
        <div>
          <p> {t(`Caption-Here`)} </p>
          <ul className='tree'>
            {(
              (Data &&
                Data.systemReportForms &&
                Data.systemReportForms.filter(
                  (value, index, self) =>
                    index === self.findIndex((t) => t.formId === value.formId)
                )) ||
              []
            ).map((item, index) => (
              <li
                onClick={() => onclick(item.name, index + 1)}
                index={index + 1}
              >
                {' '}
                {openclose.find((element) => element === index + 1) ? (
                  <span className='mdi mdi-minus-box-outline pointercursor' />
                ) : (
                  <span className='mdi mdi-plus-box-outline pointercursor' />
                )}
                <span className='form-name'>
                  {' '}
                  {(item && item.form) || 'N/A'}
                </span>{' '}
                <ul
                  className={`item-in ${
                    openclose.find((element) => element === index + 1)
                      ? 'is-open'
                      : ''
                  }`}
                >
                  {onClickedArrow(item.form || '').map(
                    (itemsss) =>
                      (itemsss &&
                        itemsss.result &&
                        itemsss.result.length === 0 && (
                          <div className='no-data' disabled>
                            {t(`no-data-found-in`)} {item.form}
                          </div>
                        )) ||
                      itemsss.result.map(
                        (itemData, indexs) =>
                          (itemData.uiWidgetType !== 'UploadFiles' &&
                            itemData.formFieldKey !== 'map' &&
                            ((ItemIfExists(
                              itemData,
                              itemsss.tableName,
                              item.form
                            ) && (
                              <li>
                                <span className='formFieldKey displaydrage'>
                                  {' '}
                                  {(itemData.formFieldTitle &&
                                    itemData.formFieldTitle.replace('*', '')) ||
                                    itemData.formFieldName}
                                </span>
                              </li>
                            )) || (
                              <li
                                index={indexs + 1}
                                className='file'
                                draggable
                                onDrag={() =>
                                  onDropHandler(itemData, itemsss.tableName)
                                }
                              >
                                <span className='formFieldKey'>
                                  {' '}
                                  {(itemData.formFieldTitle &&
                                    itemData.formFieldTitle.replace('*', '')) ||
                                    itemData.formFieldName}
                                </span>
                              </li>
                            ))) ||
                          ''
                      )
                  )}
                </ul>{' '}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
TreeFieldsView.propTypes = {
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  setDragItem: PropTypes.func,
  setdrageisnide: PropTypes.func,
  Data: PropTypes.instanceOf(Array),
};
