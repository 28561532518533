import React, { useCallback, useReducer, useRef } from 'react';
import {
  XeleAutocomplete,
  XeleCheckbox,
} from '../../../Components';
import { useTranslation } from 'react-i18next';
import { OrganizationUserSearch } from '../../../Services/userServices';

const ShareViaEmailForm = ({
  unitOwner,
  leaseUnitOwner,
  selected,
  setSelected,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared'],  {
    keyPrefix: translationPath,
  });
  const searchTimer = useRef(null);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [data, setData] = useReducer(reducer, {
    leads: [],
    systemUsers: [],
  });

  const getSystemUsers = async (value) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: value || '',
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({
          id: 'systemUsers',
          value: localValue,
        });
      } else {
        setData({
          id: 'systemUsers',
          value: [],
        });
      }
    }
  };

  return (
    <div className='dialog-content-wrapper'>
      <fieldset className='styled-fieldset d-flex mx-2'>
        <legend className='styled-legend'>
          {t(`send-to`)}
        </legend>
        <div className='dialog-content-item d-flex-column-center'>
          <XeleAutocomplete
            idRef='System-usersRef'
            inputPlaceholder={t(`select`)}
            labelValue={t(`System-users`)}
            selectedValues={selected?.recipient?.Email?.systemUsers || []}
            wrapperClasses='mr-2 my-2'
            data={data.systemUsers || []}
            chipsLabel={(option) => option.fullName || ''}
            withoutSearchButton
            multiple
            displayLabel={(option) => option.fullName || ''}
            renderOption={(option) =>
              ((option.userName || option.fullName) &&
                `${option.fullName} (${option.userName})`) ||
              ''
            }
            onChange={(event, newValue) => {
              const localRecipient = {
                ...selected.recipient,
              };
              localRecipient.Email.systemUsers = newValue;

              setSelected({
                id: 'recipient',
                value: localRecipient,
              });
            }}
            onOpen={() => {
              if (data.systemUsers && data.systemUsers.length === 0)
                getSystemUsers();
            }}
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                getSystemUsers(value);
              }, 1200);
            }}
          />
          <XeleAutocomplete
            idRef='leadsRef'
            inputPlaceholder={t(`select`)}
            labelValue={t(`leads`)}
            selectedValues={selected?.recipient?.Email?.leads || []}
            wrapperClasses='mr-2 my-2'
            data={data.leads || []}
            chipsLabel={(option) =>
              option?.lead?.contact_name?.name ||
              option?.lead?.company_name ||
              ''
            }
            withoutSearchButton
            multiple
            displayLabel={(option) =>
              option?.lead?.contact_name?.name ||
              option?.lead?.company_name ||
              ''
            }
            renderOption={(option) =>
              ((option.userName ||
                option?.lead?.contact_name?.name ||
                option?.lead?.company_name) &&
                `${
                  option?.lead?.contact_name?.name || option?.lead?.company_name
                }`) ||
              ''
            }
            onChange={(event, newValue) => {
              const localRecipient = {
                ...selected.recipient,
              };
              localRecipient.Email.leads = newValue;

              setSelected({
                id: 'recipient',
                value: localRecipient,
              });
            }}
          />
          {(unitOwner || leaseUnitOwner) && (
            <XeleCheckbox
              idRef='unit-owner-IdRef'
              label={`${t(`send-to-unit-owner`)}`}
              themeClass='theme-secondary mt-2'
              singleChecked={selected.isUnitOwnerChecked}
              onSelectedCheckboxChanged={() => {
                setSelected({
                  id: 'isUnitOwnerChecked',
                  value: !selected.isUnitOwnerChecked,
                });
              }}
            />
          )}
        </div>
      </fieldset>
    </div>
  );
};

export default ShareViaEmailForm;
