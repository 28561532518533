import React, { useEffect, useRef, useState } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import Badge from '@material-ui/core/Badge';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import { useTranslation } from 'react-i18next';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
  getDownloadableLink,
  GlobalNavigate,
  setLogoutAction,
} from '../../../../Helper';
import { UserMenuView } from './UserMenu.View';
import { useOnClickOutside } from '../../../../Hubs';
import { NotificationsView } from './Notifications.View';
import { LOGOUt } from '../../../../store/login/Actions';
import { XeleCollapse, LoadableImage } from '../../../../Components';
import NotificationSound from './CRM-notification-sound.mp3';
import { LoadableImageEnum } from '../../../../Enums';
import { Link } from 'react-router-dom';

const FirstLettersExp = /\b(\w)/gm;
const parentTranslationPath = 'HeaderView';
export const Header = ({ headerHeightChanged }) => {
  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();
  const headerRef = useRef(null);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [NewNotification, setNewNotification] = useState(null);
  const userProfileRef = useRef(null);
  const notificationsRef = useRef(null);
  const eventsRef = useRef(null);
  const audioPlayer = useRef(null);
  const [imageReq, setImageReq] = useState(null);
  const [notificationsNumber, setNotificationsNumber] = useState(0);
  const [isOpenMenu, setIsOpenMenu] = useState({
    userProfile: false,
    notifications: false,
    events: false,
  });

  const menuRef = useRef();
  const userProfileClicked = () => {
    setIsOpenMenu({ ...isOpenMenu, userProfile: !isOpenMenu.userProfile });
  };
  const notificationsClicked = () => {
    setIsOpenMenu({ ...isOpenMenu, notifications: !isOpenMenu.notifications });
  };
  const logoutClicked = () => {
    localStorage.removeItem('session');
    dispatch(LOGOUt());
    setTimeout(() => {
      GlobalNavigate('/account/login');
    }, 100);
  };
  setLogoutAction(logoutClicked);
  useOnClickOutside(userProfileRef, () =>
    setIsOpenMenu((item) =>
      item.userProfile
        ? {
            ...item,
            userProfile: false,
          }
        : item
    )
  );
  useOnClickOutside(notificationsRef, () => {
    setIsOpenMenu((item) =>
      item.notifications
        ? {
            ...item,
            notifications: false,
          }
        : item
    );
  });
  useOnClickOutside(eventsRef, () =>
    setIsOpenMenu((item) =>
      item.events
        ? {
            ...item,
            events: false,
          }
        : item
    )
  );
  useEffect(() => {
    headerHeightChanged(headerRef.current.clientHeight);
  }, [headerHeightChanged]);
  useEffect(() => {
    if (loginResponse) setImageReq(loginResponse);
  }, [loginResponse]);

  function playAudio() {
    audioPlayer.current.play();
  }
  useEffect(() => {
    if (NewNotification !== null) playAudio();
  }, [NewNotification]);

  return (
    <div className='header-wrapper' ref={headerRef}>
      <div className='section w-100'>
        <Link to='/home'>
          <span role='img' aria-label={t('PIS-logo')} className='img-logo' />
        </Link>
      </div>
      <div className='section'>
        <div ref={menuRef}></div>
        <div className='p-relative' ref={notificationsRef}>
          <ButtonBase
            className='btns-icon section-conteaner'
            onClick={notificationsClicked}
          >
            <Badge
              badgeContent={+notificationsNumber}
              className='badges'
              max={99}
            >
              <NotificationsIcon
                id='NotificationsIcon_ref'
                className='privet-icon '
              />
            </Badge>
            <audio ref={audioPlayer} src={NotificationSound} />
          </ButtonBase>
          {isOpenMenu && (
            <NotificationsView
              isOpen={isOpenMenu.notifications}
              top={headerRef.current ? headerRef.current.clientHeight : 60}
              getCurrentNotificationNumber={(value) =>
                setNotificationsNumber(value)
              }
              NewNotification={(value) => setNewNotification(value)}
            />
          )}
        </div>
        <div className='p-relative' ref={userProfileRef}>
          <ButtonBase
            className='btns theme-transparent user-button-wrapper'
            id='edit_profile_page_REF'
            onClick={userProfileClicked}
          >
            {loginResponse &&
              loginResponse.fullName &&
              (!imageReq || !imageReq.profileImg) && (
                <Avatar className='avatars-wrapper theme-small'>
                  {loginResponse.fullName.match(FirstLettersExp).join('')}
                </Avatar>
              )}
            {imageReq && imageReq.profileImg && (
              <LoadableImage
                src={getDownloadableLink(imageReq.profileImg)}
                type={LoadableImageEnum.div.key}
                alt={t('user-image')}
                classes='user-image'
              />
            )}
            {imageReq && imageReq.fullName && (
              <div className='user-name-wrapper'>
                <span className='user-name-text'>{imageReq.fullName}</span>
                {isOpenMenu.userProfile === false ? (
                  <span className='mdi mdi-chevron-down mx-2' />
                ) : (
                  <span className='mdi mdi-chevron-up mx-2' />
                )}
              </div>
            )}
          </ButtonBase>
          <XeleCollapse
            isOpen={isOpenMenu.userProfile}
            top={headerRef.current ? headerRef.current.clientHeight : 60}
            component={<UserMenuView logout={logoutClicked} />}
          />
        </div>
      </div>
    </div>
  );
};
Header.propTypes = {
  headerHeightChanged: PropTypes.func.isRequired,
};
