import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import xCloseIcon from '../../assets/images/icons/x-close-dialog.svg';
import './HtmlPreviewDialog.scss';
import { DialogActions, DialogContent, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { XeleDialog, Spinner } from '../../Components';
import { getDownloadableLink, GetParams, showError } from '../../Helper';
import { SidePopupActions } from './SidePopupActions';

export const HtmlPreviewDialog = ({
  isDialogOpen,
  onClose,
  onSave,
  isReadOnly,
  templateId,
  templateName,
  externalHtmlContent,
}) => {
  const parentTranslationPath = 'Shared';
  const { t } = useTranslation(parentTranslationPath);
  const maxHeight = '137px';
  const mediaPrint =
    '@page {margin: 2mm;}@media print {.page-header,.page-header-space {height: 137px;max-height: 137px;}.page-footer,.page-footer-space {height: 137px;max-height: 137px;}.page-footer {position: fixed;bottom: 0;width: 100%;}.page-header {position: fixed;top: 0mm;width: 100%;}.page {page-break-after: always;}thead {display: table-header-group;}tfoot {display: table-footer-group;}button {display: none;}body {margin: 0;}}';

  const unitId = +GetParams('id');
  const HtmlContentRef = useRef('');

  const [HtmlContent, setHtmlContent] = useState('');
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  const [headerAndFooter, setHeaderAndFooter] = useState({
    headerImageId: null,
    footerImageId: null,
  });
  const [sideButtonsData, setSideButtonsData] = useState({
    AddHeader: {
      id: 1,
      key: 'AddHeader',
      label: 'AddHeader',
      mdiIconClass: 'mdi-format-align-bottom',
      onClickHandler: AddHeaderClickHandler,
      isActive: false,
    },
    AddFooter: {
      id: 2,
      key: 'AddFooter',
      label: 'AddFooter',
      mdiIconClass: 'mdi-format-align-top',
      onClickHandler: AddFooterClickHandler,
      isActive: false,
    },
  });

  const activeButtonHandler = (clickedItem, buttonsData) => {
    const clickedItemKey = clickedItem?.key;
    const localButtonsData = {
      ...(buttonsData || {}),
    };

    localButtonsData[clickedItemKey] = {
      ...(clickedItem || {}),
      isActive: !clickedItem.isActive,
    };

    setSideButtonsData(localButtonsData);
  };

  function AddHeaderClickHandler(clickedItem, buttonsData) {
    activeButtonHandler(clickedItem, buttonsData);
  }

  function AddFooterClickHandler(clickedItem, buttonsData) {
    activeButtonHandler(clickedItem, buttonsData);
  }


  const handleReadOnlyTemplate = () => {
    const HtmlWithoutEditAttribute =
      externalHtmlContent && isReadOnly
        ? externalHtmlContent.replaceAll(
            'contenteditable="true"',
            'contenteditable="false"'
          )
        : null;

    setHtmlContent(HtmlWithoutEditAttribute || externalHtmlContent);
  };

  const saveHandler = () => {
    const updatedHtml = HtmlContentRef.current?.innerHTML
      ? HtmlContentRef.current?.innerHTML
      : '';

    onSave(updatedHtml);
  };


  return (
    <div>
      <XeleDialog
        isOpen={isDialogOpen}
        maxWidth='md'
        wrapperClasses='Html-Preview-Dialog d-flex-center'
        dialogTitle={
          <div className='add-task-dialog pb-3 w-100'>
            <div className='d-flex-v-center-h-between'>
              <div className='fw-simi-bold'>{templateName || ''}</div>
              <div className='xCloseIcon pointer' onClick={onClose}>
                <img src={xCloseIcon} />
              </div>
            </div>
          </div>
        }
        dialogContent={
          <>
            <DialogContent>
              <div className='template-wrapper  px-4 relative'>
                <Spinner isActive={isTemplateLoading} />
                {!externalHtmlContent ? (
                  <SidePopupActions buttonsData={sideButtonsData} />
                ) : null}
                <div ref={HtmlContentRef}>
                  <style>{mediaPrint}</style>
                  {sideButtonsData.AddHeader.isActive &&
                    headerAndFooter.headerImageId && (
                      <div className='page-header'>
                        <img
                          alt='HeaderPSITemplet'
                          src={
                            getDownloadableLink(
                              headerAndFooter.headerImageId
                            ) || null
                          }
                          style={{ width: '100%', maxHeight }}
                        />
                      </div>
                    )}
                  <table>
                    {sideButtonsData.AddHeader.isActive &&
                      headerAndFooter.headerImageId && (
                        <thead>
                          <tr>
                            <td>
                              <div className='page-header-space' />
                            </td>
                          </tr>
                        </thead>
                      )}
                    <tbody>
                      <tr>
                        <td>
                          <div
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: HtmlContent }}
                          />
                        </td>
                      </tr>
                    </tbody>
                    {sideButtonsData.AddFooter.isActive &&
                      headerAndFooter.footerImageId && (
                        <tfoot>
                          <tr>
                            <td>
                              <div className='page-footer-space' />
                            </td>
                          </tr>
                        </tfoot>
                      )}
                  </table>
                  {sideButtonsData.AddFooter.isActive &&
                    headerAndFooter.footerImageId && (
                      <div className='page-footer'>
                        <img
                          alt='FooterPSITemplet'
                          src={
                            getDownloadableLink(
                              headerAndFooter.footerImageId
                            ) || null
                          }
                          style={{ width: '100%', maxHeight }}
                        />
                      </div>
                    )}
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <div className='d-flex-center fj-end py-0 pt-3'>
                <ButtonBase
                  onClick={onClose}
                  className='btns theme-propx outlined'
                >
                  {t(`cancel`)}
                </ButtonBase>

                <ButtonBase
                  className='btns theme-propx solid mr-0'
                  onClick={saveHandler}
                  disabled={isReadOnly}
                >
                  {t(`save`)}
                </ButtonBase>
              </div>
            </DialogActions>
          </>
        }
      />
    </div>
  );
};

HtmlPreviewDialog.propTypes = {
  isDialogOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  isReadOnly: PropTypes.bool,
  templateId: PropTypes.number,
  templateName: PropTypes.string,
  externalHtmlContent: PropTypes.string,
};
HtmlPreviewDialog.defaultProps = {
  onClose: () => {},
  onSave: () => {},
  templateName: '',
};
