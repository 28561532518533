import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { XeleDatePicker } from '../../../../Controls';
import {
  TableFilterOperatorsEnum,
  TableFilterTypesEnum,
} from '../../../../../Enums';
import { TableFilterOperatorsComponent } from './TableFilterOperatorsComponent';
import { GlobalAdvanceSearchActions } from '../../../../../store/GlobalAdvanceSearch/GlobalAdvanceSearchActions';
import { useDispatch, useSelector } from 'react-redux';

export const TableFilterDataPickerComponent = ({
  idRef,
  maxDate,
  minDate,
  operators,
  filterKey,
  displayPath,
  searchableKey,
  filterValues,
  onFilterValuesChanged,
  defaultSelectedOperator,
  placeholder,
  parentTranslationPath,
  translationPath,
  setIsClearFiltersClicked,
  isClearFiltersClicked,
  localStorageKey,
  FilterDisabledButton,
}) => {
  const dispatch = useDispatch();
  const advanceSearch = useSelector(
    (state) => state.GlobalAdvanceSearchReducer
  );
  const [searchValue, setSearchValue] = useState(
    (advanceSearch && advanceSearch[localStorageKey]) || null
  );
  const [filterIcon, setFilterIcon] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState(
    defaultSelectedOperator
  );

  const getOperatorValue = useCallback(
    (key) =>
      (Object.values(TableFilterOperatorsEnum).findIndex(
        (item) => item.key === key
      ) !== -1 &&
        Object.values(TableFilterOperatorsEnum).find(
          (item) => item.key === key
        )) ||
      '',
    []
  );

  const clearOperator = () => {
    const searchValueView = advanceSearch && advanceSearch[localStorageKey];
    for (const key in searchValueView) {
      const element = searchValueView[key];
      let elementKey =
        element.searchableKey || element.displayPath || element.input;
      let activeItemKey = searchableKey || displayPath;
      if (elementKey === activeItemKey) delete searchValueView[key];
      dispatch(
        GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
          ...advanceSearch,
          [localStorageKey]:
            searchValueView && Object.keys(searchValueView).length
              ? searchValueView
              : null,
        })
      );
    }
  };

  useEffect(() => {
    const searchValueView = advanceSearch && advanceSearch[localStorageKey];
    const searchKey =
      searchValueView &&
      Object.keys(searchValueView).find(
        (e) => e === searchableKey || e === displayPath
      );
    // setFilterDate(searchValueView && searchValueView[searchKey] && searchValueView[searchKey].value || null);

    if (!searchValueView) {
      setSearchValue(null);
      setFilterDate('');
    }

    const storedFilterValue =
      (searchValueView &&
        searchValueView[searchKey] &&
        searchValueView[searchKey].value) ||
      null;
    if (storedFilterValue) setFilterDate(storedFilterValue);
    else setFilterDate(null);

    const storedFilterOperator =
      (searchValueView &&
        searchValueView[searchKey] &&
        searchValueView[searchKey].operator) ||
      null;
    setFilterIcon(getOperatorValue(storedFilterOperator).icon);
    if (storedFilterOperator) setSelectedOperator(storedFilterOperator);
    else setSelectedOperator(defaultSelectedOperator);

    let isBlank =
      storedFilterOperator === TableFilterOperatorsEnum.isBlank.key ||
      storedFilterOperator === TableFilterOperatorsEnum.isNotBlank.key;
    if (isBlank) setFilterDate(null);
  }, [advanceSearch]);

  useEffect(() => {
    for (const key in searchValue) {
      const element = searchValue[key];
      let isBlank =
        element.operator === TableFilterOperatorsEnum.isBlank.key ||
        element.operator === TableFilterOperatorsEnum.isNotBlank.key;
      if (!element.value && !isBlank) delete searchValue[key];
    }
    localStorageKey &&
      dispatch(
        GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
          ...advanceSearch,
          [localStorageKey]:
            searchValue && Object.keys(searchValue).length ? searchValue : null,
        })
      );
    if (onFilterValuesChanged) onFilterValuesChanged(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (isClearFiltersClicked) {
      setFilterDate(null);
      setIsClearFiltersClicked(false);
      setFilterIcon(null);
      setSearchValue(null);
      setSelectedOperator(defaultSelectedOperator);
    }
  }, [isClearFiltersClicked]);

  return (
    <div className='table-filter-data-picker-wrapper control-wrapper'>
      <XeleDatePicker
        idRef={idRef}
        placeholder={placeholder}
        value={filterDate}
        format='YYYY/MM/DD'
        maxDate={maxDate}
        minDate={minDate}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onDateChanged={(newValue) => {
          if (!filterIcon) setFilterIcon('mdi mdi-equal');
          setFilterDate(newValue);
          if (onFilterValuesChanged) {
            let isBlank =
              selectedOperator === TableFilterOperatorsEnum.isBlank.key ||
              selectedOperator === TableFilterOperatorsEnum.isNotBlank.key;

            if (isBlank && newValue) {
              setSelectedOperator(defaultSelectedOperator);
              setFilterIcon('mdi mdi-equal');
            }
            let searchValueView =
              advanceSearch && advanceSearch[localStorageKey];
            const localFilterValues =
              (filterValues && { ...filterValues }) || {};
            if (filterKey)
              localFilterValues[filterKey] = {
                value:
                  (newValue && moment(newValue).format('YYYY-MM-DD')) || null,
                operator:
                  isBlank && newValue
                    ? defaultSelectedOperator
                    : selectedOperator,
                displayPath,
                searchableKey,
              };
            setSearchValue((item) => ({
              ...item,
              ...searchValueView,
              ...localFilterValues,
            }));
            onFilterValuesChanged(localFilterValues);
          }
        }}
      />
      {/* <DateRangePickerComponent
        onClearClicked={() => setDateFilter(dateRangeDefault)}
        ranges={[dateFilter]}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onDateChanged={(selectedDate) =>
                        setDateFilter({
                          startDate: selectedDate.selection && selectedDate.selection.startDate,
                          endDate: selectedDate.selection && selectedDate.selection.endDate,
                          key: 'selection',
                        })}
      /> */}
      <TableFilterOperatorsComponent
        operators={
          operators || TableFilterTypesEnum.datePicker.defaultOperators
        }
        selectedOperator={selectedOperator}
        filterIcon={filterIcon}
        FilterDisabledButton={FilterDisabledButton}
        onSelectedOperatorChanged={(newValue) => () => {
          setFilterIcon(getOperatorValue(newValue).icon);
          setSelectedOperator(newValue);
          let isBlank =
            newValue === TableFilterOperatorsEnum.isBlank.key ||
            newValue === TableFilterOperatorsEnum.isNotBlank.key;

          if (onFilterValuesChanged && (filterDate || isBlank)) {
            const localFilterValues =
              (filterValues && { ...filterValues }) || {};
            if (filterKey)
              localFilterValues[filterKey] = {
                value:
                  (filterDate && moment(filterDate).format('YYYY-MM-DD')) ||
                  null,
                operator: newValue,
                displayPath,
                searchableKey,
              };
            if (isBlank) {
              if (filterKey) localFilterValues[filterKey].value = null;
              setFilterDate(null);
            }
            setSearchValue(localFilterValues);
            onFilterValuesChanged(localFilterValues);
          }
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        clearOperator={clearOperator}
      />
    </div>
  );
};

TableFilterDataPickerComponent.propTypes = {
  idRef: PropTypes.string.isRequired,
  filterKey: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number,
  ]).isRequired,
  maxDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  filterValues: PropTypes.instanceOf(Object),
  displayPath: PropTypes.string,
  searchableKey: PropTypes.string,
  onFilterValuesChanged: PropTypes.func,
  operators: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOf(
        Object.values(TableFilterOperatorsEnum).map((item) => item.key)
      ),
      isDisabled: PropTypes.bool,
    })
  ),
  defaultSelectedOperator: PropTypes.oneOf(
    Object.values(TableFilterOperatorsEnum).map((item) => item.key)
  ),
  placeholder: PropTypes.string,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  setIsClearFiltersClicked: PropTypes.bool,
  isClearFiltersClicked: PropTypes.bool,
};
TableFilterDataPickerComponent.defaultProps = {
  defaultSelectedOperator:
    TableFilterTypesEnum.datePicker.defaultSelectedOperator,
  maxDate: undefined,
  minDate: undefined,
  displayPath: undefined,
  searchableKey: undefined,
  onFilterValuesChanged: undefined,
  placeholder: 'YYYY/MM/DD',
  filterValues: undefined,
  operators: undefined,
  setIsClearFiltersClicked: false,
  isClearFiltersClicked: false,
};
