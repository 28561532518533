import { lazy } from 'react';

const ReportsBuilderView = lazy(() =>
  import('../../Views/Home').then((module) => ({
    default: module.ReportsBuilderView,
  }))
);
const AddReportsBuilderView = lazy(() =>
  import('../../Views/Home/ReportsBuilderView/AddReportsBuilderView').then(
    (module) => ({ default: module.AddReportsBuilderView })
  )
);
export const ReportsBuilderRoutes = [
  {
    path: '/view',
    name: 'ReportsBuilderg:reportsbuilder',
    component: ReportsBuilderView,
    layout: '/home/Reports-Builder',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'ReportsBuilderg:reportsbuilder',
        isDisabled: true,
        route: '/home/Reports-Builder/view',
        groupName: 'Reports',
      },
    ],
  },
  {
    path: '/add',
    name: 'ReportsBuilderg:addreportsbuilder',
    component: AddReportsBuilderView,
    layout: '/home/Reports-Builder',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'ReportsBuilderg:reportsbuilder',
        isDisabled: false,
        route: '/home/Reports-Builder/view',
        groupName: 'Reports',
      },
      {
        name: 'ReportsBuilderg:add-Reports',
        isDisabled: true,
        route: '/home/country/add',
      },
    ],
  },
  {
    path: '/edit',
    name: 'ReportsBuilderg:editreportsbuilder',
    component: AddReportsBuilderView,
    layout: '/home/Reports-Builder',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'ReportsBuilderg:reportsbuilder',
        isDisabled: false,
        route: '/home/Reports-Builder/view',
        groupName: 'Reports',
      },
      {
        name: 'ReportsBuilderg:edit-Reports',
        isDisabled: true,
        route: '/home/country/edit',
      },
    ],
  },
];
