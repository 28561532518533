import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DefaultImagesEnum } from '../../../../Enums';
import excellogo from '../../../../assets/images/defaults/cloudupload.gif';
import lode from '../../../../assets/images/defaults/otp_icon_upload.gif';

export const BoxThemeComponent = ({
  file,
  defaultImage,
  uploadRef,
  isDragOver = true,
  accept,
  multiple,
  parentTranslationPath,
  translationPathShared,
  dropHereText,
  uploadedspiner,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  return (
    <>
      {isDragOver && (
        <img
          src={excellogo || undefined}
          className='box-theme-image img-iplode-drop'
          alt='i'
        />
      )}
      {!isDragOver && (
        <div
          className={`box-theme-component-wrapper${
            (isDragOver && ' drag-over') || ''
          }`}
        >
          {(!uploadedspiner && (
            <div className='dropzone-wrapper'>
              {file && file.status === 'uploading' && (
                <span className='mdi mdi-reload mdi-spin as-overlay-spinner' />
              )}
              <div className='drop-here'>
                {t(`${translationPathShared}${dropHereText}`)}{' '}
                <span className='mdi mdi-cloud-upload px-2' />
              </div>
              <Button
                className='btns theme-solid mx-0 mt-2'
                onClick={() => uploadRef.current.click()}
              >
                <span className='mx-3 text-nowrap'>
                  {(accept &&
                    accept.includes('image') &&
                    t(
                      `${translationPathShared}${
                        (multiple && 'browse-files') || 'browse-files'
                      }`
                    )) ||
                    t(
                      `${translationPathShared}${
                        (multiple && 'browse-files') || 'browse-file'
                      }`
                    )}
                </span>
              </Button>
            </div>
          )) || (
            <img
              src={lode || undefined}
              className='box-theme-image-loders'
              alt='i'
            />
          )}
        </div>
      )}
    </>
  );
};

BoxThemeComponent.propTypes = {
  file: PropTypes.instanceOf(Object),
  isDragOver: PropTypes.bool.isRequired,
  parentTranslationPath: PropTypes.string,
  translationPathShared: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  dropHereText: PropTypes.string.isRequired,
  multiple: PropTypes.bool.isRequired,
  WithoutDefaultImg: PropTypes.bool,
  defaultImage: PropTypes.oneOf(
    Object.values(DefaultImagesEnum).map((item) => item.key)
  ).isRequired,
  fileDeleted: PropTypes.func.isRequired,
  uploadRef: PropTypes.instanceOf(Object).isRequired,
};
BoxThemeComponent.defaultProps = {
  file: undefined,
  parentTranslationPath: '',
  WithoutDefaultImg: false,
};
