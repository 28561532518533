import React, {
  useCallback,
  useState,
  useRef,
  memo,
  useEffect,
  Children,
} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TableActions } from '../../Enums';
import {
  bottomBoxComponentUpdate,
  opnfouces,
  ProtectCopiedFun,
  ProtectCopiedFundelete,
} from '../../Helper';
import { useOnClickOutside } from '../../Hubs';
import { useEventListener, useLocalStorage } from '../../Hooks';
import { XelePagination } from '../PaginationComponent/XelePagination';
import { XelePopover } from '../PopoverComponent/XelePopover';
import { XeleCheckbox } from '../Controls';
import { TableFilterComponent } from './Sections';
import { Spinner } from '..';
import { config } from '../../config';
import { useSuccessDialog } from '../../SharedComponents';

const XeleTableComponent = ({
  tableOptions = {
    itemsPerPageOptions: [10, 20, 25, 50, 100],
    tableSize: 'small',
    dateFormat: null,
    sortFrom: 1, // 1:front, 2:do nothing only send that it change
  },
  parentTranslationPath = '',
  translationPath = '',
  data = [],
  activePage,
  totalItems,
  activePageChanged, // Not assigning a default function
  itemsPerPageChanged, // Not assigning a default function
  itemsPerPage = 10,
  headerData = [],
  footerData = [],
  selectAllOptions = null,
  sortColumnClicked, // Not assigning a default function
  defaultActions = [
    {
      enum: TableActions.openFile.key,
      isDisabled: false,
      externalComponent: null,
    },
    {
      enum: TableActions.editText.key,
      isDisabled: false,
      externalComponent: null,
    },
    {
      enum: TableActions.phoneSolid.key,
      isDisabled: false,
      externalComponent: null,
    },
    {
      enum: TableActions.emailSolid.key,
      isDisabled: false,
      externalComponent: null,
    },
    {
      enum: TableActions.dotsHorizontal.key,
      isDisabled: false,
      externalComponent: null,
    },
  ],
  actionsOptions = {
    classes: '',
    isDisabled: false,
    isReverceDisabled: false,
  },
  focusedRowChanged, // Not assigning a default function
  isOriginalPagination = false,
  onPageIndexChanged, // Not assigning a default function
  onPageSizeChanged, // Not assigning a default function
  dateFormat = 'YYYY-MM-DD',
  externalPopoverComponent = undefined,
  isSellectAllDisabled = false,
  bodyRowId = 'bodyRowRef',
  tableFilterClassWrapper = '',
  filterValues,
  onFilterValuesChanged, // Not assigning a default function
  filterData,
  textInputPlaceholder = 'search',
  isWithFilter = false,
  setSortBy, // Not assigning a default function
  isWithCheckboxColumn = false,
  FilterDisabledButton,
  optionFilterName,
  isWithOrderBy = false,
  isClearFiltersClicked = false,
  setIsClearFiltersClicked, // Not assigning a default function
  isLoading = false,
  localStorageKey,
  insideForm,
  HideSellectAll,
  TotalSelect = false,
  highlightedRowKey,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared'], {
    keyPrefix: translationPath,
  });
  const { isOpen } = useSuccessDialog();
  const [language] = useLocalStorage('localization', {
    currentLanguage: 'en',
    isRtl: false,
  });
  const buttonRef = useRef(null);
  if (config.DisableRightClicking)
    document.addEventListener('contextmenu', (event) => event.preventDefault());
  const [currentOrderById, setCurrentOrderById] = useState(-1);
  const [actionsAttachedWith, setActionsMenuAttachedWith] = useState(null);
  const isInitialData = useRef(false);
  const [activeItem, setActiveItem] = useState(null);

  const highlightLastClickedRow = useCallback(() => {
    if (highlightedRowKey && data && data.length > 0) {
      const localStorageValue = localStorage.getItem('GlobalHighlightedRows');
      const globalHighlightRows = localStorageValue
        ? JSON.parse(localStorageValue)
        : {};
      const highlightedRowID = globalHighlightRows[highlightedRowKey] || null;

      const rowIndex = highlightedRowID
        ? data.findIndex((item) => item && item['id'] === highlightedRowID)
        : -1;
      const item = data.find((item) => item && item['id'] === highlightedRowID);

      if (rowIndex != -1) {
        setActiveItem(item);
        setFocusedRow(() => {
          focusedRowChanged(rowIndex, item);
          return rowIndex;
        });

        scrollToHighlightedRow(rowIndex);
      } else {
        setActiveItem(null);
        setFocusedRow(() => {
          focusedRowChanged(-1);
          return -1;
        });
      }
      isInitialData.current = true;
    }
  }, [data, highlightedRowKey]);

  const changeStoredHighlightRow = (rowItem) => {
    const localStorageValue = localStorage.getItem('GlobalHighlightedRows');
    const globalHighlightRows = localStorageValue
      ? JSON.parse(localStorageValue)
      : {};

    if (highlightedRowKey) {
      const newGlobalHighlightedRows = {
        ...globalHighlightRows,
        [highlightedRowKey]: rowItem ? rowItem['id'] : null,
      };
      localStorage.setItem(
        'GlobalHighlightedRows',
        JSON.stringify(newGlobalHighlightedRows)
      );
    }
  };

  const scrollToHighlightedRow = (rowIndex) => {
    const highlightedRow = document.getElementById(
      `${bodyRowId}${rowIndex * (activePage + 1)}`
    );
    const scrollIntoViewOptions = {
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    };

    if (highlightedRow) highlightedRow.scrollIntoView(scrollIntoViewOptions);
  };

  const slider = document.getElementById('TheScrollFun');
  let mouseDown = false;
  let startX;
  let scrollLeft;
  if (slider) {
    const startDragging = (e) => {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    const stopDragging = () => {
      mouseDown = false;
    };

    slider.addEventListener('mousemove', (e) => {
      e.preventDefault();
      if (!mouseDown) return;
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    });

    slider.addEventListener('mousedown', startDragging, false);
    slider.addEventListener('mouseup', stopDragging, false);
    slider.addEventListener('mouseleave', stopDragging, false);
  }
  const [currentOrderDirection, setCurrentOrderDirection] = useState('desc');
  const tableRef = useRef(null);
  const [focusedRow, setFocusedRow] = useState(-1);
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  };
  const getComparator = (order, orderBy) =>
    order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  const createSortHandler = useCallback(
    (columnItem) => () => {
      if (setSortBy && !isWithOrderBy) {
        let fieldType = 'string';
        if (columnItem.isDate) fieldType = 'date';
        else if (columnItem.isNumber) fieldType = 'number';

        if (columnItem.searchableKey)
          setSortBy({
            filterBy:
              columnItem.searchableKey[0].toUpperCase() +
                columnItem.searchableKey.slice(1) || null,
            orderBy: currentOrderDirection === 'desc' ? 2 : 1,
            fieldType,
          });
        else if (columnItem.input)
          setSortBy({
            filterBy:
              columnItem.input[0].toUpperCase() + columnItem.input.slice(1) ||
              null,
            orderBy: currentOrderDirection === 'desc' ? 2 : 1,
            fieldType,
          });
      } else if (setSortBy && isWithOrderBy) {
        let fieldType = 'string';
        if (columnItem.isDate) fieldType = 'date';
        else if (columnItem.isNumber) fieldType = 'number';

        if (columnItem.searchableKey) {
          setSortBy({
            orderBy: columnItem.key,
            searchKey: columnItem.displayPath,
            orderType: currentOrderDirection === 'desc' ? 2 : 1,
            orderByCategory: columnItem.category,
          });
        }
      }
      if (!tableOptions) return;
      setCurrentOrderDirection((item) => (item === 'desc' ? 'asc' : 'desc'));
      setCurrentOrderById(columnItem.id);
      if (tableOptions.sortFrom === 2)
        sortColumnClicked(columnItem.id, currentOrderDirection);
    },
    [currentOrderDirection, tableOptions, sortColumnClicked]
  );
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };
  const getCurrentSelectedItem = useCallback(
    (itemIndex) => {
      if (!selectAllOptions) return false;
      return (
        (selectAllOptions.selectedRows &&
          selectAllOptions.selectedRows.findIndex(
            (item) => item === itemIndex + activePage
          ) !== -1) ||
        selectAllOptions.isSelectAll
      );
    },
    [activePage, selectAllOptions]
  );

  const getCurrentDisabledItem = useCallback(
    (itemIndex) =>
      selectAllOptions
        ? selectAllOptions.disabledRows.findIndex(
            (item) => item === itemIndex + activePage
          )
        : -1,
    [activePage, selectAllOptions]
  );
  const handleClose = useCallback(() => {
    setActionsMenuAttachedWith(null);
  }, []);
  const handleOpen = useCallback(() => {
    setActionsMenuAttachedWith(buttonRef);
  }, []);
  const bodyRowClicked = useCallback(
    (rowIndex, item) => {
      setActiveItem(item);
      if (focusedRow === -1 || focusedRow !== rowIndex) {
        changeStoredHighlightRow(item);
        setFocusedRow(() => {
          focusedRowChanged?.(rowIndex, item);
          return rowIndex;
        });
      } else {
        changeStoredHighlightRow();
        setFocusedRow(() => {
          focusedRowChanged?.(-1);
          return -1;
        });
      }
    },
    [focusedRow, focusedRowChanged]
  );
  const getTableActionValue = (key) =>
    Object.values(TableActions).find((item) => item.key === key);
  const getSortDataName = () => {
    const currentHeader = headerData.find(
      (item) => item.id === currentOrderById
    );
    if (currentHeader) return currentHeader.input;
    return null;
  };
  useOnClickOutside(tableRef, (e) => {
    handleClose();
    if (
      e.target &&
      e.target.className &&
      typeof e.target.className === 'string' &&
      (e.target.className.includes('actions-wrapper') ||
        e.target.className.includes('table-action-btn') ||
        e.target.className.includes('table-action-icon'))
    )
      return;
    if (focusedRow !== -1 && !highlightedRowKey) setFocusedRow(-1);
  });
  const TableTooltip = () => {
    const [lastClientWidth, setLastClientWidth] = useState();

    const rowRef = document.getElementById(
      `${bodyRowId}${focusedRow * (activePage + 1)}`
    );
    const [coordinations, setCoordinations] = useState(
      () => (rowRef && rowRef.getBoundingClientRect()) || { right: 0 }
    );
    const [tableCoordinations, setTableCoordinations] = useState(
      (tableRef &&
        tableRef.current &&
        tableRef.current.getBoundingClientRect()) || { right: 0 }
    );
    const timer = useRef(null);
    const innerTime = useRef(null);
    const sizeRecheckTime = useRef(null);
    const [opacity, setOpacity] = useState(0);
    const [transition, setTransition] = useState(null);

    const updateCoordinations = useCallback(() => {
      setOpacity(0);
      setTransition(null);
      if (timer.current !== null) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setTransition('opacity 0.3s linear');
        if (innerTime.current !== null) clearTimeout(innerTime.current);
        innerTime.current = setTimeout(() => {
          // do something
          if (rowRef) setCoordinations(rowRef.getBoundingClientRect());
          setOpacity(1);
        }, 300);
      }, 299);
    }, [rowRef]);
    const sizeRechecker = useCallback(() => {
      if (rowRef && rowRef.current) {
        if (
          !lastClientWidth ||
          rowRef.current.clientWidth !== lastClientWidth
        ) {
          setLastClientWidth(rowRef.current.clientWidth);
          if (sizeRecheckTime.current !== null)
            clearTimeout(sizeRecheckTime.current);
          sizeRecheckTime.current = setTimeout(() => {
            if (
              !lastClientWidth ||
              lastClientWidth !== rowRef.current.clientWidth
            )
              sizeRechecker();
          }, 50);
        }
      }
    }, [lastClientWidth, rowRef]);
    const updateSize = useCallback(() => {
      setOpacity(0);
      setTransition(null);
      if (tableRef.current)
        setTableCoordinations(tableRef.current.getBoundingClientRect());
      if (timer.current !== null) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setTransition('opacity 0.3s linear');
        innerTime.current = setTimeout(() => {
          // do something
          if (rowRef)
            setTableCoordinations(tableRef.current.getBoundingClientRect());
          setOpacity(1);
        }, 249);
      }, 250);
      sizeRechecker();
    }, [rowRef, sizeRechecker]);

    useEventListener('resize', () => updateSize());
    useEventListener('scroll', updateCoordinations);
    useEffect(() => {
      updateSize();
    }, [updateSize]);
    useEffect(
      () => () => {
        if (timer.current !== null) clearTimeout(timer.current);
        if (sizeRecheckTime.current !== null)
          clearTimeout(sizeRecheckTime.current);
        if (innerTime.current !== null) clearTimeout(innerTime.current);
      },
      []
    );
    return (
      <div
        className={`table-actions-wrapper ${actionsOptions.classes || ''}`}
        id={`table-actions-wrapper ${actionsOptions.classes || ''}`}
        style={{
          top: Math.abs(coordinations.top - tableCoordinations.top),
          right: !language.isRtl ? 0 : 'initial',
          left: language.isRtl ? 0 : 'initial',
          height: coordinations.height,
          opacity,
          transition,
        }}
      >
        {(actionsOptions.actions || defaultActions || []).map((item, index) => (
          <React.Fragment key={`tableAction${index + 1}`}>
            {(item.enum !== TableActions.externalComponent &&
              (!item.isHidden ||
                (item.isHidden && item.isHidden(activeItem))) && (
                <Tooltip title={item.title && item.title}>
                  <Button
                    ref={buttonRef}
                    id={`Action${item.enum || null}_${item.label || null}_${
                      window.location.pathname || null
                    }`}
                    className={getTableActionValue(item.enum).buttonClasses}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      if (actionsOptions && actionsOptions.onActionClicked) {
                        if (item.enum === 'dotsHorizontal') handleOpen();
                        actionsOptions.onActionClicked(
                          item.enum,
                          data[focusedRow],
                          focusedRow,
                          event
                        );
                      }
                    }}
                    disabled={item.isDisabled || actionsOptions.isDisabled}
                  >
                    <span className={getTableActionValue(item.enum).icon} />
                    {getTableActionValue(item.enum).label && (
                      <span
                        className={getTableActionValue(item.enum).labelClasses}
                      >
                        {t(getTableActionValue(item.enum).label)}
                      </span>
                    )}
                  </Button>
                </Tooltip>
              )) ||
              (actionsOptions.externalComponent &&
                actionsOptions.externalComponent(item)) ||
              undefined}
            {(actionsAttachedWith && (
              <XelePopover
                idRef='actionsPopRef'
                handleClose={handleClose}
                attachedWith={actionsAttachedWith.current}
                popoverClasses='popover-contact-prefernces'
                component={externalPopoverComponent}
              />
            )) ||
              undefined}
          </React.Fragment>
        ))}
      </div>
    );
  };
  const dataReturn = (dataItem, columnPath) => {
    if (!dataItem) return '';
    if (!columnPath) return (typeof dataItem !== 'object' && dataItem) || '';
    if (!columnPath.includes('.')) return dataItem[columnPath];
    let a = dataItem;
    columnPath.split('.').map((item) => {
      if (a) a = a[item];
      return item;
    });
    return a;
  };
  useEffect(() => {
    if (!insideForm && !isOriginalPagination && onPageIndexChanged) {
      bottomBoxComponentUpdate(
        <XelePagination
          pageIndex={activePage}
          pageSize={itemsPerPage}
          totalCount={totalItems}
          perPageText='row-per-page'
          translationPath=''
          parentTranslationPath='Shared'
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }
  });
  useEffect(
    () => () => {
      if (!insideForm) bottomBoxComponentUpdate(null);
    },
    []
  );
  useEffect(() => {
    if (!isInitialData.current) highlightLastClickedRow();
  }, [highlightLastClickedRow]);

  return (
    <div
      className='w-100 table-responsive'
      ref={tableRef}
      onMouseEnter={ProtectCopiedFun}
      onMouseLeave={ProtectCopiedFundelete}
      onFocus={opnfouces}
    >
      <TableContainer className='TheScrollFunParent' id='TheScrollFun'>
        {!isOpen && <Spinner isActive={isLoading || false} />}
        <Table
          className='table-wrapper'
          aria-labelledby='tableTitle'
          size={tableOptions.tableSize} // 'small' or 'medium'
          aria-label='enhanced table'
        >
          <TableHead>
            <TableRow>
              {/* {isCollapsed && <TableCell></TableCell>} */}
              {selectAllOptions && (
                <TableCell padding='checkbox'>
                  {!isSellectAllDisabled && (
                    <div className='d-flex '>
                      {' '}
                      {!HideSellectAll && (
                        <XeleCheckbox
                          idRef='tableSelectAllRef'
                          singleIndeterminate={
                            selectAllOptions.selectedRows &&
                            selectAllOptions.selectedRows.length > 0 &&
                            selectAllOptions.selectedRows.length < totalItems &&
                            !selectAllOptions.isSelectAll
                          }
                          singleChecked={
                            (totalItems > 0 &&
                              selectAllOptions.selectedRows &&
                              selectAllOptions.selectedRows.length ===
                                totalItems) ||
                            selectAllOptions.isSelectAll
                          }
                          isDisabled={selectAllOptions.isDisableAll}
                          onSelectedCheckboxClicked={
                            selectAllOptions.onSelectAllClicked
                          }
                        />
                      )}
                      {TotalSelect !== false && TotalSelect >= 0 && (
                        <div className='d-flex-center fw-simi-bold'>
                          <span>
                            {t('Shared:Select')} : {TotalSelect || 0}
                          </span>
                        </div>
                      )}
                      {TotalSelect === false && (
                        <div className='d-flex-center fw-simi-bold'>
                          <span>
                            {t('Shared:Select')} :{' '}
                            {selectAllOptions?.selectedRows?.length || 0}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </TableCell>
              )}
              {Children.toArray(
                headerData
                  .filter((column) => !column.isHidden)
                  .map((item, index) => (
                    <TableCell
                      key={`headerCell${index + 1}`}
                      sortDirection={
                        item.isSortable && currentOrderById === item.id
                          ? currentOrderDirection
                          : false
                      }
                    >
                      {item.isSortable ? (
                        <TableSortLabel
                          active={currentOrderById === item.id}
                          direction={
                            currentOrderById === item.id
                              ? currentOrderDirection
                              : 'desc'
                          }
                          onClick={createSortHandler(item)}
                        >
                          {t(`${item.label}`)}
                        </TableSortLabel>
                      ) : (
                        t(`${item.label}`)
                      )}
                    </TableCell>
                  ))
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isWithFilter && (
              <TableFilterComponent
                tableFilterClassWrapper={tableFilterClassWrapper}
                filterValues={filterValues}
                onFilterValuesChanged={onFilterValuesChanged}
                filterData={filterData}
                FilterDisabledButton={FilterDisabledButton}
                isWithCheckboxColumn={
                  (selectAllOptions && selectAllOptions.withCheckAll) ||
                  isWithCheckboxColumn
                }
                textInputPlaceholder={textInputPlaceholder}
                optionFilterName={optionFilterName}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                isClearFiltersClicked={isClearFiltersClicked}
                setIsClearFiltersClicked={setIsClearFiltersClicked}
                localStorageKey={localStorageKey}
              />
            )}
            {data
              .slice(
                data.length <= itemsPerPage ? 0 : activePage * itemsPerPage,
                data.length <= itemsPerPage
                  ? itemsPerPage
                  : activePage * itemsPerPage + itemsPerPage
              )
              .map((row, rowIndex) => {
                const isItemSelected = getCurrentSelectedItem(rowIndex);
                const isItemDisabled = getCurrentDisabledItem(rowIndex) !== -1;
                return Children.toArray(
                  <React.Fragment key={`bodyRow${rowIndex * (activePage + 1)}`}>
                    <TableRow
                      role='checkbox'
                      aria-checked={
                        (selectAllOptions &&
                          selectAllOptions.getIsSelected &&
                          selectAllOptions.getIsSelected(row, rowIndex)) ||
                        isItemSelected
                      }
                      tabIndex={-1}
                      selected={
                        (selectAllOptions &&
                          selectAllOptions.getIsSelected &&
                          selectAllOptions.getIsSelected(row, rowIndex)) ||
                        isItemSelected
                      }
                      id={`${bodyRowId}${rowIndex * (activePage + 1)}`}
                      onClick={(event) => {
                        event.stopPropagation();
                        bodyRowClicked(rowIndex, row);
                      }}
                      className={
                        rowIndex === focusedRow ? 'table-row-overlay' : ''
                      }
                    >
                      {selectAllOptions ? (
                        <TableCell padding='checkbox'>
                          <XeleCheckbox
                            idRef={`tableSelectRef${rowIndex + 1}`}
                            singleChecked={
                              (selectAllOptions &&
                                selectAllOptions.getIsSelected &&
                                selectAllOptions.getIsSelected(
                                  row,
                                  rowIndex
                                )) ||
                              isItemSelected ||
                              false
                            }
                            isDisabled={
                              selectAllOptions.isDisableAll ||
                              (selectAllOptions.getIsDisabled &&
                                selectAllOptions.getIsDisabled(
                                  row,
                                  rowIndex
                                )) ||
                              isItemDisabled
                            }
                            onSelectedCheckboxClicked={(event) => {
                              event.stopPropagation();
                              selectAllOptions.onSelectClicked(row, rowIndex);
                            }}
                          />
                        </TableCell>
                      ) : null}
                      {headerData.length > 0 &&
                        headerData
                          .filter((column) => !column.isHidden)
                          .map((column, columnIndex) => (
                            <TableCell
                              key={`bodyColumn${
                                columnIndex * (activePage + 1) + rowIndex
                              }`}
                              className={column.cellClasses || ''}
                            >
                              {(column.isDate &&
                                ((dataReturn(row, column.input) &&
                                  moment(dataReturn(row, column.input)).format(
                                    column.dateFormat ||
                                      tableOptions.dateFormat ||
                                      dateFormat
                                  )) ||
                                  '')) ||
                                (column.component &&
                                  column.component(
                                    row,
                                    rowIndex,
                                    column,
                                    columnIndex
                                  )) ||
                                dataReturn(row, column.input)}
                            </TableCell>
                          ))}
                    </TableRow>
                  </React.Fragment>
                );
              })}
          </TableBody>
          {footerData && footerData.length > 0 && (
            <TableFooter className='footer-wrapper'>
              <TableRow>
                {footerData.map((item, index) => (
                  <TableCell
                    colSpan={item.colSpan}
                    key={`footerCell${index + 1}`}
                  >
                    {(item.component && item.component(item, index)) ||
                      item.value}
                  </TableCell>
                ))}
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      {(actionsOptions || defaultActions) &&
        (actionsOptions.actions || defaultActions).length > 0 &&
        !actionsOptions.isDisabled &&
        focusedRow !== -1 && <TableTooltip />}
      {isOriginalPagination && (
        <TablePagination
          rowsPerPageOptions={tableOptions.itemsPerPageOptions}
          component='div'
          count={totalItems}
          rowsPerPage={itemsPerPage}
          page={activePage}
          labelRowsPerPage={t('Shared:tables.rows-per-page')}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${t('Shared:tables.of')} ${
              count !== -1 ? count : to
            }`
          }
          nextIconButtonText={t('Shared:tables.next-page')}
          backIconButtonText={t('Shared:tables.previous-page')}
          nextIconButtonProps={{
            className: 'btns-icon theme-transparent mx-2',
          }}
          backIconButtonProps={{
            className: 'btns-icon theme-transparent mx-2',
          }}
          SelectProps={{
            className: 'select-wrapper',
          }}
          onChangePage={activePageChanged}
          onChangeRowsPerPage={itemsPerPageChanged}
          className='pagination-wrapper'
        />
      )}
    </div>
  );
};
XeleTableComponent.propTypes = {
  setSortBy: PropTypes.func,
  isSellectAllDisabled: PropTypes.bool,
  dateFormat: PropTypes.string,
  tableOptions: PropTypes.shape({
    itemsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    tableSize: PropTypes.string,
    dateFormat: PropTypes.string,
    sortFrom: PropTypes.number,
  }),
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  isOriginalPagination: PropTypes.bool,
  itemsPerPage: PropTypes.number,
  activePageChanged: PropTypes.func,
  itemsPerPageChanged: PropTypes.func,
  defaultActions: PropTypes.arrayOf(
    PropTypes.shape({
      enum: PropTypes.string,
      isDisabled: PropTypes.bool,
      externalComponent: PropTypes.node,
    })
  ),
  actionsOptions: PropTypes.shape({
    classes: PropTypes.string,
    isDisabled: PropTypes.bool,
    isReverceDisabled: PropTypes.bool,
    onActionClicked: PropTypes.func,
    actionsIsDisabledInput: PropTypes.any,
  }),
  selectAllOptions: PropTypes.any,
  isWithCheckboxColumn: PropTypes.bool,
  sortColumnClicked: PropTypes.func,
  headerData: PropTypes.array,
  data: PropTypes.array,
  footerData: PropTypes.array,
  focusedRowChanged: PropTypes.func,
  onPageIndexChanged: PropTypes.func,
  onPageSizeChanged: PropTypes.func,
  externalPopoverComponent: PropTypes.node,
  bodyRowId: PropTypes.string,
  // filter
  tableFilterClassWrapper: PropTypes.string,
  TotalSelect: PropTypes.bool,
  filterData: PropTypes.any,
  filterValues: PropTypes.any,
  onFilterValuesChanged: PropTypes.func,
  textInputPlaceholder: PropTypes.string,
  isWithFilter: PropTypes.bool,
  isWithOrderBy: PropTypes.bool,
  isClearFiltersClicked: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export const XeleTable = memo(XeleTableComponent);
