import { ButtonBase } from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import Joi from 'joi';
import { XeleInput, Spinner } from '../../../Components';
import { getErrorByName, showError, showSuccess } from '../../../Helper';
import {
  atLeastOneNumber,
  charactersLength,
  containUpper,
  containSpecialCharacter,
} from '../../../Utils';
import { ChangePasswordAPI } from '../../../Services/LoginService';

export const ChangePasswordView = ({ onCancelClicked }) => {
  const [state, setState] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [Passwords, setPasswords] = useState({
    ViewOldPassword: '',
    ViewNewassword: '',
    ViewconfirmPassword: '',
  });
  const translationPath = 'userMenuView';
  const { t } = useTranslation('HeaderView',  {
    keyPrefix: translationPath,
  });

  const schema = Joi.object({
    oldPassword:
      Joi.string()
        .required()
        .messages({
          'string.empty': t(`password-is-required`),
        }) || Joi.any(),
    newPassword:
      Joi.string()
        .required()
        .regex(atLeastOneNumber)
        .regex(containSpecialCharacter)
        .regex(containUpper)
        .regex(charactersLength)
        .messages({
          'string.empty': t(`password-is-required`),
          'string.pattern.base': t(`weak-password`),
        }) || Joi.any(),
    confirmPassword:
      Joi.string()
        .required()
        .valid(Joi.ref('newPassword'))
        .messages({
          'any.only': t(`password-not-matched`),
          'string.empty': t(`confirm-password-is-required`),
        }) || Joi.any(),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ChangePassword = useCallback(async () => {
    setIsLoading(true);
    const res = await ChangePasswordAPI(state);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`Change-Password-successfully`));
      setIsLoading(false);
      onCancelClicked();
    } else {
      showError(t(`invalid-password`));
      setIsLoading(false);
    }
  });
  const onClickedold = () => {
    setPasswords({ ...Passwords, ViewOldPassword: !Passwords.ViewOldPassword });
  };
  const onClickednew = () => {
    setPasswords({ ...Passwords, ViewNewassword: !Passwords.ViewNewassword });
  };
  const onClickedViewconfirmPassword = () => {
    setPasswords({
      ...Passwords,
      ViewconfirmPassword: !Passwords.ViewconfirmPassword,
    });
  };

  return (
    <div className='px-2 view-ChangePassword'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='w-100'>
        <XeleInput
          inputPlaceholder={t(`InputsPassword`)}
          labelValue={t(`old-Password`)}
          idRef='oldpasswordRef'
          labelClasses='Requierd-Color'
          value={state.oldPassword}
          isWithError
          wrapperClasses={'mx-2'}
          helperText={getErrorByName(schema, 'oldPassword').message}
          error={getErrorByName(schema, 'oldPassword').error}
          type={!Passwords.ViewOldPassword ? 'password' : ''}
          endAdornment={
            <ButtonBase onClick={onClickedold} className='p-2'>
              {Passwords.ViewOldPassword ? (
                <span className='mdi mdi-eye-outline' />
              ) : (
                <span className='mdi mdi-eye-off-outline' />
              )}
            </ButtonBase>
          }
          onInputChanged={(e) =>
            setState({ ...state, oldPassword: e.target.value })
          }
        />
      </div>
      <div className='d-flex w-100'>
        <XeleInput
          inputPlaceholder={t(`InputsPassword`)}
          labelValue={t(`new-Password`)}
          labelClasses='Requierd-Color'
          idRef='newpasswordRef'
          wrapperClasses={'mx-2'}
          value={state.newPassword}
          DisabledPaste
          DisabledCopy
          isWithError
          helperText={getErrorByName(schema, 'newPassword').message}
          error={getErrorByName(schema, 'newPassword').error}
          endAdornment={
            <ButtonBase onClick={onClickednew} className='p-2'>
              {Passwords.ViewNewassword ? (
                <span className='mdi mdi-eye-outline' />
              ) : (
                <span className='mdi mdi-eye-off-outline' />
              )}
            </ButtonBase>
          }
          type={!Passwords.ViewNewassword ? 'password' : ''}
          onInputChanged={(e) =>
            setState({ ...state, newPassword: e.target.value })
          }
        />
        <XeleInput
          idRef='confirmPasswordRef'
          labelClasses='Requierd-Color'
          inputPlaceholder={t(`confirm-password`)}
          labelValue={t(`confirm-password-req`)}
          DisabledPaste
          DisabledCopy
          value={state.confirmPassword}
          type={!Passwords.ViewconfirmPassword ? 'password' : ''}
          endAdornment={
            <ButtonBase onClick={onClickedViewconfirmPassword} className='p-2'>
              {Passwords.ViewconfirmPassword ? (
                <span className='mdi mdi-eye-outline' />
              ) : (
                <span className='mdi mdi-eye-off-outline' />
              )}
            </ButtonBase>
          }
          isWithError
          isDisabled={state.password === ''}
          helperText={getErrorByName(schema, 'confirmPassword').message}
          error={getErrorByName(schema, 'confirmPassword').error}
          onInputChanged={(e) =>
            setState({ ...state, confirmPassword: e.target.value })
          }
        />
      </div>
      {/* <div className='w-100 pb-130px mb-3'>
        <div className='w-100 pb-130px mb-3 ChangePasswordView-Page-progresses'>
          <ProgressComponet
            inSameLine
            wrapperClasses='ChangePasswordView-wrpaer'
            value={
              (state.newPassword.match(strongStringRegex) && 100) ||
              (state.newPassword.match(mediumStringRegex) && 50) ||
              (state.newPassword !== '' && 25) ||
              0
            }
            isTextColored
            themeClasses='theme-gradient'
            progressText={
              (state.newPassword.match(strongStringRegex) &&
                t(`strong-password`)) ||
              (state.newPassword.match(mediumStringRegex) &&
                t(`medium-password`)) ||
              (state.newPassword !== '' && t(`weak-password`)) ||
              t(`password-is-blank`)
            }
          />
        </div>
      </div> */}
      <div className='password-validation  my-2'>
        <span
          className={
            atLeastOneNumber.test(state.newPassword)
              ? 'mdi mdi-check my-1'
              : 'mdi mdi-information-outline my-1'
          }
        >
          {t(`one-number`)}
        </span>
        <span
          className={
            charactersLength.test(state.newPassword)
              ? 'mdi mdi-check my-1 '
              : 'mdi mdi-information-outline my-1'
          }
        >
          {' '}
          {t(`8-20-characters`)}
        </span>
        <span
          className={
            containUpper.test(state.newPassword)
              ? 'mdi mdi-check my-1'
              : 'mdi mdi-information-outline my-1'
          }
        >
          {t(`one-uppercase-letter`)}
        </span>
        <span
          className={
            containSpecialCharacter.test(state.newPassword)
              ? 'mdi mdi-check my-1'
              : 'mdi mdi-information-outline my-1'
          }
        >
          {t(`special-character(!@#$%^&*)`)}
        </span>
      </div>
      <div className='MuiDialogActions-root dialog-footer-wrapper  MuiDialogActions-spacing'>
        <div className='save-cancel-wrapper d-flex-v-center-h-end flex-wrap p-2'>
          <div className='cancel-wrapper d-inline-flex-center'>
            <ButtonBase
              className='cancel-btn-wrapper btns theme-transparent c-primary'
              onClick={onCancelClicked}
            >
              <span className='MuiButton-label'>
                <span>{t(`Cancel`)}</span>
              </span>
              <span className='MuiTouchRipple-root' />
            </ButtonBase>
          </div>
          <div className='save-wrapper d-inline-flex-center'>
            <ButtonBase
              className='save-btn-wrapper btns theme-solid bg-primary'
              onClick={ChangePassword}
              id='ChangePasswordref'
              disabled={!!schema.error}
            >
              <span className='MuiButton-label'>
                <span>{t(`Save-Change`)}</span>
              </span>
              <span className='MuiTouchRipple-root' />
            </ButtonBase>
          </div>
        </div>
      </div>
    </div>
  );
};
ChangePasswordView.propTypes = { onCancelClicked: PropTypes.func.isRequired };
