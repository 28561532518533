import React from 'react';
import { XeleDialog } from '../DialogComponent/XeleDialog';
import { useTranslation } from 'react-i18next';

export const HighPofileManagementDialog = ({
  translationPath,
  isOpenDialog,
  onClose,
  onSave,
  withCreateLeadForm,
}) => {
  const { t } = useTranslation('Shared', { keyPrefix: translationPath });

  return (
    <div>
      <XeleDialog
        titleText='confirm-message'
        saveText='confirm'
        saveType='button'
        maxWidth='sm'
        dialogContent={
          <div className='d-flex-column-center'>
            <span className='mdi mdi-close-octagon c-danger mdi-48px' />

            <span>
              {!withCreateLeadForm
                ? `${t(`Angry-Bird-atomatically-close-all-leads`)}`
                : `${t(
                    `Shared:Whoa-hold-on-This-lead-seems-A-bit`
                  )}`}
            </span>
          </div>
        }
        saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
        isOpen={isOpenDialog}
        onSaveClicked={() => {
          if (onSave) onSave();

          onClose();
        }}
        onCloseClicked={() => {
          onClose(false);
        }}
        onCancelClicked={() => {
          onClose(false);
        }}
        parentTranslationPath={'Shared'}
        translationPath={translationPath}
      />
    </div>
  );
};
