import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import './assets/theme-style/master.scss';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { ToastProvider } from 'react-toast-notifications';
import { ErrorBoundaryProvider } from './SharedComponents/ErrorBoundaryComponent/ErrorBoundaryProvider';
import { GlobalExceptionHandler, InitGlobalEventHandler } from './Helper';
import { PersistGate } from 'redux-persist/integration/react';
import { store as configStore, persistor } from './store/RootStore';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { config } from './config';
import { createRoot } from 'react-dom/client';
import { Spinner } from './Components';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

function initializeHotjar() {
  const hotjarId = config.hotjarSiteId;
  const hotjarVersion = 6; // Hotjar script version

  if (hotjarId) {
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: hotjarId, hjsv: hotjarVersion };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }
}

root.render(
  <Suspense fallback={<Spinner isActive isAbsolute />}>
    <ErrorBoundaryProvider>
      <I18nextProvider i18n={i18next}>
        <ToastProvider placement='top-right'>
          <Provider store={configStore}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        </ToastProvider>
      </I18nextProvider>
    </ErrorBoundaryProvider>
  </Suspense>
);

// Initialize Hotjar tracking
initializeHotjar();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
InitGlobalEventHandler();
GlobalExceptionHandler();
