import React, { useState, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LOGIN } from '../../../../store/login/Actions';
import { XeleInput } from '../../../../Components';
import hirxLogin from '../../../../assets/images/defaults/logo.png';
import { LoginFormsEnum } from './LoginFormsEnum';

export const SignInForm = ({
  loginDto,
  setLoginDto,
  setActiveLoginForm,
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });
  const dispatch = useDispatch(LOGIN);
  const [viewPassword, setViewPassword] = useState(false);

  const controlsHandler = useCallback(
    (input, process) => (event) => {
      setLoginDto({ ...loginDto, [input]: event.target[process] });
    },
    [setLoginDto, loginDto]
  );

  const validationHandler = () => {
    if (!loginDto.password || !loginDto.identity) return false;
    if (!loginDto.password) return false;
    return true;
  };

  const handleLogin = (event) => {
    event.preventDefault();
    if (validationHandler()) {
      dispatch(LOGIN(loginDto));
    }
  };

  return (
    <div className='login-form-container'>
      <div className='login-header'>
        <img src={hirxLogin} alt='logo' className='login-logo' />
      </div>
      <h1 className='heading-1'>{t(`Log-in`)}</h1>
      <h5 className='heading-2 mb-4'>
        {t(`sign-in-welcome`)}
      </h5>
      <div className='login-form'>
        <form noValidate className='form-wrapper' onSubmit={handleLogin}>
          <div className='mb-1'>
            <XeleInput
              idRef='identityRef'
              labelClasses='login-label ml-0'
              labelValue={t(`username`)}
              inputPlaceholder={t(`Enter-your-username`)}
              value={loginDto.identity}
              onInputChanged={controlsHandler('identity', 'value')}
            />
          </div>
          <div>
            <XeleInput
              idRef='passwordRef'
              labelClasses='login-label pb-1'
              type={!viewPassword ? 'password' : ''}
              labelValue={t(`password`)}
              inputPlaceholder={t(`password`)}
              value={loginDto.password}
              onInputChanged={controlsHandler('password', 'value')}
              endAdornment={
                <Button
                  onClick={() => setViewPassword(!viewPassword)}
                  className='p-2'
                >
                  {viewPassword ? (
                    <span className='mdi mdi-eye-outline' />
                  ) : (
                    <span className='mdi mdi-eye-off-outline' />
                  )}
                </Button>
              }
            />
          </div>
          <div
            className='login-form-bottom  d-flex fj-end my-3P5'
            onClick={() =>
              setActiveLoginForm(LoginFormsEnum.ResetPasswordRequest.key)
            }
          >
            <span className='login-text-btn'>
              {t(`forgot-password`)}
            </span>
          </div>
          <div className='d-flex-column-center'>
            <Button className='login-btn' type='submit'>
              {<span>{t(`sign-in`)}</span>}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
