export const UserLoginPermissions = {
  // CRMPortalLogin: {
  //     permissionsId: '50d26194-0d1d-4cb3-c9d8-08d94f66fc61',
  //     permissionsName: 'CRM Portal Login',
  //     componentsId: null,
  //     components: null
  // },
  // MyLeadLogin: {
  //     permissionsId: 'e7b89bbf-50ee-4768-c9d9-08d94f66fc61',
  //     permissionsName: 'My Lead Login',
  //     componentsId: null,
  //     components: null
  // },
  ViewUsers: {
    permissionsId: '31782f2d-6125-4d50-58e6-08d96ae23e90',
    permissionsName: 'View Users',
    componentsId: null,
    components: null,
  },
  EditButtonInUserRecord: {
    permissionsId: 'd5141e52-9949-4a98-58e7-08d96ae23e90',
    permissionsName: 'Edit Button In User Record',
    componentsId: null,
    components: null,
  },
  AddNewUser: {
    permissionsId: 'f3231667-3731-4ccd-58e8-08d96ae23e90',
    permissionsName: 'Add New User',
    componentsId: null,
    components: null,
  },
  DeleteButtonOnCard: {
    permissionsId: '198b5281-bbf5-43c8-58e9-08d96ae23e90',
    permissionsName: 'Delete Button On Card',
    componentsId: null,
    components: null,
  },
  RemoveRole: {
    permissionsId: '6c614e5e-19de-4741-58ea-08d96ae23e90',
    permissionsName: 'Remove Role',
    componentsId: null,
    components: null,
  },
  AddRole: {
    permissionsId: 'b67389ab-3b61-41f6-58eb-08d96ae23e90',
    permissionsName: 'Add Role',
    componentsId: null,
    components: null,
  },
  GetAllRoles: {
    permissionsId: 'f027cb14-0c29-4c0a-e7c8-08d96ae283eb',
    permissionsName: 'Get All Roles',
    componentsId: null,
    components: null,
  },

  AdminChangePassword: {
    components: null,
    componentsId: null,
    permissionsId: '48a248b1-8880-4fed-9b46-08d9881329f4',
    permissionsName: 'Admin Change Password',
  },
  EditEmailAddressInUserRecord: {
    permissionsId: 'd5dce52f-7e35-4e7a-e747-08daecccdaf8',
    permissionsName: 'Edit Email Address In User Record',
    componentsId: null,
    components: null,
  },
  EditMobileNumberInUserRecord: {
    permissionsId: 'ceb48e13-6b6c-4f6d-e748-08daecccdaf8',
    permissionsName: 'Edit Mobile Number In User Record',
    componentsId: null,
    components: null,
  },
  AssignPolicy: {
    permissionsId: '23a81ffc-c67c-45f2-a5d0-08db04f6cbfc',
    permissionsName: 'Assign Policy',
    description: null,
    componentsId: null,
    components: null,
  },
  EditGroupInUserRecord: {
    permissionsId: '07060be5-3e98-432d-77ed-08dc5572f9c8',
    permissionsName: 'Edit Group In User Record',
    description: null,
    componentsId: null,
    components: null,
  },
  ChangeProfileImage: {
    permissionsId: 'ed454bc4-5905-4c2e-5dc7-08dc7403ebc2',
    permissionsName: 'Change Profile Image',
    description: null,
    componentsId: null,
    components: null,
  },
  EditCountryInUserRecord: {
    permissionsId: 'a8266da7-3130-415e-7958-08dcaf947ea8',
    permissionsName: 'Edit Country In User Record',
    description: null,
    componentsId: null,
    components: null,
  },
  EditCityInUserRecord: {
    permissionsId: 'e2c2fa5f-4368-4433-7959-08dcaf947ea8',
    permissionsName: 'Edit City In User Record',
    description: null,
    componentsId: null,
    components: null,
  },
  EditDistrictInUserRecord: {
    permissionsId: '0d4ec87a-c0c8-4ff7-795a-08dcaf947ea8',
    permissionsName: 'Edit District In User Record',
    description: null,
    componentsId: null,
    components: null,
  },
  EditCommunityInUserRecord: {
    permissionsId: '27550745-9ea5-46dd-795b-08dcaf947ea8',
    permissionsName: 'Edit Community In User Record',
    description: null,
    componentsId: null,
    components: null,
  },
  EditSubCommunityInUserRecord: {
    permissionsId: '894a3939-e6e3-4a44-795c-08dcaf947ea8',
    permissionsName: 'Edit SubCommunity In User Record',
    description: null,
    componentsId: null,
    components: null,
  },
  EditSpecialistForDeveloperInUserRecord: {
    permissionsId: '58b7176f-9aff-4c1d-795d-08dcaf947ea8',
    permissionsName: 'Edit Specialist For Developer In User Record',
    description: null,
    componentsId: null,
    components: null,
  },
  EditSpecialistForPropertyInUserRecord: {
    permissionsId: '21d36a7d-44e6-4dcc-795e-08dcaf947ea8',
    permissionsName: 'Edit Specialist For Property In User Record',
    description: null,
    componentsId: null,
    components: null,
  },
};
