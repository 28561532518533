import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCopy } from './CopyHelper/useCopy';

// Styles
import './style/Copy.Style.scss';

export const CopyToClipboard = ({
  childrenData,
  data,
  labelClaass = '',
  CustomizationClassName = '',
  MessageTitle,
  iconCopy = 'mdi-content-copy',
  CopyDoneShowTime = 2000, // Time in Milliseconds
  iconCopyDone = 'mdi-check-all',
  isNewDesign,
  hideToastify,
  children,
}) => {
  const { CopiedTheValue, CopyFunctionBehavior } = useCopy();

  const handleCopy = (e) => {
    e.stopPropagation();
    e.preventDefault();
    CopyFunctionBehavior(data, MessageTitle, CopyDoneShowTime, hideToastify);
  };

  return (
    <div
      className={`copy-container ${CustomizationClassName} mx-1`}
      onClick={isNewDesign ? handleCopy : () => {}}
    >
      <span className={`${labelClaass}`}>{childrenData || ''}</span>
      {data && !isNewDesign && (
        <div
          className='icon-container'
          id={`id-CopyToClipboardComponents ${data || 1} `}
        >
          <span
            className={clsx(
              'mdi mdi-',
              CopiedTheValue ? `copied  ${iconCopyDone}` : `${iconCopy}`
            )}
            onClick={handleCopy}
          />
        </div>
      )}
      {children}
    </div>
  );
};

CopyToClipboard.propTypes = {
  iconCopy: PropTypes.string,
  iconCopyDone: PropTypes.string,
  CopyDoneShowTime: PropTypes.number,
  childrenData: PropTypes.string,
  MessageTitle: PropTypes.string,
  data: PropTypes.string,
  CustomizationClassName: PropTypes.string,
  isNewDesign: PropTypes.bool,
  hideToastify: PropTypes.bool,
  children: PropTypes.node,
};
