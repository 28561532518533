import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { createStaticRanges, DateRangePicker } from 'react-date-range';
import { ButtonBase, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { XelePopover } from '../../PopoverComponent/XelePopover';

// Styles
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

export function XeleDateRangePicker({
  ranges,
  onDateChanged,
  minDate,
  maxDate,
  popoverIdRef = 'dateRangePopoverRef',
  translationPath = '',
  displayFormat = 'DD/MM/YYYY',
  emptyLabel = 'all',
  idRef = 'dateRangePickerRef',
  labelValue,
  labelClasses = 'texts-form',
  isDisabled = false,
  startDateError,
  endDateError,
  helperText,
  isSubmitted = false,
  onClearClicked, // left unset
  isWideOvel = false,
  parentTranslationPath = '',
  disabledDates,
  onDialogClose, // left unset
  wrapperClasses = '',
}) {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });

  const [attachedWith, setAttachedWith] = useState(null);
  const [isBlurOrChanged, setIsBlurOrChanged] = useState(false);
  const displayRef = useRef(null);
  const [pickerDirection, setPickerDirection] = useState(null);

  const handleClose = useCallback(() => {
    setAttachedWith(null);
    if (onDialogClose) {
      onDialogClose();
    }
  }, []);

  const openHandler = useCallback(() => {
    if (!isBlurOrChanged) setIsBlurOrChanged(true);
    setAttachedWith(displayRef.current);
  }, [isBlurOrChanged]);

  const getPickerDirection = useCallback(() => {
    if (window.innerWidth <= 991.98 && pickerDirection !== 'vertical')
      setPickerDirection('vertical');
    else if (window.innerWidth > 991.98 && pickerDirection !== 'horizontal')
      setPickerDirection('horizontal');
  }, [pickerDirection]);

  useEffect(() => {
    if (!pickerDirection) getPickerDirection();
  });

  const staticRanges = createStaticRanges([
    {
      label: 'Today',
      range: () => ({
        startDate: new Date(
          moment().startOf('day').format('YYYY-MM-DD[T00:00:00]')
        ),
        endDate: new Date(moment().endOf('day')),
      }),
    },
    {
      label: 'Yesterday',
      range: () => ({
        startDate: new Date(
          moment().startOf('day').subtract(1, 'days').add(1, 'hours')
        ),
        endDate: new Date(moment().endOf('day').subtract(1, 'days')),
      }),
    },
    {
      label: 'This Week',
      range: () => ({
        startDate: new Date(
          moment().clone().startOf('isoWeek').add(1, 'hours')
        ),
        endDate: new Date(moment().clone().endOf('isoWeek')),
      }),
    },
    {
      label: 'Last Week',
      range: () => ({
        startDate: new Date(
          moment().subtract(1, 'weeks').startOf('isoWeek').add(1, 'hours')
        ),
        endDate: new Date(moment().subtract(1, 'weeks').endOf('isoWeek')),
      }),
    },
    {
      label: 'This Month',
      range: () => ({
        startDate: new Date(new Date(moment().clone().startOf('month'))),
        endDate: new Date(moment().clone().endOf('month')),
      }),
    },
    {
      label: 'Last Month',
      range: () => ({
        startDate: new Date(moment().subtract(1, 'months').startOf('month')),
        endDate: new Date(moment().subtract(1, 'months').endOf('month')),
      }),
    },
  ]);

  return (
    <div className={`date-range-picker-component ${wrapperClasses || ''}`}>
      {labelValue && (
        <label
          htmlFor={idRef}
          className={`${labelClasses}${isDisabled ? ' disabled' : ''}`}
        >
          {t(`${labelValue}`)}
        </label>
      )}
      <ButtonBase
        id={idRef}
        className={`display-wrapper${
          ((startDateError || endDateError) &&
            (isBlurOrChanged || isSubmitted) &&
            ' has-error') ||
          ''
        }${(isWideOvel && ' wide-ovel') || ''}`}
        disabled={isDisabled}
        ref={displayRef}
        onClick={openHandler}
      >
        <div className='d-inline-flex-center'>
          <span className='description-text'>
            {t(`date`)}:
          </span>
        </div>
        <div className='d-inline-flex-center  fw-bold px-2'>
          {(ranges &&
            ranges.length > 0 &&
            ranges[0] &&
            ranges[0].startDate &&
            ranges[0].endDate && (
              <>
                <span
                  className={
                    (startDateError &&
                      (isBlurOrChanged || isSubmitted) &&
                      'start-date-error') ||
                    undefined
                  }
                >
                  {moment(ranges[0].startDate).format(displayFormat)}
                </span>
                <span className='px-1'>-</span>
                <span
                  className={
                    (endDateError &&
                      (isBlurOrChanged || isSubmitted) &&
                      'end-date-error') ||
                    undefined
                  }
                >
                  {moment(ranges[0].endDate).format(displayFormat)}
                </span>
              </>
            )) || (
            <span className='description-text'>
              {t(`${emptyLabel}`)}
            </span>
          )}
        </div>
        <span
          className={` px-2 mdi mdi-chevron-${
            (attachedWith && 'up') || 'down'
          }`}
        />
        {onClearClicked &&
          ranges &&
          ranges.length > 0 &&
          ranges[0] &&
          ranges[0].startDate &&
          ranges[0].endDate && (
            <IconButton
              size='small'
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClearClicked();
              }}
            >
              <span className='mdi mdi-close' />
            </IconButton>
          )}
      </ButtonBase>
      {helperText && (isBlurOrChanged || isSubmitted) && (
        <span className='error-wrapper'>{helperText}</span>
      )}
      <XelePopover
        idRef={popoverIdRef}
        handleClose={handleClose}
        attachedWith={attachedWith}
        component={
          <DateRangePicker
            months={2}
            direction={pickerDirection}
            moveRangeOnFirstSelection={false}
            ranges={ranges}
            minDate={minDate}
            maxDate={maxDate}
            disabledDates={disabledDates}
            onChange={onDateChanged}
            staticRanges={staticRanges}
          />
        }
      />
    </div>
  );
}

XeleDateRangePicker.propTypes = {
  parentTranslationPath: PropTypes.string,
  onClearClicked: PropTypes.func,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.instanceOf(moment),
        PropTypes.string,
      ]),
      endDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.instanceOf(moment),
        PropTypes.string,
      ]),
      key: PropTypes.string,
    })
  ).isRequired,
  onDateChanged: PropTypes.func.isRequired,
  minDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  disabledDates: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
      PropTypes.string,
    ])
  ),
  onDialogClose: PropTypes.func,
  popoverIdRef: PropTypes.string,
  emptyLabel: PropTypes.string,
  translationPath: PropTypes.string,
  displayFormat: PropTypes.string,
  isDisabled: PropTypes.bool,
  idRef: PropTypes.string,
  labelClasses: PropTypes.string,
  labelValue: PropTypes.string,
  helperText: PropTypes.string,
  startDateError: PropTypes.bool,
  endDateError: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  isWideOvel: PropTypes.bool,
};
