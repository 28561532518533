import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
import { showSuccess, getIsValidURL } from '../../../../../Helper';
import {
  GetLookupItems,
  GetLookupTypeItems,
  lookupTypesGet,
} from '../../../../../Services/LookupsServices';
import { Spinner } from '../../../../../Components';

export const LookupsItemCreateDialog = (props) => {
  const { t } = useTranslation('LookupsView');
  const [responseType, setResponseType] = useState([]);
  const [responseItem, setResponseItem] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [searchValueParent, setSearchValueParent] = useState(null);
  const [lookUpTypeIdState, setLookUpTypeIdState] = useState(null);
  let time = setTimeout(() => {}, 300);

  useEffect(() => {
    if (props.item && props.item.lookupItemId) {
      for (const propsrty in props.item)
        props.setState({ id: propsrty, value: props.item[propsrty] });

      if (props.item && props.item.parentLookupItemId) props.setHasParent(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item]);

  useEffect(() => {
    if (props.selectedTypeId) {
      props.setState({
        id: 'lookupTypeId',
        value: parseInt(props.selectedTypeId),
      });
    }
  }, [props.selectedTypeId]);

  const loadType = async (pageIndex, pageSize, searchedItem) => {
    let res = await lookupTypesGet({ pageIndex, pageSize, searchedItem });

    setResponseType(res);
  };
  useEffect(() => {
    loadType(1, 20);
  }, []);
  const validate = () =>
    (!props.hasParent &&
      props.state.lookupItemName &&
      props.state.lookupItemName !== '') ||
    (props.hasParent &&
      props.state.lookupItemName &&
      props.state.lookupItemName !== '' &&
      props.state.parentLookupItemId);

  const clearAllFields = () => {
    props.setState({
      id: 'parentLookupTypeName',
      value: '',
    });
    props.setState({
      id: 'parentLookupTypeId',
      value: null,
    });
    props.setState({
      id: 'parentLookupItemName',
      value: '',
    });
    props.setState({
      id: 'parentLookupItemId',
      value: null,
    });
  };
  return (
    <Dialog
      className='lookupItem'
      open={props.open}
      keepMounted
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      <Spinner isActive={props.loading} />
      <DialogTitle id='alert-dialog-slide-title'>
        {t('CreateLookupItemDialog.LookupItem')}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} className='mb-3'>
            <FormControl>
              <div className='form-name'>
                {t('CreateLookupItemDialog.LookupName')}
              </div>
              <TextField
                fullWidth
                className='inputs theme-solid'
                size='small'
                variant='outlined'
                id='lookupItemName'
                required
                value={
                  props.state && props.state.lookupItemName
                    ? props.state.lookupItemName
                    : ''
                }
                onChange={(e) => props.setState(e.target)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} className='mb-3'>
            <FormControl>
              <div className='form-name'>
                {t('CreateLookupItemDialog.LookupOrder')}
              </div>
              <TextField
                fullWidth
                className='inputs theme-solid'
                size='small'
                variant='outlined'
                id='lookupItemOrder'
                value={props?.state?.order || ''}
                onChange={(event) => {
                  const newValue = +event.target.value;
                  if (newValue !== 0 && !isNaN(newValue)) {
                    props.setState({
                      id: 'order',
                      value: newValue,
                    });
                  } else {
                    props.setState({
                      id: 'order',
                      value: null,
                    });
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} className='mb-3'>
            <FormControl>
              <div className='form-name'>
                {t('CreateLookupItemDialog.lookupItemIcon')}
              </div>
              <TextField
                fullWidth
                className='inputs theme-solid'
                size='small'
                variant='outlined'
                id='lookupItemIcon'
                value={
                  props.state && props.state.lookupItemIcon
                    ? props.state.lookupItemIcon
                    : ''
                }
                onChange={(e) => props.setState(e.target)}
              />
              {props?.state?.lookupItemIcon &&
              getIsValidURL(props.state.lookupItemIcon) ? (
                <img
                  style={{ width: '190px', margin: '10px auto' }}
                  src={props.state.lookupItemIcon}
                  alt='-Broken Icon Link-'
                />
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={12} className='Checkboxs mb-3'>
            <FormControlLabel
              className='form-control-label'
              control={
                <Checkbox
                  className='checkbox-wrapper'
                  checkedIcon={<span className='mdi mdi-check' />}
                  indeterminateIcon={<span className='mdi mdi-minus' />}
                  checked={props.hasParent}
                  onClick={() => {
                    if (props.hasParent) clearAllFields();
                    //props.item.isForMobile
                  }}
                  onChange={async (e) => props.setHasParent(e.target.checked)}
                />
              }
              label={t('CreateLookupItemDialog.HasParent')}
            />
            <FormControlLabel
              className='form-control-label'
              control={
                <Checkbox
                  className='checkbox-wrapper'
                  checkedIcon={<span className='mdi mdi-check' />}
                  indeterminateIcon={<span className='mdi mdi-minus' />}
                  checked={props?.state?.isForMobile || props.isForMobile}
                  onChange={async (e) => {
                    props.setIsForMobile(e.target.checked);
                    props.setState({
                      id: 'isForMobile',
                      value: e.target.checked,
                    });
                  }}
                />
              }
              label={t('CreateLookupItemDialog.isForMobile')}
            />
          </Grid>

          {props.hasParent && (
            <>
              <Grid item xs={12} className='mb-3'>
                <div htmlFor='parentLookupTypeId' className='form-name'>
                  {t('CreateLookupItemDialog.LookupType')}
                </div>
                <Autocomplete
                  fullWidth
                  className='inputs theme-solid'
                  size='small'
                  id='parentLookupTypeId'
                  options={
                    responseType && responseType.result
                      ? responseType.result
                      : []
                  }
                  getOptionLabel={(option) =>
                    option.lookupTypeName ? option.lookupTypeName : ''
                  }
                  inputValue={
                    props.state.parentLookupTypeName
                      ? props.state.parentLookupTypeName
                      : ''
                  }
                  onChange={async (e, v) => {
                    if (v && v.lookupTypeName) {
                      props.setState({
                        id: 'parentLookupTypeName',
                        value: v.lookupTypeName,
                      });
                      props.setState({
                        id: 'parentLookupItemName',
                        value: '',
                      });
                    }
                    setResponseItem(
                      await GetLookupItems(1, 20, v.lookupTypeName)
                    );
                    setLookUpTypeIdState(v.lookupTypeId);
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      className='inputs theme-solid'
                      size='small'
                      variant='outlined'
                      {...params}
                      onKeyUp={() => {
                        time = setTimeout(() => {
                          loadType(1, 20, searchValue);
                        }, 1000);
                      }}
                      onChange={(e) => {
                        props.setState({
                          id: 'parentLookupTypeName',
                          value: e.target.value,
                        });
                        setSearchValue(e.target.value);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} className='mb-3'>
                <div htmlFor='parentLookupItemId' className='form-name'>
                  {t('CreateLookupItemDialog.ParentLookupItem')}
                </div>
                <Autocomplete
                  fullWidth
                  className='inputs theme-solid'
                  size='small'
                  id='parentLookupItemId'
                  options={
                    responseItem && responseItem.result
                      ? responseItem.result
                      : []
                  }
                  getOptionLabel={(option) =>
                    option.lookupItemName ? option.lookupItemName : ''
                  }
                  inputValue={
                    props.state.parentLookupItemName
                      ? props.state.parentLookupItemName
                      : ''
                  }
                  onChange={async (e, v) => {
                    if (v && v.lookupItemId) {
                      props.setState({
                        id: 'parentLookupItemId',
                        value: v.lookupItemId,
                      });
                      props.setState({
                        id: 'parentLookupItemName',
                        value: v.lookupItemName,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      className='inputs theme-solid theme-form-builder'
                      size='small'
                      variant='outlined'
                      {...params}
                      onKeyUp={() => {
                        time = setTimeout(async () =>
                          setResponseItem(
                            await GetLookupTypeItems({
                              pageIndex: 0,
                              pageSize: 99999,
                              lookupTypeId: lookUpTypeIdState,
                              search: searchValueParent || '',
                            })
                          )
                        );
                      }}
                      onChange={async (e) => {
                        setSearchValueParent(e.target.value);
                        props.setState({
                          id: 'parentLookupItemName',
                          value: e.target.value,
                        });
                      }}
                    />
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.setOpen(false);
            props.setState({ reset: true });
          }}
          className='btns theme-solid bg-cancel'
        >
          {t('CreateLookupItemDialog.Cancel')}
        </Button>
        <Button
          disabled={!validate()}
          className='btns theme-solid'
          onClick={async () => {
            props.isEdit
              ? await props.onSave(props.state)
              : await props.onCreate(props.state);
            props.setState({ reset: true });
            props.isEdit
              ? showSuccess(t('CreateLookupItemDialog.UpdateItem'))
              : showSuccess(t('CreateLookupItemDialog.CreateItem'));
            props.setOpen(false);
          }}
        >
          {t('CreateLookupItemDialog.Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
