import {
  HomeRoutes,
  FormBuilderRoutes,
  LookupsRoutes,
  RolesRoutes,
  UserDataViewRoutes,
  DashboardRoutes,
  BranchesRoutes,
  ReportsBuilderRoutes,
  TalentPoolRoutes,
  RotationSchemaRoutes
} from '../HomeRoutes';

export const BreadCrumbRoutes = [
  ...HomeRoutes,
  ...FormBuilderRoutes,
  ...LookupsRoutes,
  ...RolesRoutes,
  ...UserDataViewRoutes,
  ...DashboardRoutes,
  ...BranchesRoutes,
  ...ReportsBuilderRoutes,
  ...TalentPoolRoutes,
  ...RotationSchemaRoutes
];
