import React from 'react';
import { Button, Avatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getPublicDownloadableLink } from '../../../../Helper';
import { config } from '../../../../config';
import arrowLeft from '../../../../assets/images/icons/arrow-left.png';
import { ApplicationUserForgotPassword } from '../../../../Services/LoginService';
import propxLogin from '../../../../assets/images/pages/login/propx-login.svg';
import { LoginFormsEnum } from './LoginFormsEnum';

export const UserVerificationForm = ({
  setUserVerificationInfo,
  userVerificationInfo,
  setActiveLoginForm,
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });

  const sendOTPtoEmail = async (event) => {
    event.preventDefault();
    const response = await ApplicationUserForgotPassword({
      userId: userVerificationInfo.userId,
      identity: userVerificationInfo.email,
      organizationsId: config.organizationsId,
      applicationsId: config.applicationId,
    });
    if (response) {
      setUserVerificationInfo((prevState) => ({
        ...prevState,
        identityVerificationId: response.identityVerificationId,
      }));
      setActiveLoginForm(LoginFormsEnum.OTPVerification.key);
    }
  };

  return (
    <div className='login-form-container'>
      <div className='login-header'>
        <img src={propxLogin} />
      </div>
      <h1 className='heading-1'>{t(`verify-heading`)}</h1>
      <h5 className='heading-2 mb-4'>
        {t(`verify-description`)}
      </h5>
      <div className='login-form'>
        <form noValidate className='form-wrapper' onSubmit={sendOTPtoEmail}>
          <div className='forgot-user-banner d-flex'>
            {userVerificationInfo.profileImg ? (
              <div>
                <Avatar
                  className='avatars-wrapper user-avatar user-img'
                  src={getPublicDownloadableLink(
                    userVerificationInfo.profileImg
                  )}
                ></Avatar>
              </div>
            ) : null}
            <div className='user-info'>
              <p>{userVerificationInfo.fullName}</p>
              <p>{userVerificationInfo.email}</p>
            </div>
          </div>
          <div className='d-flex-column-center'>
            <Button className='login-btn' type='submit'>
              {<span>{t(`its-me`)}</span>}
            </Button>
          </div>
          <div
            className='back-btn d-flex-center my-3P5'
            onClick={() => setActiveLoginForm(LoginFormsEnum.SignIn.key)}
          >
            <img src={arrowLeft} className='mr-1' />
            <span>{t(`back-to-login`)}</span>
          </div>
        </form>
      </div>
    </div>
  );
};
