import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showError } from '../../Helper';
import { DefaultImagesEnum, UploaderThemesEnum } from '../../Enums';
import { GalleryShowThemeComponent } from '../../Components/UploaderComponent/UploaderThemes/GalleryShowThemeComponent/GalleryShowThemeComponent';
import { InputThemeComponent } from '../../Components/UploaderComponent/UploaderThemes/InputThemeComponent/InputThemeComponent';
import { CircleThemeComponent } from '../../Components/UploaderComponent/UploaderThemes/CircleThemeComponent/CircleThemeComponent';
import { BoxThemeComponent } from '../UploaderFilesComponent/UploaderThemes/BoxThemeComponent/BoxThemeComponent';
import { LoadableDocumentComponent, Spinner } from '../../Components';
import PropTypes from 'prop-types';
import { FindInPage } from '@material-ui/icons';
import { ButtonBase } from '@material-ui/core';
import { FileInfoCard } from '../FileInfoCard/FileInfoCard';
import { uploadFile } from '../../Services/FileManagerServices/FileManagerServices';

export const MultipleTypesDocumentsUploader = ({
  wrapperClasses,
  uploaderClasses,
  counterClasses,
  inputClasses,
  labelClasses,
  accept,
  multiple,
  initUploadedFiles,
  chipsDisabled,
  theme,
  disabledImg,
  translationPath,
  parentTranslationPath,
  translationPathShared,
  uploadedChanged,
  allFilesChanged,
  titleText,
  labelValue,
  isDisabled,
  idRef,
  defaultImage,
  viewUploadedFilesCount,
  dropHereText,
  uploaderTheme,
  chipHandler,
  WithoutDefaultImg,
  openGallery,
  isDocuments,
  setIsUploading,
  isUploading,
  isWithOCR,
  onOCRClick,
  haveToResetInitDOCs,
  checkIsRemoveHidden,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared'],  {
    keyPrefix: translationPath,
  });
  const [allFiles, setAllFiles] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [acceptedTypes, setAcceptedTypes] = useState([]);
  const [viewedFile, setViewedFile] = useState(null);
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);
  const uploadRef = useRef(null);
  const uploadHandler = (files) => {
    setIsUploading(true);
    files.map((item, index) => {
      uploadFile({ file: item.file })
        .then((response) => {
          if (multiple) uploadedFiles.push(response);
          const localUploadedFiles = (multiple && uploadedFiles) || [
            { ...response },
          ];
          setUploadedFiles(localUploadedFiles);
          uploadedChanged(localUploadedFiles);
          setUploadedFiles(localUploadedFiles);
          setAllFiles((items) => {
            const fileIndex = items.findIndex(
              (element) => element.id === item.id
            );
            if (fileIndex !== -1) {
              items[fileIndex].uuid = response.uuid;
              items[fileIndex].status = 'success';
            }
            return [...items];
          });
        })
        .catch(() => {
          setAllFiles((items) => {
            const fileIndex = items.findIndex(
              (element) => element.id === item.id
            );
            items[fileIndex].status = 'failed';
            return [...items];
          });
        })
        .finally(() => {
          // if (index === 0)
          setIsUploading(false);
        });
    });
    setIsUploading(false);
  };
  const typeChecker = (type) => {
    if (typeof type === 'string') {
      if (type.includes('image')) {
        return 'image';
      } else if (type.includes('pdf')) {
        return 'pdf';
      } else if (type.includes('video')) {
        return 'video';
      } else if (type.includes('word')) {
        return 'word';
      } else if (type.includes('ms-excel') || type.includes('sheet')) {
        return 'excel';
      } else return;
    }
  };
  const fileDeleted = useCallback(
    (item, index) => () => {
      const uploadedFilesIndex =
        uploadedFiles &&
        uploadedFiles.findIndex((element) => element.uuid === item.uuid);
      if (uploadedFilesIndex !== -1) {
        const localFiles = [...uploadedFiles];
        localFiles.splice(uploadedFilesIndex, 1);
        uploadedChanged(localFiles);
        setUploadedFiles(localFiles);
      }
      const localFiles = [...uploadedFiles];
      localFiles.splice(uploadedFilesIndex, 1);
      setUploadedFiles(localFiles);
      setAllFiles((items) => {
        items.splice(index, 1);
        return [...items];
      });
    },
    [uploadedChanged, uploadedFiles]
  );
  const inputChanged = (event) => {
    // setIsUploading(true)
    if (!event.target.value) return;
    // const filesLength = allFiles.length;
    let files = [];
    if (multiple) {
      Object.values(event.target.files).map((file) => {
        if (acceptedTypes.includes(typeChecker(file.type))) {
          files.push({
            id: allFiles.length + files.length,
            uuid: null,
            fileName: file.name,
            size: file.size,
            type: file.type,
            file,
            status: 'uploading',
          });
          // uploadHandler(file, filesLength + index);
          return undefined;
        } else
          showError(
            t(`${translationPathShared}uploaded-file-type-is-not-supported`)
          );
      });
    } else if (
      acceptedTypes.includes(typeChecker(event.target.files[0].type))
    ) {
      files = [
        {
          id: allFiles.length,
          uuid: null,
          fileName: event.target.files[0].name,
          size: event.target.files[0].size,
          type: event.target.files[0].type,
          file: event.target.files[0],
          status: 'uploading',
        },
      ];
    } else
      showError(
        t(`${translationPathShared}uploaded-file-type-is-not-supported`)
      );
    setAllFiles((items) => (multiple && items.concat(files)) || files);
    uploadHandler(files);
    event.target.value = null;
  };
  const dropHandler = (event) => {
    // setIsUploading(true)
    event.preventDefault();
    if (isDisabled) return;
    setIsDragOver(false);
    let filesToUpload = Object.values(event.dataTransfer.files);
    if (accept.includes('image'))
      filesToUpload = filesToUpload.filter((item) =>
        item.type.includes('image')
      );

    if (filesToUpload.length === 0)
      return showError(
        t(`${translationPathShared}uploaded-file-type-is-not-supported`)
      );
    let files = [];
    if (multiple) {
      filesToUpload.map((file) => {
        files.push({
          id: allFiles.length + files.length,
          uuid: null,
          fileName: file.name,
          size: file.size,
          type: file.type,
          file,
          status: 'uploading',
        });
        return undefined;
      });
    } else {
      files = [
        {
          id: allFiles.length,
          uuid: null,
          fileName: filesToUpload[0].name,
          size: filesToUpload[0].size,
          type: filesToUpload[0].type,
          file: filesToUpload[0],
          status: 'uploading',
        },
      ];
    }
    setAllFiles((items) => (multiple && items.concat(files)) || files);
    uploadHandler(files);
  };
  const removeButtonClicked = (item) => {
    const removedFileIndex =
      uploadedFiles &&
      uploadedFiles.findIndex((element) => element.uuid === item.uuid);
    if (removedFileIndex !== -1) {
      const localUploadedFiles = [...uploadedFiles];
      localUploadedFiles.splice(removedFileIndex, 1);
      uploadedChanged(localUploadedFiles);
      setUploadedFiles(localUploadedFiles);
    }
    const localUploadedFiles = [...uploadedFiles];
    localUploadedFiles.splice(removedFileIndex, 1);
    setUploadedFiles(localUploadedFiles);

    const localAllFiles = allFiles.filter(
      (element) => element.uuid !== item.uuid
    );
    setAllFiles(localAllFiles);
  };
  useEffect(() => {
    if (accept) {
      accept.split(',').map((type) => {
        setAcceptedTypes((types) => types.concat(typeChecker(type)));
      });
    }
  }, [accept]);

  useEffect(() => {
    if (
      (initUploadedFiles &&
        initUploadedFiles.length > 0 &&
        uploadedFiles.length === 0) ||
      haveToResetInitDOCs
    ) {
      setUploadedFiles(initUploadedFiles);
      setAllFiles(initUploadedFiles);
    }
  }, [initUploadedFiles, uploadedFiles.length, haveToResetInitDOCs]);
  return (
    <div className={`ActivityFilesUploader-wrapper ${wrapperClasses}`}>
      {labelValue && (
        <label
          htmlFor={idRef}
          className={`label-wrapper ${labelClasses}${
            isDisabled ? ' disabled' : ''
          }`}
        >
          {t(`${labelValue}`)}
        </label>
      )}
      <input
        ref={uploadRef}
        type='file'
        className={inputClasses}
        multiple={multiple}
        accept={accept}
        onChange={inputChanged}
        disabled={isDisabled}
      />
      <div
        className={uploaderClasses}
        onDragOver={(event) => {
          event.preventDefault();
          if (isDisabled) return;
          if (!isDragOver) setIsDragOver(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setIsDragOver(false);
        }}
        onDrop={dropHandler}
      >
        {uploaderTheme === UploaderThemesEnum.input.key && (
          <InputThemeComponent
            allFiles={allFiles}
            isDragOver={isDragOver}
            translationPathShared={translationPathShared}
            fileDeleted={fileDeleted}
            chipClicked={chipHandler}
            uploadRef={uploadRef}
            multiple={multiple}
            accept={accept}
            idRef={idRef}
            chipsDisabled={chipsDisabled}
            isDisabled={isDisabled}
          />
        )}
        {uploaderTheme === UploaderThemesEnum.circle.key && (
          <CircleThemeComponent
            allFiles={allFiles}
            defaultImage={defaultImage}
            isDragOver={isDragOver}
            translationPathShared={translationPathShared}
            fileDeleted={fileDeleted}
            uploadRef={uploadRef}
          />
        )}
        {uploaderTheme === UploaderThemesEnum.box.key && (
          <BoxThemeComponent
            file={(allFiles.length > 0 && allFiles[0]) || undefined}
            defaultImage={defaultImage}
            isDragOver={isDragOver}
            WithoutDefaultImg={WithoutDefaultImg}
            translationPathShared={translationPathShared}
            fileDeleted={fileDeleted}
            uploadRef={uploadRef}
            dropHereText={dropHereText}
            multiple={multiple}
            accept={accept}
          />
        )}
        {uploaderTheme === UploaderThemesEnum.GalleryShow.key && (
          <GalleryShowThemeComponent
            allFiles={allFiles}
            isDragOver={isDragOver}
            translationPathShared={translationPathShared}
            fileDeleted={fileDeleted}
            uploadRef={uploadRef}
            multiple={multiple}
            accept={accept}
            idRef={idRef}
            Disabledimg={disabledImg}
            isDisabled={isDisabled}
          />
        )}
        {viewUploadedFilesCount && (
          <span className={counterClasses}>
            {`${allFiles.length} ${
              (accept &&
                accept.includes('image') &&
                t(
                  `${translationPathShared}${
                    (allFiles.length > 1 && 'images-uploaded') ||
                    'image-uploaded'
                  }`
                )) ||
              t(
                `${translationPathShared}${
                  (allFiles.length > 1 && 'files-uploaded') || 'file-uploaded'
                }`
              )
            }`}
          </span>
        )}
      </div>
      <div className='GalleryComponent'>
        <Spinner isActive={isUploading} />
        {isDocuments &&
          allFiles?.map((file) =>
            theme === 'primary' ? (
              <div className='doc-file-card document-card' key={file.fileName}>
                <div className='d-flex card-image'>
                  <LoadableDocumentComponent
                    classes='doc-image'
                    fileName={file.fileName}
                    alt={t(`cover-image`)}
                  />
                  <span
                    className='mdi mdi-eye'
                    onClick={() => {
                      setIsOpenPreviewDialog(true);
                      setViewedFile({
                        templateFileId: file.uuid || file.fileId,
                        templateFileName: file.fileName,
                        templateText: '',
                      });
                    }}
                    title='View File'
                  ></span>
                  <div className='doc-info'>
                    <span>{file.fileName}</span>
                    <div className='file-icons-container'>
                      <span
                        className='mdi mdi-delete-circle-outline c-warning delete-button'
                        onClick={() => removeButtonClicked(file)}
                      />
                      {isWithOCR && (
                        <ButtonBase onClick={() => onOCRClick(file.uuid)}>
                          <FindInPage
                            fontSize='small'
                            color='action'
                            titleAccess='read from document'
                          />
                        </ButtonBase>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <FileInfoCard
                wrapperClasses='w-100 mt-3'
                fileName={file.fileName}
                fileId={file.uuid}
                onRemoveClick={() => removeButtonClicked(file)}
                isRemoveHidden={checkIsRemoveHidden(file)}
              />
            )
          )}
      </div>
    </div>
  );
};

MultipleTypesDocumentsUploader.propTypes = {
  initUploadedFiles: PropTypes.instanceOf(Array),
  wrapperClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  labelValue: PropTypes.string,
  uploaderClasses: PropTypes.string,
  idRef: PropTypes.string,
  inputClasses: PropTypes.string,
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  translationPathShared: PropTypes.string,
  theme: PropTypes.oneOf(['primary', 'propx']),
  accept: PropTypes.string,
  counterClasses: PropTypes.string,
  titleText: PropTypes.string,
  uploaderTheme: PropTypes.oneOf(
    Object.values(UploaderThemesEnum).map((item) => item.key)
  ),
  multiple: PropTypes.bool,
  chipsDisabled: PropTypes.func,
  disabledImg: PropTypes.func,
  chipHandler: PropTypes.func,
  uploadedChanged: PropTypes.func,
  allFilesChanged: PropTypes.func,
  isDisabled: PropTypes.bool,
  WithoutDefaultImg: PropTypes.bool,
  viewUploadedFilesCount: PropTypes.bool,
  defaultImage: PropTypes.string,
  dropHereText: PropTypes.string,
  isWithOCR: PropTypes.bool,
  onOCRClick: PropTypes.func,
  checkIsRemoveHidden: PropTypes.func,
};
MultipleTypesDocumentsUploader.defaultProps = {
  initUploadedFiles: [],
  wrapperClasses: 'uploader-wrapper',
  labelClasses: '',
  uploaderClasses: 'uploader-container',
  counterClasses: 'counter-text',
  inputClasses: 'file-input',
  idRef: 'uploaderChipRef',
  translationPath: '',
  theme: 'primary',
  parentTranslationPath: '',
  translationPathShared: 'Shared:uploaderComponent.',
  accept:
    'image/*,application/pdf,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  titleText: undefined,
  chipHandler: () => {},
  labelValue: undefined,
  uploaderTheme: UploaderThemesEnum.input.key,
  multiple: false,
  WithoutDefaultImg: false,
  chipsDisabled: () => false,
  disabledImg: () => false,
  allFilesChanged: undefined,
  uploadedChanged: undefined,
  isDisabled: false,
  viewUploadedFilesCount: false,
  defaultImage: DefaultImagesEnum.corporate.key,
  dropHereText: 'drop-here',
  setIsUploading: () => {},
  isWithOCR: false,
  onOCRClick: () => {},
  checkIsRemoveHidden: () => {},
};
