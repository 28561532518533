import { config } from '../config/config';
import { GlobalTranslate, showError, HttpServices } from '../Helper';

export const GetAllOrganizationUser = async (
  pageIndex,
  pageSize,
  userStatusId
) => {
  const GetUrl = () => {
    let url = '';
    if (userStatusId === null)
      url = `${config.server_address}/Identity/Account/GetAllApplicationUser/${pageIndex}/${pageSize}`;
    else
      url = `${config.server_address}/Identity/Account/GetAllApplicationUser/${pageIndex}/${pageSize}?userStatusId=${userStatusId}`;

    return url;
  };
  const result = await HttpServices.get(GetUrl())
    .then((data) => data)
    .catch((error) => {
      showError(GlobalTranslate.t('UsersView:NotificationErrorView'));
      return undefined;
    });
  return result;
};

export const CancelOrganizationUser = async (userId) => {
  const result = await HttpServices.put(
    `${config.server_address}/Identity/Account/CancelApplicationUser/${userId}`
  )
    .then((data) => data)
    .catch((error) => {
      showError(
        GlobalTranslate.t(
          'UsersView:UsersDeleteDialog.NotificationErrorDeleteUsers'
        )
      );
      return undefined;
    });
  return result;
};

export const DeleteOrganizationUser = async (userId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/Identity/Account/DeleteApplicationUser/${userId}`
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const ActiveOrganizationUser = async (userId) => {
  const result = await HttpServices.put(
    `${config.server_address}/Identity/Account/ActiveApplicationUser/${userId}`
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const DeactivatedUser = async (userId) => {
  const result = await HttpServices.post(
    `${config.server_address}/Identity/Account/DeactivateUser?userId=${userId}`
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const CanDeActiveUser = async (userId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Users/CanDeActiveUser/${userId}`
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const EditOrganizationUserProfile = async (userId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/Identity/Account/EditApplicationUserProfile/${userId}`,
    body
  )
    .then((data) => data)
    .catch((error) => {
      showError(
        GlobalTranslate.t('UsersView:UsersEditDialog.NotificationEditUsers')
      );
      return undefined;
    });
  return result;
};

export const AdminChangePasswordWithoutVerification = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/Identity/Account/AdminChangePasswordWithoutVerification`,
    body
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const CreateOrganizationUser = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/Identity/Account/CreateApplicationUser`,
    body
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const UploadUserPicture = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/FileManager/File/Upload`,
    body
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const GetLookupItem = async (typeName) => {
  const result = await HttpServices.get(
    `${config.server_address}/Lookups/LookupItem?lookupTypeName=${typeName}`
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const OrganizationUserSearch = async (body) => {
  if (body.pageIndex === 0) body.pageIndex = 1;
  if (body.userStatusId === 0) body.userStatusId = 2;
  const result = await HttpServices.post(
    `${config.server_address}/Identity/Account/ApplicationUserSearch`,
    body
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const ApplicationUserSearch = async (body) => {
  if (body.pageIndex === 0) body.pageIndex = 1;
  if (body.userStatusId === 0) body.userStatusId = 2;
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Users/ApplicationUserSearch`,
    body
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const GetAllBranches = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/Identity/Branch/GetAllBranches`,
    body
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};
export const GetApplicationUserById = async (userId) => {
  const result = await HttpServices.get(
    `${config.server_address}/Identity/Account/GetApplicationUserById?userId=${userId}`
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const AssignRolesToUser = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/Authorization/Roles/AssignRolesToUser`,
    body
  )
    .then((data) => data)
    .catch((error) => {
      showError(
        GlobalTranslate.t('UsersView:UsersAddDialog.NotificationErrorAddUsers')
      );
      return undefined;
    });
  return result;
};

export const GetAllRolesByUserId = async (userId, pageIndex, pageSize) => {
  const result = await HttpServices.get(
    `${config.server_address}/Authorization/Roles/GetAllRolesByUsersId/${userId}/${pageIndex}/${pageSize}`
  )
    .then((data) => data)
    .catch((error) => {
      showError(
        GlobalTranslate.t('UsersView:UsersAddDialog.NotificationErrorAddUsers')
      );
      return undefined;
    });
  return result;
};

export const countryService = async (body) => {
  const result = await HttpServices.get(
    `${config.server_address}/Lookups/LookupItem?lookupTypeName=country`,
    body
  )
    .then((data) => data)
    .catch((error) => {
      const { t } = GlobalTranslate;
      showError(t('LookupsView:LookupItems.NotificationLookupaddErorr'));
      return undefined;
    });
  return result;
};

export const RemoveRolesFromUser = async (userRolesId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/Authorization/Roles/RemoveRolesFromUser/${userRolesId}`
  )
    .then((data) => data)
    .catch((error) => {
      const { t } = GlobalTranslate;
      showError(t('LookupsView:LookupItems.NotificationLookupaddErorr'));
      return undefined;
    });
  return result;
};

export const GetUserTeamsInfo = async (userId, isTeamLead) => {
  const queryList = [];
  if (isTeamLead) queryList.push('isTeamLead=true');
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Team/GetUserTeamsInfo?userId=${userId}&${queryList.join('&')}`
  )
    .then((data) => data)
    .catch(
      (error) =>
        // showError(GlobalTranslate.t('UsersView:UsersAddDialog.NotificationErrorAddUsers'));
        undefined
    );
  return result;
};
export const GetAllTeamsByManagerId = async (managerId) => {
  const queryList = [];
  // if (isTeamLead) queryList.push('isTeamLead=true');
  const result = await HttpServices.get(
    `${config.server_address}/Authorization/Teams/GetAllTeamsByManagerId?managerId=${managerId}`
  )
    .then((data) => data)
    .catch(
      (error) =>
        // showError(GlobalTranslate.t('UsersView:UsersAddDialog.NotificationErrorAddUsers'));
        undefined
    );
  return result;
};

export const GetAllUserLogins = async (userId) => {
  const result = await HttpServices.get(
    `${config.server_address}/Identity/Account/GetUserLoginHistory?userId=${userId}`
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};
export const GetAllTeamLeadUsers = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Users/GetAllTeamLeadUsers`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetUsersPolicies = async (usersIds) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/Policies/GetUsersPolicies`,
      usersIds
    );
    return result;
  } catch (error) {
    return error.message;
  }
};

export const GetInActiveUserRoles = async ({ userId, pageIndex, pageSize }) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0) queryList.push(`pageIndex=${pageIndex}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/Identity/Roles/GetUserRoles/${userId}?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const RemoveRoleFromInActiveUser = async (userId, userRoleId) => {
  const queryList = [];
  if (userId) queryList.push(`userId=${userId}`);

  const result = await HttpServices.delete(
    `${
      config.server_address
    }/Identity/Roles/RemoveRoleFromUser/${userRoleId}?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const AssignRolesToInActiveUser = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/Identity/Roles/AssignRolesToUser`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};


export const AssignPolicyToInActiveUser = async ({ userId, policyId }) => {
  const queryList = [];
  if (userId) queryList.push(`userId=${userId}`);
  if (policyId) queryList.push(`policyId=${policyId}`);

  const result = await HttpServices.get(
    `${
      config.server_address
    }/Identity/Policy/AssignPolicyToUser?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const RemovePolicyFromInActiveUser = async (userId, policyUserId) => {
  const queryList = [];
  if (userId) queryList.push(`userId=${userId}`);
  if (policyUserId) queryList.push(`policyUserId=${policyUserId}`);

  const result = await HttpServices.delete(
    `${
      config.server_address
    }/Identity/Policy/RemovePolicyFromUser?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateUnitsListingAgent = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Unit/UpdateUnitsListingAgent`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const ReAssignDeActiveUserLeads = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Leads/ReAssignDeActiveUserLeads`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const ReactiveApplicationUser = async (userId, body) => {
  const result = await HttpServices.post(
    `${config.server_address}/Identity/Account/ReactiveApplicationUser/${userId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetTeamLeadsByUserId = async (userId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Users/GetTeamLeadsByUserId?userId=${userId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const BulkUpdateUsers = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/Identity/Account/BulkUpdateUsers`,
      body
    );
    return result;
  } catch (error) {
    throw error.response;
  }
};
