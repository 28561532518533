import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { XeleInput, XeleSelect } from '../Controls';
import { PaginationEnum } from '../../Enums';

export const XelePagination = ({
  pageIndex,
  totalCount,
  pageSize = PaginationEnum[25].key,
  onPageIndexChanged,
  onPageSizeChanged,
  idRef = 'paginationRef',
  parentTranslationPath = 'Shared',
  translationPath = 'pagination',
  perPageText = 'card-per-page',
  pagesText = 'pages',
  ofText = 'of',
  wrapperClasses = '',
}) => {
  const [sizeOfPage, setSizeOfPage] = useState(25);
  const [pageNumber, setPageNumber] = useState(pageIndex + 1);
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });

  useEffect(() => {
    const savedPageSize = localStorage.getItem('Pagination');
    if (savedPageSize) {
      setSizeOfPage(parseInt(savedPageSize));
    } else {
      localStorage.setItem('Pagination', pageSize);
    }
  }, [pageSize]);

  useEffect(() => {
    setPageNumber(pageIndex + 1);
  }, [pageIndex]);

  const handlePageChange = (direction) => {
    let newIndex = pageIndex;
    if (direction === 'first') newIndex = 0;
    else if (direction === 'previous') newIndex -= 1;
    else if (direction === 'next') newIndex += 1;
    else if (direction === 'last') {
      newIndex = Math.floor(totalCount / sizeOfPage) - 1;
      if (!Number.isInteger(totalCount / sizeOfPage)) {
        newIndex = Math.floor(totalCount / sizeOfPage);
      }
    }
    setPageNumber(newIndex + 1);
    if (onPageIndexChanged && newIndex !== pageIndex) {
      onPageIndexChanged(newIndex);
    }
  };

  const handleInputChange = (event) => {
    let { value } = event.target;
    if (+value * sizeOfPage >= totalCount)
      value = Math.ceil(totalCount / sizeOfPage);
    setPageNumber(+value);
    if (
      !Number.isNaN(+value) &&
      +value >= 1 &&
      onPageIndexChanged &&
      value !== pageIndex + 1
    ) {
      onPageIndexChanged(+value - 1);
    }
  };

  const handlePageSizeChange = (selectedSize) => {
    setSizeOfPage(selectedSize);
    localStorage.setItem('Pagination', selectedSize);
    if (onPageSizeChanged) onPageSizeChanged(selectedSize);
  };

  return (
    <div className={`pagination-component-wrapper ${wrapperClasses}`}>
      <div className='pagination-section'>
        <span className='fz-14px fw-medium'>{t(pagesText)}:</span>
        <ButtonBase
          className='btns-icon mx-2 theme-outline'
          disabled={pageIndex === 0}
          onClick={() => handlePageChange('first')}
        >
          <span className='mdi mdi-chevron-double-left' />
        </ButtonBase>
        <ButtonBase
          className='btns-icon mx-2 theme-outline'
          disabled={pageIndex === 0}
          onClick={() => handlePageChange('previous')}
        >
          <span className='mdi mdi-chevron-left' />
        </ButtonBase>
        <XeleInput
          idRef={`${idRef}input`}
          value={pageNumber}
          type='number'
          wrapperClasses='mx-2 mb-0 pagination-input'
          onInputChanged={handleInputChange}
          min={1}
        />
        <ButtonBase
          className='btns-icon mx-2 theme-outline'
          disabled={pageNumber * sizeOfPage >= totalCount}
          onClick={() => handlePageChange('next')}
        >
          <span className='mdi mdi-chevron-right' />
        </ButtonBase>
        <ButtonBase
          className='btns-icon mx-2 theme-outline'
          disabled={pageNumber * sizeOfPage >= totalCount}
          onClick={() => handlePageChange('last')}
        >
          <span className='mdi mdi-chevron-double-right' />
        </ButtonBase>
      </div>
      <div className='pagination-section'>
        <span className='fz-14px fw-medium'>{t(perPageText)}</span>
        <XeleSelect
          idRef={`${idRef}select`}
          data={Object.values(PaginationEnum)}
          value={sizeOfPage}
          wrapperClasses='mx-1'
          themeClass='theme-solid'
          onSelectChanged={handlePageSizeChange}
          valueInput='key'
          textInput='value'
        />
        <span className='px-1'>{pageIndex + 1}</span>
        <span className='mdi mdi-minus' />
        <span className='px-1'>{sizeOfPage}</span>
        <span>{t(ofText)}</span>
        <span className='px-1' id='totalCount-Pagination'>
          {totalCount}
        </span>
      </div>
    </div>
  );
};

XelePagination.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onPageIndexChanged: PropTypes.func.isRequired,
  onPageSizeChanged: PropTypes.func.isRequired,
  pageSize: PropTypes.oneOf(Object.keys(PaginationEnum).map((item) => +item)),
  idRef: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  translationPath: PropTypes.string,
  perPageText: PropTypes.string,
  ofText: PropTypes.string,
  pagesText: PropTypes.string,
  wrapperClasses: PropTypes.string,
};
