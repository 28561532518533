import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './FileInfoCard.scss';
import btnLoader from '../../assets/images/GIF_images/btn-loader.gif';
import { ButtonBase } from '@material-ui/core';
import { LoadableDocumentComponent } from '../../Components';
import { getDownloadableLink } from '../../Helper';
import { HtmlPreviewDialog } from '../HtmlPreviewDialog/HtmlPreviewDialog';

export const FileInfoCard = ({
  onRemoveClick,
  isRemoveHidden,
  fileName,
  fileId,
  templateId,
  templateName,
  templateContent,
  emptyTemplate,
  wrapperClasses,
  onViewClose,
  isLoading,
  isReadOnly,
}) => {
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);
  const [viewedFile, setViewedFile] = useState(null);
  const [filledTemplate, setFilledTemplate] = useState('');

  const fileDownloadHandler = () => {
    const link = document.createElement('a');
    link.setAttribute('download', fileName);
    link.href = getDownloadableLink(fileId);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const filePreviewHandler = () => {
    setIsOpenPreviewDialog(true);
    setViewedFile({
      templateFileId: fileId || templateId,
      templateFileName: fileName || templateName,
      templateText: '',
    });
  };

  const templatePreviewHandler = () => {
    setIsOpenPreviewDialog(true);
  };

  const templatePrintHandler = async () => {
    const content = filledTemplate || templateContent || emptyTemplate;
    const iframeElement = document.getElementById(
      'templatePrintIframe'
    ).contentWindow;
    iframeElement.document.open();
    iframeElement.document.write(content);
    iframeElement.document.close();
    iframeElement.focus();
    iframeElement.print();
  };

  return (
    <>
      <div
        className={`File-Info-Card ${wrapperClasses}`}
        key={fileName || templateName}
      >
        <div>
          {isLoading ? (
            <img
              src={btnLoader}
              className='ml-1 dark-loader'
              width='12'
              height='12'
              alt=''
            />
          ) : (
            <LoadableDocumentComponent
              classes='doc-image w-75'
              fileName={templateName ? `${templateName}.pdf` : fileName || ''}
              alt={''}
            />
          )}
        </div>
        <div>
          <p className='fw-simi-bold light-text '>
            {templateName ? `${templateName}.pdf` : fileName || ''}
          </p>
          <div className='file-actions'>
            {fileId ? (
              <>
                <ButtonBase
                  className='light-text fw-simi-bold pointer'
                  onClick={() => filePreviewHandler()}
                >
                  View
                </ButtonBase>
                <ButtonBase
                  className='c-propx fw-simi-bold pointer'
                  onClick={() => fileDownloadHandler()}
                >
                  Download
                </ButtonBase>
              </>
            ) : null}
            {templateId ? (
              <>
                <ButtonBase
                  className='light-text fw-simi-bold pointer'
                  onClick={() => templatePreviewHandler()}
                >
                  View
                </ButtonBase>
                <ButtonBase
                  className='c-propx fw-simi-bold pointer'
                  disabled={isLoading && !filledTemplate}
                  onClick={templatePrintHandler}
                >
                  Print
                </ButtonBase>
              </>
            ) : null}
          </div>
        </div>
        {onRemoveClick && !isRemoveHidden && (
          <span
            className='mdi mdi-close pointer opacity-8'
            onClick={onRemoveClick}
          />
        )}
      </div>
      {isOpenPreviewDialog && templateId && (
        <HtmlPreviewDialog
          isDialogOpen={isOpenPreviewDialog}
          onSave={(filledTemplate) => {
            setIsOpenPreviewDialog(false);
            onViewClose(filledTemplate);
            setFilledTemplate(filledTemplate);
          }}
          templateId={templateId}
          templateName={templateName}
          externalHtmlContent={templateContent}
          isReadOnly={isReadOnly}
          onClose={() => {
            setIsOpenPreviewDialog(false);
          }}
        />
      )}
      <iframe
        id='templatePrintIframe'
        style={{ minHeight: 500, display: 'none' }}
        width='100%'
        height='100%'
        title=''
      />
    </>
  );
};

FileInfoCard.propTypes = {
  fileId: PropTypes.string,
  fileName: PropTypes.string,
  templateId: PropTypes.string,
  templateName: PropTypes.string,
  templateContent: PropTypes.string,
  wrapperClasses: PropTypes.string,
  onRemoveClick: PropTypes.func,
  onViewClose: PropTypes.func,
  isRemoveHidden: PropTypes.bool,
  isLoading: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};
FileInfoCard.defaultProps = {
  isRemoveHidden: false,
  isReadOnly: false,
  templateContent: '',
  emptyTemplate: '',
  onViewClose: () => {},
};
