import React, { useCallback } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';

export const XeleCheckbox = ({
  data,
  labelValue = '',
  label = '',
  ariaLabel,
  singleChecked,
  isRow = false,
  translationPath = '',
  parentTranslationPath = '',
  translationPathForData,
  onSelectedCheckboxClicked,
  onSelectedCheckboxChanged,
  checked,
  indeterminate,
  key = 'checkboxGroups',
  isDisabledInput,
  formControlLabelClassesInput,
  checkboxClassesInput,
  isDisabled = false,
  isRequired = false,
  labelInput,
  wrapperClasses = '',
  checkboxClasses = '',
  labelClasses = '',
  formControlLabelClasses = '',
  checkboxGroupClasses,
  themeClass = 'theme-default',
  tabIndex,
  disableRipple,
  singleIndeterminate = false,
  title = '',
  idRef,
}) => {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });
  const onChangeHandler = useCallback(
    (item, index) => (event, checkedValue) => {
      if (onSelectedCheckboxChanged)
        onSelectedCheckboxChanged(item, index, checkedValue, event);
    },
    [onSelectedCheckboxChanged]
  );
  return (
    <FormControl
      required={isRequired}
      className={`checkbox-groups-wrapper ${wrapperClasses} ${
        themeClass || ''
      }`}
      component='fieldset'
    >
      {labelValue && (
        <label
          htmlFor={idRef}
          className={`label-wrapper ${labelClasses}${
            isDisabled ? ' disabled' : ''
          }`}
        >
          {t(`${labelValue}`)}
        </label>
      )}
      {!data && (
        <FormControlLabel
          disabled={isDisabled}
          className={`form-control-label ${formControlLabelClasses}`}
          tabIndex={tabIndex}
          title={title}
          control={
            <Checkbox
              className={`checkbox-wrapper ${checkboxClasses}`}
              icon={<span className='i-unchecked' />}
              checkedIcon={<span className='mdi mdi-check' />}
              indeterminateIcon={<span className='mdi mdi-minus' />}
              checked={singleChecked}
              disableRipple={disableRipple}
              indeterminate={singleIndeterminate}
              onClick={onSelectedCheckboxClicked}
              onChange={onSelectedCheckboxChanged}
            />
          }
          label={t(`${label}`)}
          id={idRef || labelValue || label || ''}
        />
      )}
      {data && (
        <FormGroup
          aria-label={
            ariaLabel
              ? t(`${translationPathForData}${ariaLabel}`)
              : 'Checkbox Group'
          }
          row={isRow}
          className={`checkbox-group-wrapper ${checkboxGroupClasses}`}
          id={idRef}
        >
          {data.map((item, index) => (
            <FormControlLabel
              key={`${key}${index + 1}`}
              disabled={isDisabledInput ? item[isDisabledInput] : isDisabled}
              className={`form-control-label ${formControlLabelClasses} ${
                (formControlLabelClassesInput &&
                  item[formControlLabelClassesInput]) ||
                ''
              }`}
              onChange={onChangeHandler(item, index)}
              control={
                <Checkbox
                  className={`checkbox-wrapper ${checkboxClasses} ${
                    (checkboxClassesInput && item[checkboxClassesInput]) || ''
                  }`}
                  icon={<span className='i-unchecked' />}
                  checkedIcon={<span className='mdi mdi-check' />}
                  indeterminateIcon={<span className='mdi mdi-minus' />}
                  checked={checked && checked(item, index)}
                  indeterminate={indeterminate && indeterminate(item, index)}
                  onChange={onChangeHandler(item, index)}
                />
              }
              label={
                label ||
                (labelInput
                  ? ((translationPathForData ||
                      translationPathForData === '') &&
                      t(`${translationPathForData}${item[labelInput]}`)) ||
                    item[labelInput]
                  : item)
              }
            />
          ))}
        </FormGroup>
      )}
    </FormControl>
  );
};

XeleCheckbox.propTypes = {
  data: PropTypes.instanceOf(Array),
  idRef: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  labelValue: PropTypes.string,
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  translationPathForData: PropTypes.string,
  // value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onSelectedCheckboxChanged: PropTypes.func,
  onSelectedCheckboxClicked: PropTypes.func,
  singleChecked: PropTypes.bool,
  singleIndeterminate: PropTypes.bool,
  checked: PropTypes.func,
  indeterminate: PropTypes.func,
  key: PropTypes.string,
  isDisabledInput: PropTypes.string,
  formControlLabelClassesInput: PropTypes.string,
  checkboxClassesInput: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isRow: PropTypes.bool,
  // valueInput: PropTypes.string,
  labelInput: PropTypes.string,
  label: PropTypes.string,
  wrapperClasses: PropTypes.string,
  checkboxClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  formControlLabelClasses: PropTypes.string,
  checkboxGroupClasses: PropTypes.string,
  themeClass: PropTypes.oneOf([
    'theme-default',
    'theme-secondary',
    'theme-propx',
  ]),
  tabIndex: PropTypes.number,
  disableRipple: PropTypes.bool,
  title: PropTypes.string,
};
