import {
  UserLoginPermissions,
  TeamPermissions,
  RolesPermissions,
  AdminDashboardPermissions,
  BranchesPermissions,
  ReportBuilderPermissions,
} from '../../Permissions';
import {
  LookupsPermissions,
  FormBuilderPermissions,
} from '../../Permissions/SystemParametersPermissions';

export const MainMenu = [
  {
    groupId: 1,
    order: 1,
    description: '',
    icon: 'mdi mdi-chart-scatter-plot-hexbin c-blue-lighter',
    iconActive: 'mdi mdi-chart-scatter-plot-hexbin c-white',
    isDisabled: false,
    roles: [
      ...Object.values(AdminDashboardPermissions),
    ],
    name: 'dashboard',
    newIcon: 'icon-dashboard-route',
  },
  {
    groupId: 2,
    order: 1,
    description: '',
    icon: 'mdi mdi-account-tie c-blue-lighter',
    iconActive: 'mdi mdi-account-tie c-white',
    isDisabled: false,
    roles: [],
    name: 'candidates',
    newIcon: 'icon-candidates-route',
  },
  {
    groupId: 16,
    order: 14,
    description: '',
    icon: 'mdi mdi-view-list-outline c-blue-lighter',
    iconActive: 'mdi mdi-view-list-outline c-white',
    isDisabled: false,
    roles: [
      ...Object.values(LookupsPermissions),
      // ...Object.values(FormBuilderPermissions),
    ],
    name: 'system-parameters',
    newIcon: 'icon-system-parameters-route',
  },
  // {
  //   groupId: 23,
  //   order: 16,
  //   description: '',
  //   icon: 'mdi mdi-clipboard-text c-blue-lighter',
  //   iconActive: 'mdi mdi-clipboard-text c-white',
  //   isDisabled: false,
  //   roles: [
  //     ...Object.values(ReportBuilderPermissions),
  //   ],
  //   name: 'reports',
  //   newIcon: 'icon-reports-route',
  // },
  // {
  //   groupId: 24,
  //   order: 17,
  //   description: '',
  //   icon: 'mdi  mdi-format-indent-increase c-blue-lighter',
  //   iconActive: 'mdi  mdi-format-indent-increase c-white',
  //   isDisabled: false,
  //   roles: [
  //   ],
  //   name: 'system-log',
  //   newIcon: 'icon-system-logs-route',
  // },
  {
    groupId: 4,
    order: 19,
    description: '',
    icon: 'mdi mdi-cog-outline c-blue-lighter',
    iconActive: 'mdi mdi-cog-outline c-white',
    isDisabled: false,
    roles: [
      ...Object.values(UserLoginPermissions),
      ...Object.values(TeamPermissions),
      ...Object.values(RolesPermissions),
      ...Object.values(BranchesPermissions),
    ],
    name: 'system-admin',
    newIcon: 'icon-system-admin-route',
  },
  {
    groupId: 5,
    order: 20,
    description: '',
    icon: 'mdi mdi-account-multiple-outline c-blue-lighter',
    iconActive: 'mdi mdi-account-multiple-outline c-white',
    isDisabled: false,
    roles: [],
    name: 'rotation-schema',
    newIcon: 'icon-rotation-schema-route',
  },
];
