import React, { memo, useState, useEffect, useCallback, useRef } from 'react';
import Collapse from '@material-ui/core/Collapse';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import moment from 'moment';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useTranslation } from 'react-i18next';
import Ellipse from '../../../../assets/images/defaults/Ellipse.svg';
import {
  GlobalNavigate,
  GlobalTranslate,
  getDownloadableLink,
  showSuccess,
  showError,
} from '../../../../Helper';
import { ImportFileNotificationHub } from '../../../../Hubs';
import { GetMyImportProcess } from '../../../../Services/File';
import {
  CrmDfmNotificationHub,
} from '../../../../Hubs/CrmDFMNotificationHub';
import {
  LoadableImage,
  PermissionsComponent,
  Spinner,
} from '../../../../Components';
import { LoadableImageEnum } from '../../../../Enums';
import { Button, Tooltip } from '@material-ui/core';
import file_notf from '../../../../assets/images/defaults/file_notf.png';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';

const translationPath = 'notificationsView.';
let notificationContext;
let AlertContext;
const notificationsIcons = [
  {
    type: 1,
    classes: 'icons circle-ripple i-cloud',
  },
  {
    type: 2,
    classes: 'icons circle-ripple bg-green-light i-upload',
  },
  {
    type: 3,
    classes: 'circle-ripple bg-warning-light mdi mdi-close',
  },
  {
    type: 4,
    classes: 'icons circle-ripple bg-info i-upload',
  },
  {
    type: 5,
    classes: 'icons circle-ripple bg-warning-light i-shield',
  },
];

const localStoragedat = JSON.parse(
  localStorage.getItem('ActionsReadDataStorage')
);
const NotificationNumberlocal = JSON.parse(
  localStorage.getItem('NotificationNumber')
);

const getHeaderName = (importProcceseStatus) => {
  if (importProcceseStatus === 1) return `${translationPath}reading-file`;
  if (importProcceseStatus === 2)
    return `${translationPath}reading-file-failed`;
  if (importProcceseStatus === 3)
    return `${translationPath}finish-reading-and-start-importing`;
  if (importProcceseStatus === 4)
    return `${translationPath}imported-successfully`;
  return 'N/A';
};
const getAction = (actionItem) => {
  const object = {
    id: actionItem.importProcceseId,
    processName: null,
    createdOn: null,
    type: null,
    header: null,
    text: null,
    withProgress: false,
  };

  object.header = GlobalTranslate.t(
    `HeaderView:${getHeaderName(actionItem.importProcceseStatus)}`
  );
  if (actionItem.importProcceseStatus === 1) {
    object.text = `${object.header} ${actionItem.fileName}`;
    object.type = 4;
    object.withProgress = true;
    object.createdOn = actionItem.createdOn;
  } else if (actionItem.importProcceseStatus === 2) {
    object.text =
      GlobalTranslate.t(
        `HeaderView:${translationPath}error-while-reading-file`
      ) + actionItem.fileName;
    object.type = 3;
    object.createdOn = actionItem.createdOn;
  } else if (actionItem.importProcceseStatus === 3) {
    object.text = `${object.header} ${object.processName}`;
    object.type = 4;
    object.createdOn = actionItem.createdOn;
    object.withProgress = true;
  } else if (actionItem.importProcceseStatus === 4) {
    object.text = `${object.processName} ${object.header}`;
    object.type = 2;
    object.createdOn = actionItem.createdOn;
  }
  return object;
};

const NotificationsView = memo(
  ({
    isOpen,
    top,
    getCurrentNotificationNumber,
    loginResponse,
    NewNotification,
  }) => {
    const translationPath = 'notificationsView ';
    const { t } = useTranslation('HeaderView', {
      keyPrefix: translationPath,
    });
    const [totalNotificationNumberUnRead, setTotalNotificationNumberUnRead] =
      useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [NotificationNumber, setNotificationNumber] = useState(
      NotificationNumberlocal || 0
    );
    const [
      totalImportantNotificationNumberUnRead,
      setTotalImportantNotificationNumberUnRead,
    ] = useState(0);
    const [currentImportantTab, setCurrentImportantTab] = useState(0);
    const [importantNotifications, setImportantNotifications] = useState([]);

    const [
      openImportantNotificationReadMore,
      setOpenImportantNotificationReadMore,
    ] = useState({
      itemIndex: null,
      isSelected: false,
    });
    const [prevPage, setPrevPage] = useState(0);
    const [notificationsAlerts, setNotificationsAlerts] = useState([]);
    const [currPage, setCurrPage] = useState(10);
    const [currentTab, setCurrentTab] = useState(0);
    const [currentTabAlert, setCurrentTabAlert] = useState(0);
    const listInnerRef = useRef();
    const listInnerImportentRef = useRef();
    const [notificationItems, setNotificationsItems] = useState([]);
    const [notificationItemsimportant, setNotificationsItemsimportant] =
      useState([]);
    const [ActionsReadData, setActionsReadData] = useState(
      localStoragedat || []
    );
    const [lastList, setLastList] = useState(false);
    const [actions, setActions] = useState([]);
    const navigate = useNavigate();
    const [openReadMore, setOpenReadMore] = useState({
      itemIndex: null,
      isSelected: false,
    });

    const openReadMoreDiv = (item, index) => {
      if (currentTab === 2) {
        if (item && item.body && item.body.length < 64) return 'HideReadMore';
        else if (
          item &&
          item.body &&
          item.body.length > 64 &&
          openReadMore.itemIndex === index
        )
          return 'ReadMoreInfoIsSelected';
        else if (
          item &&
          item.body &&
          item.body.length > 64 &&
          openReadMore.itemIndex !== index
        )
          return 'DisplayReadMoreBtn';
      } else if (currentTab === 0) {
        if (item && item.body && item.body.length < 64) return 'HideReadMore';
        else if (
          item &&
          item.body &&
          item.body.length > 64 &&
          openImportantNotificationReadMore.itemIndex === index
        )
          return 'ReadMoreInfoIsSelected';
        else if (
          item &&
          item.body &&
          item.body.length > 64 &&
          openImportantNotificationReadMore.itemIndex !== index
        )
          return 'DisplayReadMoreBtn';
      }
    };

    const ChangeImportantTabs = (val) => {
      setCurrentImportantTab(val);
    };

    const actionsHandler = useCallback((data) => {
      data.map((item) => {
        setActions((elements) => {
          const localActions = elements;
          const itemIndex = elements.findIndex(
            (element) => element.id === item.importProcceseId
          );
          if (itemIndex === -1) return [{ ...getAction(item) }, ...elements];
          localActions[itemIndex] = getAction(item);
          return [...localActions];
        });
        return null;
      });
    }, []);

    const getIcon = (type) =>
      notificationsIcons.find((item) => item.type === type).classes;

    const goToAlertActions = useCallback(
      (item) => () => {
        saveHandlerMarkNotificationAsRead(
          item && item.notificationHistoryId,
          true,
          currPage,
          currentTab
        );
      },
      [currPage, currentTab]
    );

    const goToActions = useCallback(
      (action) => () => {
        if (action.type !== 2) return;
        GlobalNavigate(`/home/import-details?id=${action.id}`);
        const found = (localStoragedat || []).find(
          (element) => element.id === action.id
        );
        if (!found) {
          setActionsReadData((items) => {
            items.push(action);
            return [...items];
          });
          GetMyImportProcess(1, 10).then((data) => {
            if (data) actionsHandler(data.result);
            else setActions([]);
          });
        }
      },
      []
    );

    const withProgressNumber = useCallback(
      () => actions.filter((item) => item.withProgress).length,
      [actions]
    );




    const MarkAllIsReadHandler = async () => {
      setIsLoading(true);
      const res = await []
      if (!(res && res.status && res.status !== 200)) {
        {
          showSuccess(
            t(
              `${translationPath}mark-all-user-notifications-as-read-successfully`
            )
          );
          GetMyNotificationsapi(10, currentTab);
        }
      } else showError(t(`mark-all-user-notifications-as-read-failed`));
      setIsLoading(false);
    };

    useEffect(() => {
      if (loginResponse) {
        if (!AlertContext) {
          AlertContext = CrmDfmNotificationHub();
          AlertContext.start()
            .then(() => AlertContext.invoke('GetConnectionId'))
            .catch(() => {});
          fetchDataNotification();
        }
      } else AlertContext = null;
    }, [loginResponse]);

    useEffect(() => {
      if (loginResponse) {
        if (!notificationContext) {
          notificationContext = ImportFileNotificationHub();
          notificationContext
            .start()
            .then(() => notificationContext.invoke('GetConnectionId'))
            .catch(() => {});
          GetMyImportProcess(1, 10).then((data) => {
            if (data) actionsHandler(data.result);
            else setActions([]);
          });
        }
      } else notificationContext = null;
    }, [actionsHandler, loginResponse]);

    // Call api when scroll
    const fetchDataNotification = async () => {
      if (currentTab === 0) {
        const response = []
        if (
          !(
            (response &&
              response.notificationItems &&
              response.notificationItems.result.length) ||
            0
          )
        ) {
          setLastList(true);
          return;
        }
        setNotificationsItems(response?.notificationItems?.result || []);
      }
      if (currentTab === 0) {
        const responseddd = []
        if (
          !(
            (responseddd &&
              responseddd.notificationItems &&
              responseddd.notificationItems.result.length) ||
            0
          )
        ) {
          setLastList(true);
          return;
        }
        setPrevPage(currPage);
        setNotificationsItemsimportant(
          responseddd?.notificationItems?.result || []
        );
      }
    };
    // Call api when scroll

    // ChangeTabs
    const ChangeTabs = (val) => {
      setCurrentTab(val);
      localStorage.setItem('NotificationNumber', JSON.stringify(0)); // To remove Notification Number Iteam
      setNotificationNumber(0);
    };

    const ChangeAlertsTabs = (val) => {
      setCurrentTabAlert(val);
    };

    //  onScroll Fun
    const onScroll = () => {
      if (listInnerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
        if (scrollTop + clientHeight === scrollHeight)
          setCurrPage(currPage + 10);
      }
    };
    const onScrollImportent = () => {
      if (listInnerImportentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          listInnerImportentRef.current;
        if (scrollTop + clientHeight === scrollHeight)
          setCurrPage(currPage + 10);
      }
    };
    //  onScroll Fun

    const handleNotificationRedirect = (item) => {
      if (item.data.leadId != null) {
        const formTypeId = item.formTypeId;
        const leadId = item.data.leadId;
        navigate.push(
          `/home/leads/lead-profile-edit?formType=${formTypeId}&id=${leadId}`
        );
      } else if (item?.data?.UserId) {
        const statusId = item.data.StatusId;
        navigate.push(`/home/users-management?redirectStatus=${statusId}`);
        localStorage.setItem('userNotificationData', JSON.stringify(item.data));
      } else if (item?.data?.inquiryId) {
        let id = item?.data?.inquiryId;
        if (item?.data?.formTypeId === 'ZeroMatchingSale') {
          navigate.push(
            `/home/zero-matching-sale/zero-matching-sale-profile-edit?formType=${44}&id=${id}&activeFormType=${1}`
          );
        } else if (item?.data?.formTypeId === 'ZeroMatchingLease') {
          navigate.push(
            `/home/zero-matching-lease/zero-matching-lease-profile-edit?formType=${47}&id=${id}&activeFormType=${2}`
          );
        }
      }
    };

    // to Clear Notification Number
    useEffect(() => {
      if (isOpen) setNotificationNumber(0);
    }, [isOpen]);
    // to Clear Notification Number

    // to Scroll Page and Get Request
    useEffect(() => {
      try {
        if (!lastList && prevPage !== currPage)
          fetchDataNotification(currentTab);
      } catch (error) {
        console.error('error', error);
      }
    }, [currPage]);
    // to Scroll Page and Get Request

    //
    useEffect(() => {
      try {
        // GetMyimportantNotificationsapi();
      } catch (error) {
        console.error('error', error);
      }
    }, []);
    //
    useEffect(() => {
      getCurrentNotificationNumber(NotificationNumber);
      localStorage.setItem(
        'NotificationNumber',
        JSON.stringify(NotificationNumber)
      );
    }, [NotificationNumber]);

    useEffect(() => {
      notificationContext?.on('NotificationListener', (n) => {
        GetMyImportProcess(1, 10).then((data) => {
          if (data) {
            actionsHandler(data.result);
            setNotificationNumber((items) => items + 1);
            NewNotification(Math.random());
          } else setActions([]);
        });
      });
    }, []);

    useEffect(() => {
      setTotalNotificationNumberUnRead(
        notificationItems.filter((f) => !f.isRead)?.length || 0
      );
    }, [notificationItems]);

    useEffect(() => {
      if (currentTabAlert === 0) setNotificationsAlerts(notificationItems);
      else if (currentTabAlert === 1)
        setNotificationsAlerts(notificationItems.filter((f) => !f.isRead));
    }, [notificationItems, currentTabAlert]);

    useEffect(() => {
      setTotalImportantNotificationNumberUnRead(
        notificationItemsimportant.filter((f) => !f.isRead)?.length || 0
      );
    }, [notificationItemsimportant]);

    useEffect(() => {
      if (currentImportantTab === 0)
        setImportantNotifications(notificationItemsimportant);
      else if (currentImportantTab === 1)
        setImportantNotifications(
          notificationItemsimportant.filter((f) => !f.isRead)
        );
    }, [notificationItemsimportant, currentImportantTab]);

    return (
      <Collapse
        in={isOpen}
        className='collapses absolute-collapse'
        style={{ top }}
      >
        <Spinner isActive={isLoading} isAbsolute />
        <div className='cards'>
          <div className='card-header'>
            <p className='texts-large'>
              {` ${
                currentTab === 2
                  ? NotificationNumberlocal || 0
                  : withProgressNumber()
              } ${t(
                `${translationPath}${currentTab === 1 ? 'new' : 'in-progress'}`
              )}`}
            </p>
            <p>{t(`user-notification`)}</p>
          </div>
          <div className='card-content p-0'>
            <Tabs
              value={currentTab}
              onChange={(event, newValue) => ChangeTabs(newValue)}
              variant='fullWidth'
              indicatorColor='primary'
              textColor='primary'
              className='tabs-wrapper'
              aria-label='icon tabs example'
            >
              <Tab
                label={
                  t(`important`) +
                  `   ${totalImportantNotificationNumberUnRead}`
                }
              />
              <Tab label={t(`actions`)} />
              <Tab
                label={t(`alerts`) + `   ${totalNotificationNumberUnRead}`}
              />
            </Tabs>
            <div
              className='notificationItems-container-tab-1'
              onScroll={onScroll}
              ref={listInnerRef}
            >
              {currentTab === 2 && (
                <div className='d-flex-v-center-h-between px-4'>
                  <Tabs
                    value={currentTabAlert}
                    onChange={(event, newValue) => ChangeAlertsTabs(newValue)}
                    indicatorColor='primary'
                    textColor='primary'
                    className='tabs-wrapper'
                    aria-label='icon tabs example'
                  >
                    <Tab label={t(`all`)} />
                    <Tab
                      label={
                        t(`unread`) + `   ${totalNotificationNumberUnRead}`
                      }
                    />
                  </Tabs>
                  {totalNotificationNumberUnRead > 0 && (
                    <div className=''>
                      <span
                        className='mdi mdi-check-all unRead'
                        onClick={() => {
                          MarkAllIsReadHandler();
                        }}
                      >
                        {t(`mark-all-is-read`)}
                      </span>
                    </div>
                  )}
                </div>
              )}
              {currentTab === 0 && (
                <div className='d-flex-v-center-h-between px-4'>
                  <Tabs
                    value={currentImportantTab}
                    onChange={(event, newValue) =>
                      ChangeImportantTabs(newValue)
                    }
                    indicatorColor='primary'
                    textColor='primary'
                    className='tabs-wrapper'
                    aria-label='icon tabs example'
                  >
                    <Tab label={t(`all`)} />
                    <Tab
                      label={
                        t(`unread`) +
                        `   ${totalImportantNotificationNumberUnRead}`
                      }
                    />
                  </Tabs>
                  {totalImportantNotificationNumberUnRead > 0 && (
                    <div className=''>
                      <span
                        className='mdi mdi-check-all unRead'
                        onClick={() => {
                          MarkAllIsReadHandler();
                        }}
                      >
                        {t(`mark-all-is-read`)}
                      </span>
                    </div>
                  )}
                </div>
              )}
              {currentTab === 2 &&
                notificationsAlerts.length > 0 &&
                notificationsAlerts.map((item, index) => (
                  <React.Fragment key={`actions${index + 1}`}>
                    <div onClick={() => handleNotificationRedirect(item)}>
                      <div className='separator-h' />
                      {(item && item.sourceName === 'ExportReportJob' && (
                        <ButtonBase
                          onClick={goToAlertActions(item, currPage)}
                          className={`btns theme-wide br-0 mt-2  px-4  w-100  px-4  d-flex  flex-h-between ${
                            (item && item.isRead && 'is-read') || ''
                          }`}
                        >
                          <LoadableImage
                            classes='cover-image notifcationImage'
                            type={LoadableImageEnum.image.key}
                            alt={t(`contact-image`)}
                            src={file_notf}
                          />
                          <div className='d-inline-flex-column-center-v mx-1'>
                            <span className='texts-small textNotification'>
                              {(item && item.sourceName) +
                                '     ' +
                                moment(item && item.createdOn)
                                  .locale(i18next.language)
                                  .format('DD/MM/YYYY  h:mm a') ||
                                '' ||
                                ''}{' '}
                              {(item && item.data && item.data.date) || ''}{' '}
                            </span>

                            <span className='texts-large textNotification'>
                              {(item && item.title) || ''}
                            </span>
                          </div>{' '}
                          <Button
                            onClick={() => {
                              const link = document.createElement('a');
                              link.setAttribute('download', item && item.title);
                              link.href = getDownloadableLink(
                                item && item.body
                              );
                              document.body.appendChild(link);
                              link.click();
                              link.remove();
                            }}
                            id='download-ref'
                            className='MuiButtonBase-root MuiButton-root MuiButton-text table-action-btn  btns-icon theme-solid bg-secondary'
                          >
                            <span className='MuiButton-label'>
                              <span className='table-action-icon  mdi mdi-download-outline' />
                            </span>
                          </Button>
                        </ButtonBase>
                      )) || (
                        <ButtonBase
                          onClick={goToAlertActions(item, currPage)}
                          className={`btns theme-wide br-0 mt-2  px-4  alertItem ${
                            (item && item.isRead && 'is-read') || ''
                          }`}
                        >
                          <div className='body-part'>
                            {openReadMoreDiv(item, index) ===
                              'DisplayReadMoreBtn' && (
                              <>
                                <span className='texts-large textNotification'>
                                  {(item && item.body) || ''}
                                </span>
                              </>
                            )}
                            {openReadMoreDiv(item, index) ===
                              'ReadMoreInfoIsSelected' && (
                              <>
                                <div
                                  className={`more-info px-4  read-text mt-2`}
                                >
                                  {(item && item.body) || ''}
                                  <div
                                    className={`mt-2 ${
                                      item.isRead ? 'read-text' : 'unRead'
                                    }`}
                                  >
                                    <span>
                                      {moment(item && item.createdOn)
                                        .locale(i18next.language)
                                        .format('DD MMM YYYY  h:mm a')}
                                      {'  '}
                                    </span>
                                    <span className='fw-bold'>
                                      {item.title}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}

                            {openReadMoreDiv(item, index) ===
                              'HideReadMore' && (
                              <>
                                <span className='texts-large more-info'>
                                  {(item && item.body) || ''}
                                </span>
                                <div
                                  className={`mt-2 ${
                                    item.isRead ? 'read-text' : 'unRead'
                                  }`}
                                >
                                  <span>
                                    {moment(item && item.createdOn)
                                      .locale(i18next.language)
                                      .format('DD MMM YYYY  h:mm a')}
                                  </span>
                                  {'  '}
                                  <span className='fw-bold'>{item.title}</span>
                                </div>
                              </>
                            )}
                            {openReadMoreDiv(item, index) ===
                              'DisplayReadMoreBtn' && (
                              <div className='read-more-btn'>
                                <div
                                  className={'unRead fw-bold'}
                                  style={{ 'text-decoration': 'underline' }}
                                  onClick={() => {
                                    setOpenReadMore((e) => ({
                                      ...e,
                                      itemIndex: index,
                                      isSelected: true,
                                    }));
                                  }}
                                >
                                  {t(`read-more`)}
                                </div>
                              </div>
                            )}
                          </div>
                          {!item.isRead && (
                            <img className='dot' alt='dots' src={Ellipse} />
                          )}
                        </ButtonBase>
                      )}
                    </div>
                  </React.Fragment>
                ))}
            </div>
            <div className='notificationItems-container'>
              {currentTab === 1 &&
                actions.length > 0 &&
                actions.map((item, index) => (
                  <React.Fragment key={`actions${index + 1}`}>
                    <div className='separator-h' />
                    <ButtonBase
                      className={`btns theme-wide br-0 ${
                        (ActionsReadData || []).find(
                          (element) => element.id === item.id
                        )
                          ? 'is-read'
                          : ''
                      }`}
                      onClick={goToActions(item)}
                    >
                      <span className={getIcon(item.type)} />{' '}
                      <div className='d-inline-flex-column-center-v mx-3'>
                        <span className='texts-large textNotification'>
                          {`${item.processName}${' '}${item.header}`}
                          {'     ' +
                            moment(item && item.createdOn)
                              .locale(i18next.language)
                              .format('DD/MM/YYYY  h:mm a') ||
                            '' ||
                            ''}
                        </span>
                        <Tooltip title={item.text || ''}>
                          <span className='texts-small textNotification'>
                            {item.text || ''}
                          </span>
                        </Tooltip>
                      </div>
                    </ButtonBase>
                    {item.withProgress && <LinearProgress />}
                  </React.Fragment>
                ))}
            </div>
            <div
              className='notificationItems-container-tab-1'
              onScroll={onScrollImportent}
              ref={listInnerImportentRef}
            >
              {currentTab === 0 &&
                importantNotifications &&
                importantNotifications.length > 0 &&
                importantNotifications.map((item, index) => (
                  <React.Fragment key={`actions${index + 1}`}>
                    <div onClick={() => handleNotificationRedirect(item)}>
                      <div className='separator-h' />
                      {(item && item.sourceName === 'ExportReportJob' && (
                        <ButtonBase
                          onClick={goToAlertActions(item, currPage)}
                          className={`btns theme-wide br-0 mt-2  px-4  w-100  px-4  d-flex  flex-h-between ${
                            (item && item.isRead && 'is-read') || ''
                          }`}
                        >
                          <LoadableImage
                            classes='cover-image notifcationImage'
                            type={LoadableImageEnum.image.key}
                            alt={t(`contact-image`)}
                            src={file_notf}
                          />
                          <div className='d-inline-flex-column-center-v mx-1'>
                            <span className='texts-small textNotification'>
                              {(item && item.sourceName) +
                                '     ' +
                                moment(item && item.createdOn)
                                  .locale(i18next.language)
                                  .format('DD/MM/YYYY  h:mm a') ||
                                '' ||
                                ''}{' '}
                              {(item && item.data && item.data.date) || ''}{' '}
                            </span>

                            <span className='texts-large textNotification'>
                              {(item && item.title) || ''}
                            </span>
                          </div>{' '}
                          <Button
                            onClick={() => {
                              const link = document.createElement('a');
                              link.setAttribute('download', item && item.title);
                              link.href = getDownloadableLink(
                                item && item.body
                              );
                              document.body.appendChild(link);
                              link.click();
                              link.remove();
                            }}
                            id='download-ref'
                            className='MuiButtonBase-root MuiButton-root MuiButton-text table-action-btn  btns-icon theme-solid bg-secondary'
                          >
                            <span className='MuiButton-label'>
                              <span className='table-action-icon  mdi mdi-download-outline' />
                            </span>
                          </Button>
                        </ButtonBase>
                      )) || (
                        <ButtonBase
                          onClick={goToAlertActions(item, currPage)}
                          className={`btns theme-wide br-0 mt-2  px-4  alertItem ${
                            (item && item.isRead && 'is-read') || ''
                          }`}
                        >
                          <div className='body-part'>
                            {openReadMoreDiv(item, index) ===
                              'DisplayReadMoreBtn' && (
                              <>
                                <span className='texts-large textNotification'>
                                  {(item && item.body) || ''}
                                </span>
                              </>
                            )}
                            {openReadMoreDiv(item, index) ===
                              'ReadMoreInfoIsSelected' && (
                              <>
                                <div
                                  className={`more-info px-4  read-text mt-2`}
                                >
                                  {(item && item.body) || ''}
                                  <div
                                    className={`mt-2 ${
                                      item.isRead ? 'read-text' : 'unRead'
                                    }`}
                                  >
                                    <span>
                                      {moment(item && item.createdOn)
                                        .locale(i18next.language)
                                        .format('DD MMM YYYY  h:mm a')}
                                      {'  '}
                                    </span>
                                    <span className='fw-bold'>
                                      {item.title}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}

                            {openReadMoreDiv(item, index) ===
                              'HideReadMore' && (
                              <>
                                <span className='texts-large more-info'>
                                  {(item && item.body) || ''}
                                </span>
                                <div
                                  className={`mt-2 ${
                                    item.isRead ? 'read-text' : 'unRead'
                                  }`}
                                >
                                  <span>
                                    {moment(item && item.createdOn)
                                      .locale(i18next.language)
                                      .format('DD MMM YYYY  h:mm a')}
                                  </span>
                                  {'  '}
                                  <span className='fw-bold'>{item.title}</span>
                                </div>
                              </>
                            )}
                            {openReadMoreDiv(item, index) ===
                              'DisplayReadMoreBtn' && (
                              <div className='read-more-btn'>
                                <div
                                  className={'unRead fw-bold'}
                                  style={{ 'text-decoration': 'underline' }}
                                  onClick={() => {
                                    setOpenImportantNotificationReadMore(
                                      (e) => ({
                                        ...e,
                                        itemIndex: index,
                                        isSelected: true,
                                      })
                                    );
                                  }}
                                >
                                  {t(`read-more`)}
                                </div>
                              </div>
                            )}
                          </div>
                          {!item.isRead && (
                            <img className='dot' alt='dots' src={Ellipse} />
                          )}
                        </ButtonBase>
                      )}
                    </div>
                  </React.Fragment>
                ))}
            </div>
            <div className='separator-h mb-3' />
          </div>
          <div className='card-footer separator-h s-gray-primary pt-2'>
            {(currentTab === 0 || currentTab === 1 || currentTab === 2) && (
              <PermissionsComponent allowEmptyRoles>
                <ButtonBase
                  className='btns theme-outline mb-1'
                  onClick={() => {
                    if (currentTab === 0)
                      GlobalNavigate('/home/user-notification-details');
                    if (currentTab === 2)
                      GlobalNavigate(
                        '/home/user-notification-details?isImportant=1'
                      );
                    if (currentTab === 1)
                      GlobalNavigate('/home/import-details-actions');
                  }}
                >
                  <span>{t(`view-all`)}</span>
                </ButtonBase>
              </PermissionsComponent>
            )}
          </div>
        </div>
      </Collapse>
    );
  }
);
const mapStateToProps = (state) => {
  const {
    login: { loginResponse },
  } = state;
  return {
    loginResponse,
  };
};

const store = connect(mapStateToProps)(NotificationsView);
NotificationsView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  top: PropTypes.number.isRequired,
  getCurrentNotificationNumber: PropTypes.func.isRequired,
  NewNotification: PropTypes.func.isRequired,
  loginResponse: PropTypes.shape(undefined),
};
NotificationsView.defaultProps = {
  loginResponse: null,
};
export { store as NotificationsView };
