import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { showSuccess } from '../../../../../../Helper';
import { DeleteTeamServices } from '../../../../../../Services/Team';
import { XeleDialog, Spinner } from '../../../../../../Components';

const DeleteTeamDialog = (props) => {
  const { t } = useTranslation('TeamView');
  const parentTranslationPath = 'TeamView';
  const translationPath = '';
  const [loading, setloading] = React.useState(false);

  const DeleteTeamServicesAPI = useCallback(async (props) => {
    setloading(true);
    const res = await DeleteTeamServices(props.deletedId);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t('DeleteDialog.NotificationDeleteTeam'));
      props.close();
    }
    setloading(false);
    props.reloadData();
  }, []);

  return (
    <XeleDialog
      titleText={`${t('DeleteDialog.confirm-message')}`}
      saveText='confirm'
      saveType='button'
      maxWidth='sm'
      dialogContent={(
        <div className='d-flex-column-center'>
          <Spinner isActive={loading} isAbsolute />
          <span className='mdi mdi-close-octagon c-danger mdi-48px' />
          <span>
            {`${t('DeleteDialog.Alarm')}`}
            {' '}
            {props.name}
          </span>
        </div>
      )}
      saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
      isOpen={props.open}
      onSaveClicked={() => DeleteTeamServicesAPI(props)}
      onCloseClicked={() => props.close()}
      onCancelClicked={() => props.close()}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />

  );
};
export default DeleteTeamDialog;
