import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useHashChange = (hashChangeHandler) => {
  const location = useLocation();

  useEffect(() => {
    hashChangeHandler(); // Call handler on initial load

    const unListen = () => {
      if (location.hash) {
        hashChangeHandler(); // Call handler when hash changes
      }
    };

    return unListen;
  }, [location.hash, hashChangeHandler]);
};
