import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { XeleInput } from '../Inputs/XeleInput';

export const XeleAutocomplete = ({
  onChange,
  onOpen,
  data,
  displayLabel,
  defaultValue,
  isLoading = false,
  selectedValues,
  inputValue,
  onInputChange,
  disabledOptions,
  chipsLabel,
  chipsDisabled,
  translationPath = '',
  parentTranslationPath = '',
  labelValue = null,
  inputPlaceholder = null,
  idRef = 'autocompleteRef',
  wrapperClasses = '',
  autocompleteClasses = '',
  variant = 'standard',
  multiple = true,
  isDisabled = false,
  searchClicked,
  isRequired = false,
  inputClasses,
  labelClasses,
  inputWrapperClasses,
  helperText = null,
  error = false,
  inputLabel = null,
  renderOption,
  withLoader = true,
  withoutSearchButton = false,
  buttonOptions = null,
  disableClearable = false,
  renderTags,
  isWithError = false,
  isSubmitted = false,
  paddingReverse,
  inputStartAdornment,
  inputEndAdornment,
  beforeIconClasses,
  afterIconClasses,
  overInputIcon,
  themeClass,
  autocompleteThemeClass,
  inputThemeClass,
  popperClasses = '',
  popperThemeClasses,
  withBackdrop = false,
  dropdownIcon = 'mdi mdi-chevron-down',
  dropdownCloseIcon = 'mdi mdi-close',
  getOptionSelected,
  onInputKeyUp,
  onTextKeyDown,
  onTextKeyUp,
  tagValues,
  filterOptions,
  groupBy,
  autoComplete = 'new-password',
  textValue,
  hideDeleteMark = true,
}) => {
  return (
    <div
      className={`autocomplete-wrapper ${wrapperClasses} ${
        themeClass || autocompleteThemeClass || ''
      }${(multiple && ' is-multiple') || ''}`}
    >
      {!withoutSearchButton && (
        <Button
          className='btns-icon theme-transparent mx-2'
          onClick={searchClicked}
        >
          <span className='mdi mdi-magnify' />
        </Button>
      )}
      <Autocomplete
        multiple={multiple}
        disableClearable={disableClearable}
        autoComplete={autoComplete}
        id={idRef || labelValue || inputPlaceholder || inputLabel || ''}
        className={`autocomplete ${autocompleteClasses}`}
        options={data}
        classes={{
          popper: `autocomplete-popper-wrapper ${popperClasses} ${
            themeClass || popperThemeClasses || ''
          }${(withBackdrop && ' with-backdrop') || ''}`,
        }}
        groupBy={groupBy}
        onKeyUp={onInputKeyUp}
        getOptionLabel={displayLabel}
        renderOption={renderOption}
        defaultValue={defaultValue}
        getOptionSelected={getOptionSelected}
        loading={isLoading}
        onOpen={onOpen}
        value={selectedValues}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onChange={onChange}
        getOptionDisabled={disabledOptions}
        disabled={isDisabled}
        filterOptions={filterOptions}
        closeIcon={
          <span
            className={`${dropdownCloseIcon} dropdown-close-icon-wrapper`}
          />
        }
        popupIcon={<span className={`${dropdownIcon} dropdown-icon-wrapper`} />}
        renderTags={
          renderTags ||
          ((tagValue, getTagProps) =>
            (tagValues || tagValue).map((option, index) =>
              !hideDeleteMark ? (
                <Chip
                  className='autocomplete-chip'
                  label={chipsLabel?.(option, index)}
                  {...getTagProps({ index })}
                  disabled={chipsDisabled?.(option, index)}
                  onDelete={hideDeleteMark}
                />
              ) : (
                <Chip
                  className='autocomplete-chip'
                  label={chipsLabel?.(option, index)}
                  {...getTagProps({ index })}
                  disabled={chipsDisabled?.(option, index)}
                />
              )
            ))
        }
        renderInput={(params) => (
          <XeleInput
            idRef={idRef || inputLabel || inputPlaceholder || labelValue || ''}
            autoComplete={autoComplete}
            label={inputLabel}
            labelValue={labelValue}
            labelClasses={labelClasses}
            autoCompleteParams={params}
            value={textValue}
            inputPlaceholder={inputPlaceholder}
            variant={variant}
            isWithError={isWithError}
            isSubmitted={isSubmitted}
            paddingReverse={paddingReverse}
            isRequired={isRequired}
            wrapperClasses={inputWrapperClasses}
            fieldClasses={inputClasses}
            startAdornment={inputStartAdornment}
            endAdornment={inputEndAdornment}
            beforeIconClasses={beforeIconClasses}
            afterIconClasses={afterIconClasses}
            overInputIcon={overInputIcon}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            buttonOptions={buttonOptions}
            themeClass={themeClass || inputThemeClass}
            onKeyDown={onTextKeyDown}
            onKeyUp={onTextKeyUp}
            isLoading={isLoading}
            withLoader={withLoader}
            error={error}
            helperText={helperText}
          />
        )}
      />
    </div>
  );
};
XeleAutocomplete.propTypes = {
  autoComplete: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  data: PropTypes.instanceOf(Array).isRequired,
  displayLabel: PropTypes.func.isRequired,
  tagValues: PropTypes.instanceOf(Array),
  disableClearable: PropTypes.bool,
  chipsLabel: PropTypes.func,
  renderTags: PropTypes.func,
  getOptionSelected: PropTypes.func,
  groupBy: PropTypes.func,
  renderOption: PropTypes.func,
  searchClicked: PropTypes.func,
  onInputKeyUp: PropTypes.func,
  onTextKeyDown: PropTypes.func,
  onTextKeyUp: PropTypes.func,
  textValue: PropTypes.string,
  selectedValues: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Object),
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  defaultValue: PropTypes.instanceOf(Array),
  isLoading: PropTypes.bool,
  multiple: PropTypes.bool,
  inputValue: PropTypes.string,
  onInputChange: PropTypes.func,
  filterOptions: PropTypes.func,
  disabledOptions: PropTypes.func,
  chipsDisabled: PropTypes.func,
  parentTranslationPath: PropTypes.string,
  translationPath: PropTypes.string,
  labelValue: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  idRef: PropTypes.string,
  wrapperClasses: PropTypes.string,
  autocompleteClasses: PropTypes.string,
  variant: PropTypes.string,
  inputWrapperClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  helperText: PropTypes.string,
  inputLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  error: PropTypes.bool,
  withLoader: PropTypes.bool,
  withoutSearchButton: PropTypes.bool,
  isWithError: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  paddingReverse: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputStartAdornment: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
    PropTypes.node,
  ]),
  inputEndAdornment: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
    PropTypes.node,
  ]),
  beforeIconClasses: PropTypes.string,
  afterIconClasses: PropTypes.string,
  overInputIcon: PropTypes.string,
  themeClass: PropTypes.string,
  autocompleteThemeClass: PropTypes.string,
  inputThemeClass: PropTypes.string,
  popperClasses: PropTypes.string,
  hideDeleteMark: PropTypes.bool,
  popperThemeClasses: PropTypes.string,
  dropdownIcon: PropTypes.string,
  dropdownCloseIcon: PropTypes.string,
  withBackdrop: PropTypes.bool,
  buttonOptions: PropTypes.shape({
    className: PropTypes.string,
    iconClasses: PropTypes.string,
    onActionClicked: PropTypes.func,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
  }),
};
