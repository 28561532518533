import { config } from '../config/config';
import { HttpServices } from '../Helper';

export const GetLookupItems = async (pageIndex, pageSize, lookupTypeName) => {
  const result = await HttpServices.get(
    `${config.server_address}/Lookups/LookupItem/${pageIndex}/${pageSize}?lookupTypeName=${lookupTypeName}`
  )
    .then((data) => data)
    .catch((error) => {
      return undefined;
    });
  return result;
};

export const lookupGet = async ({ lookupType }) => {
  const result = await HttpServices.get(
    `${config.server_address}/api/v1/lookups/${lookupType}/getAll?market=US`
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const GetLookupTypeItems = async ({
  pageIndex,
  pageSize,
  lookupTypeId,
  search,
  filterBy,
  orderBy,
}) => {
  const queryList = [];
  if (lookupTypeId) queryList.push(`lookupTypeId=${lookupTypeId}`);
  if (search) queryList.push(`search=${search}`);
  if (filterBy) queryList.push(`filterBy=${filterBy}`);
  if (orderBy) queryList.push(`orderBy=${orderBy}`);
  const result = await HttpServices.get(
    `${config.server_address}/Lookups/LookupItem/GetLookupsByTypeIdSearch/${
      pageIndex + 1
    }/${pageSize}?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const lookupItemsGet = async ({
  pageIndex,
  pageSize,
  lookupTypeId,
  lookupTypeName,
  lookupParentId,
  searchedItem,
  filterBy,
  orderBy,
}) => {
  const queryList = [];
  if (lookupTypeId) queryList.push(`lookupTypeId=${lookupTypeId}`);
  if (lookupTypeName) queryList.push(`lookupTypeName=${lookupTypeName}`);
  if (lookupParentId) queryList.push(`lookupParentId=${lookupParentId}`);
  if (searchedItem) queryList.push(`search=${searchedItem}`);
  if (filterBy) queryList.push(`filterBy=${filterBy}`);
  if (orderBy) queryList.push(`orderBy=${orderBy}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/Lookups/LookupItem/${pageIndex}/${pageSize}?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const lookupItemsGetId = async ({ lookupTypeId, lookupParentId }) => {
  const queryList = [];
  if (lookupParentId) queryList.push(`lookupParentId=${lookupParentId}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/Lookups/LookupItem/${lookupTypeId}?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetLookupItemsByLookupTypeName = async ({
  lookUpName,
  pageSize,
  pageIndex,
}) => {
  const result = await HttpServices.get(
    `${config.server_address}/Lookups/LookupItem/GetLookupItemsByLookupTypeName?lookupTypeName=${lookUpName}&pageSize=${pageSize}&pageIndex=${pageIndex}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetParentLookupItemById = async ({
  pageIndex,
  pageSize,
  lookupItemId,
}) => {
  const result = await HttpServices.get(
    `${config.server_address}/Lookups/LookupItem/${pageIndex}/${pageSize}?lookupItemId=${lookupItemId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const lookupItemsPost = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/Lookups/LookupItem`,
    body
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const lookupItemsPut = async (lookupItemId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/Lookups/LookupItem/${lookupItemId}`,
    body
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const lookupItemsDelete = async (lookupItemId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/Lookups/LookupItem/${lookupItemId}`
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const lookupTypesGet = async ({
  pageIndex,
  pageSize,
  searchedItem,
  filterBy,
  orderBy,
}) => {
  // eslint-disable-next-line prefer-const
  let queryList = [];
  if (searchedItem) queryList.push(`search=${searchedItem}`);
  if (filterBy) queryList.push(`filterBy=${filterBy}`);
  if (orderBy) queryList.push(`orderBy=${orderBy}`);

  const result = await HttpServices.get(
    `${
      config.server_address
    }/Lookups/LookupType/${pageIndex}/${pageSize}?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};
export const lookupTypesPut = async (lookupTypeId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/Lookups/LookupType/${lookupTypeId}`,
    body
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const lookupTypesDelete = async (lookupTypeId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/Lookups/LookupType/${lookupTypeId}`
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const lookupTypesPost = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/Lookups/LookupType`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
