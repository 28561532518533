/* eslint-disable brace-style */
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useTitle } from '../../../../Hooks';
import { XeleInput } from '../../../../Components/Controls';
import {
  NoDataFoundComponent,
  XelePagination,
  Spinner,
  ViewTypes,
  PermissionsComponent,
  XeleSelect,
  XelePhone,
  XeleAutocomplete,
  ActionsButtonsComponent,
} from '../../../../Components';
import {
  UserCardsComponent,
  UserDeleteDialog,
  UserCancelDialog,
  UserListView,
  CanDeactivateUserDialog,
} from './UserUtilties';
import '.';
import {
  ViewTypesEnum,
  ActionsEnum,
  TableActions,
  UserStatusEnum,
  ActionsButtonsEnum,
} from '../../../../Enums';
import {
  OrganizationUserSearch,
  CanDeActiveUser,
} from '../../../../Services/userServices';
import {
  sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,
  GlobalNavigate,
  bottomBoxComponentUpdate,
} from '../../../../Helper/Middleware.Helper';
import { UserLoginPermissions } from '../../../../Permissions';
import { getIsAllowedPermission } from '../../../../Helper/Permissions.Helper';

import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import { GlobalOrderFilterActions } from '../../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { GetAllRoles } from '../../../../Services/roleServices';

const translationPath = 'Users';

const UserView = () => {
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const dispatch = useDispatch();
  const [viewTypes, setViewTypes] = useState('');
  const [isLoading, setIsLoading] = useState({
    all: false,
    getAllRoleLoading: false,
    userType: false,
    getAllPolicyLoading: false,
  });
  const [nameInput, setNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [userNameInput, setUserNameInput] = useState('');
  const [phoneInput, setPhoneInput] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [status, setStatus] = useState();
  const [users, setUsers] = useState({ result: [], totalCount: 0 });
  const [activeUserItem, setActiveUserItem] = useState(null);
  const [usersTableFilter, setUsersTableFilter] = useState(null);
  const [allRoles, setAllRoles] = useState({
    result: [],
    totalCount: 0,
  });
  const [searchRole, setSearchRole] = useState(null);
  const [activeAction, setActiveAction] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { t } = useTranslation('UsersView', { keyPrefix: translationPath });
  useTitle(t('users'));
  const [sortBy, setSortBy] = useState(null);
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy:
      orderFilter &&
      orderFilter.usersFilter &&
      orderFilter.usersFilter.filterBy,
    orderBy:
      orderFilter && orderFilter.usersFilter && orderFilter.usersFilter.orderBy,
  });
  const [orderBy, setOrderBy] = useState(
    selectedOrderBy.filterBy
      ? selectedOrderBy
      : { filterBy: 'CreatedOn', orderBy: 2 }
  );
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    name: null,
    UserStatus: null,
    userName: null,
    phoneNumber: null,
    email: null,
    userTypeId: null,
    filterBy: orderBy.filterBy,
    orderBy: orderBy.orderBy,
    searchedItem: null,
    rolesIds: [],
  });
  const searchTimer = useRef(null);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isOpenDeactive, setIsOpenDeactive] = useState(false);
  const [isOpenCanDeactive, setIsOpenCanDeactive] = useState(false);
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        usersFilter: {
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      ...orderBy,
      filterBy: selectedOrderBy && selectedOrderBy.filterBy,
      orderBy: selectedOrderBy && selectedOrderBy.orderBy,
    });
    getUsers({
      ...orderBy,
      filterBy: selectedOrderBy && selectedOrderBy.filterBy,
      orderBy: selectedOrderBy && selectedOrderBy.orderBy,
    });
  };

  const getUsers = useCallback(
    async (f) => {
      setIsLoading((loading) => ({ ...loading, all: true }));
      if (
        getIsAllowedPermission(
          Object.values(UserLoginPermissions),
          loginResponse,
          UserLoginPermissions.ViewUsers.permissionsId
        )
      ) {
        const localFilterDto = f || filter || {};
        if (usersTableFilter) {
          Object.values(usersTableFilter).map((item) => {
            if (localFilterDto[item.displayPath]) {
              localFilterDto[item.displayPath] = item.value;
            } else localFilterDto[item.displayPath] = item.value;

            return undefined;
          });
        }

        const res = await OrganizationUserSearch({
          ...filter,
          ...localFilterDto,
          pageIndex: filter.pageIndex + 1,
        });


        if (filter.pageIndex === 0 || viewTypes === ViewTypesEnum.tableView.key)
          setUsers(res);
        else {
          setUsers({
            result: res.result,
            totalCount: res.totalCount,
          });
        }
      }
      setIsLoading((loading) => ({ ...loading, all: false }));
    },
    [usersTableFilter, filter, loginResponse, viewTypes]
  );
  const itemsPerPageChanged = useCallback((event, newItemsPerPage) => {
    setFilter((items) => ({
      ...items,
      pageIndex: 0,
      pageSize: newItemsPerPage.props.value,
    }));
  }, []);

  const searchHandler = (input) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    if (input.target.id === 'usersNameSearchRef') {
      searchTimer.current = setTimeout(() => {
        setFilter((items) => ({ ...items, name: nameInput, pageIndex: 0 }));
      }, 700);
    }
    if (input.target.id === 'usersEmailSearchRef') {
      searchTimer.current = setTimeout(() => {
        setFilter((items) => ({ ...items, email: emailInput, pageIndex: 0 }));
      }, 700);
    }
    if (input.target.id === 'userNameSearchRef') {
      searchTimer.current = setTimeout(() => {
        setFilter((items) => ({
          ...items,
          userName: userNameInput,
          pageIndex: 0,
        }));
      }, 700);
    }
    if (input.target.id === 'usersPhoneSearchRef') {
      searchTimer.current = setTimeout(() => {
        setIsSubmitted(true);
        setFilter((items) => ({
          ...items,
          phoneNumber: phoneInput,
          pageIndex: 0,
        }));
      }, 1200);
    }
  };

  const onTypeChanged = (activeType) => {
    localStorage.setItem('UserViewType', JSON.stringify(activeType));
    setViewTypes(activeType);
    setUsersTableFilter(null);
  };

  const actionClicked = useCallback(
    (actionEnum, item) => async (event) => {
      event.stopPropagation();
      setActiveUserItem(item);
      localStorage.setItem('activeUserItem', JSON.stringify(item));
      if (
        actionEnum === ActionsEnum.delete.key ||
        actionEnum === TableActions.delete.key
      )
        setIsOpenConfirm(true);
      else if (
        actionEnum === ActionsEnum.edit.key ||
        actionEnum === TableActions.edit.key
      ) {
        dispatch(ActiveItemActions.activeItemRequest(item));
        GlobalNavigate(`/home/Users/edit?id=${item.id}`);
      } else if (
        actionEnum === ActionsEnum.close.key ||
        actionEnum === TableActions.close.key
      ) {
        setIsLoading((loading) => ({ ...loading, all: true }));
        const canActivate = await CanDeActiveUser(item.id);
        setIsLoading((loading) => ({ ...loading, all: false }));

        if (
          !(canActivate && canActivate.status && canActivate.status !== 200)
        ) {
          if (canActivate) setIsOpenDeactive(true);
          else setIsOpenCanDeactive(true);
        }
      }
    },
    []
  );
  const activePageChanged = useCallback((e, newPage) => {
    setFilter((item) => ({ ...item, pageIndex: newPage }));
  }, []);

  const getAllRoles = useCallback(async () => {
    setIsLoading((loading) => ({ ...loading, getAllRoleLoading: true }));

    const res = await GetAllRoles(
      filter.pageIndex,
      filter.pageSize,
      searchRole
    );
    if (!(res && res.status && res.status !== 200)) {
      setAllRoles({
        result: res.result,
        totalCount: res.totalCount,
      });
    } else {
      setAllRoles({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((loading) => ({ ...loading, getAllRoleLoading: false }));
  }, [searchRole]);

  useEffect(() => {
    getAllRoles();
  }, [getAllRoles]);

  useEffect(() => {
    if (filter) getUsers(filter);
  }, [getUsers]);

  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  const onFilterValuesChanged = (newValue) => {
    if (newValue && Object.values(newValue).length)
      setUsersTableFilter(newValue);
  };
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const onSelectUser = (user) => {
    setSelectedUsers((users) => users.concat(user));
  };
  const onDeselectUser = (user) => {
    setSelectedUsers((users) => users.filter((u) => u.id !== user.id));
  };

  const actionChangeHandler = (actionId) => {
    if (actionId === ActionsButtonsEnum[1].id) {
      GlobalNavigate(`/home/Users/add`);
    }
  };

  useEffect(() => {
    if (
      getIsAllowedPermission(
        Object.values(UserLoginPermissions),
        loginResponse,
        UserLoginPermissions.ViewUsers.permissionsId
      )
    ) {
      bottomBoxComponentUpdate(
        <XelePagination
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={users && users.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  useEffect(() => {
    if (sortBy) {
      if (sortBy.filterBy === 'UserStatus') {
        sortBy.filterBy = 'UserStatusId';
        setFilter((item) => ({
          ...item,
          filterBy: sortBy.filterBy,
          orderBy: sortBy.orderBy,
        }));
      } else
        setFilter((item) => ({
          ...item,
          filterBy: sortBy.filterBy,
          orderBy: sortBy.orderBy,
        }));
    }
  }, [sortBy]);

  useEffect(() => {
    localStorage.setItem('bulk-update-users', JSON.stringify(selectedUsers));
  }, [selectedUsers]);

  return (
    <>
      <div className='view-wrapper'>
        <Spinner isActive={isLoading.all} />

        <div className='header-section'>
          <div className='filter-section px-2'>
            <div className='section'>
              <ActionsButtonsComponent
                onActionButtonChanged={(activeAction) =>
                  setActiveAction(activeAction)
                }
                // isDisabled={activeAction !== ActionsButtonsEnum[4].id}
                // checkDisable={() => selectedUsers.length < 2}
                onActionsButtonClicked={actionChangeHandler}
              />
            </div>
            <div className='d-flex-v-center-h-end mb-3'>
              <PermissionsComponent
                permissionsList={Object.values(UserLoginPermissions)}
                permissionsId={UserLoginPermissions.ViewUsers.permissionsId}
              >
                <div className='d-flex-column mxw-60 field-wrapper'>
                  <span className='m-2'>{t('filter-By')}</span>
                  <div className='d-flex'>
                    <div className='w-50 mr-1-reversed'>
                      <XeleInput
                        idRef='usersNameSearchRef'
                        fieldClasses='inputs theme-solid'
                        label={t('filter-by-name')}
                        value={nameInput}
                        onInputChanged={(event) =>
                          setNameInput(event.target.value)
                        }
                        onKeyUp={searchHandler}
                        inputPlaceholder={t('search-users-by-name')}
                      />
                    </div>

                    <div className='w-50 mr-1-reversed'>
                      <XeleInput
                        idRef='userNameSearchRef'
                        fieldClasses='inputs theme-solid'
                        value={userNameInput}
                        onInputChanged={(event) =>
                          setUserNameInput(event.target.value)
                        }
                        inputPlaceholder={t('search-users-by-full-name')}
                        onKeyUp={searchHandler}
                        label={t('filter-by-fullname')}
                      />
                    </div>
                    <div className='w-50 mr-1-reversed'>
                      <XeleInput
                        idRef='usersEmailSearchRef'
                        fieldClasses='inputs theme-solid'
                        value={emailInput}
                        label={t('filter-by-email')}
                        inputPlaceholder={t('search-users-by-email')}
                        onInputChanged={(event) =>
                          setEmailInput(event.target.value)
                        }
                        onKeyUp={searchHandler}
                      />
                    </div>
                  </div>

                  <div className='d-flex '>
                    <div className='w-50 mr-1-reversed'>
                      <XeleSelect
                        data={Object.values(UserStatusEnum).filter(
                          (item) => item.label !== 'Pending'
                        )}
                        value={status || 0}
                        emptyItem={{
                          value: 0,
                          text: t('select-status'),
                          isDisabled: false,
                        }}
                        valueInput='key'
                        textInput='label'
                        onSelectChanged={(value) => {
                          setStatus(value);
                          return setFilter((items) => ({
                            ...items,
                            UserStatusId: value,
                            pageIndex: 0,
                          }));
                        }}
                      />
                    </div>
                    <div className='mr-1-reversed'>
                      <XeleAutocomplete
                        idRef='rolesSearchRef'
                        multiple
                        data={allRoles.result || []}
                        chipsLabel={(option) => option.rolesName || ''}
                        displayLabel={(option) => option.rolesName || ''}
                        withoutSearchButton
                        isLoading={isLoading.getAllRoleLoading}
                        inputPlaceholder={t('roles-filter')}
                        onInputChange={(e) => {
                          const { value } = e.target;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            setSearchRole(value);
                          }, 700);
                        }}
                        onChange={(e, newValue) => {
                          const list = [];
                          newValue &&
                            newValue.map((element) =>
                              list.push(element.rolesId)
                            );
                          setFilter((f) => ({ ...f, rolesIds: list }));
                        }}
                      />
                    </div>
                    <div className='w-50 mr-1-reversed'>
                      <XelePhone
                        idRef='usersPhoneSearchRef'
                        value={phoneInput}
                        inputPlaceholder='Phone'
                        isSubmitted={isSubmitted}
                        onInputChanged={(event) => setPhoneInput(event)}
                        onKeyUp={searchHandler}
                      />
                    </div>
                  </div>
                </div>
              </PermissionsComponent>
              <PermissionsComponent
                permissionsList={Object.values(UserLoginPermissions)}
                permissionsId={UserLoginPermissions.ViewUsers.permissionsId}
              >
                <div>
                  <ViewTypes
                    onTypeChanged={onTypeChanged}
                    initialActiveType={viewTypes}
                    activeTypes={[
                      ViewTypesEnum.tableView.key,
                      ViewTypesEnum.cards.key,
                    ]}
                    className='mb-3'
                  />
                </div>
              </PermissionsComponent>
            </div>
          </div>
        </div>
        <PermissionsComponent
          permissionsList={Object.values(UserLoginPermissions)}
          permissionsId={UserLoginPermissions.ViewUsers.permissionsId}
        >
          <NoDataFoundComponent />
          <div className='d-flex px-2'>
            <span className='mx-2 mt-1'>{t('Users')}</span>
            <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
            <span className='px-2 d-flex'>
              <span className='texts-large mt-1'>{t('order-by')}:</span>
              <div className='px-2'>
                <XeleSelect
                  idRef='filterByRef'
                  data={[
                    { id: 'CreatedOn', filterBy: t('created-on') },
                    { id: 'UpdateOn', filterBy: t('last-updated') },
                  ]}
                  value={selectedOrderBy.filterBy}
                  onSelectChanged={filterByChanged}
                  wrapperClasses='mb-3'
                  isRequired
                  valueInput='id'
                  textInput='filterBy'
                  emptyItem={{
                    value: null,
                    text: t('select-filter-by'),
                    isDisabled: false,
                  }}
                />
              </div>
              <div className='px-2'>
                <XeleSelect
                  idRef='orderByRef'
                  data={[
                    { id: 1, orderBy: t('ascending') },
                    { id: 2, orderBy: t('descending') },
                  ]}
                  value={selectedOrderBy.orderBy} //
                  onSelectChanged={orderByChanged}
                  wrapperClasses='mb-3'
                  isRequired
                  valueInput='id'
                  textInput='orderBy'
                  emptyItem={{
                    value: null,
                    text: t('select-filter-by'),
                    isDisabled: false,
                  }}
                />
              </div>
              <div className='mt-1'>
                <ButtonBase
                  className='btns theme-solid'
                  onClick={orderBySubmitted}
                  disabled={
                    !selectedOrderBy.filterBy || !selectedOrderBy.orderBy
                  }
                >
                  <span>{t('apply')}</span>
                </ButtonBase>
              </div>
            </span>
          </div>
        </PermissionsComponent>
        <PermissionsComponent
          permissionsList={Object.values(UserLoginPermissions)}
          permissionsId={UserLoginPermissions.ViewUsers.permissionsId}
        >
          {viewTypes === ViewTypesEnum.tableView.key && (
            <UserListView
              data={users}
              actionClicked={(actionEnum, item, focusedRow, event) =>
                actionClicked(actionEnum, item)(event)
              }
              activePageChanged={activePageChanged}
              itemsPerPageChanged={itemsPerPageChanged}
              filter={filter}
              setSortBy={setSortBy}
              onFilterValuesChanged={onFilterValuesChanged}
              isWithCheckboxColumn={ActionsButtonsEnum[4].id === activeAction}
              selectedUsers={selectedUsers}
              onSelectUser={onSelectUser}
              onDeselectUser={onDeselectUser}
            />
          )}
        </PermissionsComponent>
        <PermissionsComponent
          permissionsList={Object.values(UserLoginPermissions)}
          permissionsId={UserLoginPermissions.ViewUsers.permissionsId}
        >
          {viewTypes !== ViewTypesEnum.tableView.key && (
            <UserCardsComponent
              data={users}
              enableUserSelect={ActionsButtonsEnum[4].id === activeAction}
              onSelectUser={onSelectUser}
              onDeselectUser={onDeselectUser}
              actionClicked={actionClicked}
              translationPath={translationPath}
            />
          )}
        </PermissionsComponent>
      </div>
      {activeUserItem && (
        <>
          <UserDeleteDialog
            activeUserItem={activeUserItem}
            isOpen={isOpenConfirm}
            isOpenChanged={() => {
              setIsOpenConfirm(false);
              setActiveUserItem(null);
            }}
            reloadData={() => {
              setFilter((item) => ({ ...item, pageIndex: 0 }));
              setActiveUserItem(null);
              setIsOpenConfirm(false);
            }}
          />
          <UserCancelDialog
            activeUserItem={activeUserItem}
            isOpen={isOpenDeactive}
            isOpenChanged={() => {
              setIsOpenDeactive(false);
              setActiveUserItem(null);
            }}
            reloadData={() => {
              setFilter((item) => ({ ...item, pageIndex: 0 }));
              setActiveUserItem(null);
              setIsOpenDeactive(false);
            }}
          />
          {isOpenCanDeactive && (
            <CanDeactivateUserDialog
              activeUserItem={activeUserItem}
              isOpen={isOpenCanDeactive}
              isOpenChanged={() => {
                setActiveUserItem(null);
                setIsOpenCanDeactive(false);
              }}
              reloadData={() => {
                setFilter((item) => ({ ...item, pageIndex: 0 }));
                setActiveUserItem(null);
                setIsOpenCanDeactive(false);
              }}
            />
          )}
        </>
      )}
    </>
  );
};
export { UserView };
