import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import image from '../../assets/images/defaults/loader.gif';
import errorImage from '../../assets/images/defaults/error-image-generic.png';
import { LoadableImageEnum } from '../../Enums';

export const LoadableImage = ({
  src,
  alt,
  type = LoadableImageEnum.image.key,
  classes,
  withOverlay,
  overlayText,
  overlayImage,
  onFinishLoading,
  defaultImage,
  ...props
}) => {
  const [state, setState] = useState(false);
  const [error, setError] = useState(false);
  const mounted = useRef(true);
  useEffect(() => {
    if (type === LoadableImageEnum.div.key) {
      const bgImg = new Image();
      bgImg.src = src;
      bgImg.onload = () => {
        if (mounted.current) {
          setState(true);
          if (onFinishLoading) onFinishLoading();
        }
      };
      bgImg.onerror = () => {
        if (mounted.current) {
          setError(true);
          onFinishLoading?.();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted, src, type]);
  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );
  if (error) {
    return (
      <img
        src={defaultImage ?? errorImage}
        alt={alt}
        className={`${classes}`}
        {...props}
      />
    );
  }
  return (
    <>
      {type === LoadableImageEnum.image.key && state && (
        <img
          src={src}
          alt={alt}
          className={`${classes}`}
          onLoad={() => {
            setState(true);
            onFinishLoading?.();
          }}
          onError={() => setError(true)}
          {...props}
        />
      )}
      {type === LoadableImageEnum.div.key && state && (
        <div
          className={classes}
          aria-label={alt}
          {...props}
          style={{
            ...props.style,
            backgroundImage: `url(${src})`,
          }}
        />
      )}
      {!state && (
        <img src={image} alt={alt} className={`${classes}}`} {...props} />
      )}
      {withOverlay && (
        <div className='loadable-overlay'>
          {overlayText && <span>{overlayText}</span>}
          {overlayImage && <span className={overlayImage} />}
        </div>
      )}
    </>
  );
};
LoadableImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  type: PropTypes.oneOf(
    Object.values(LoadableImageEnum).map((item) => item.key)
  ),
  classes: PropTypes.string,
  onFinishLoading: PropTypes.func,
  withOverlay: PropTypes.bool,
  overlayText: PropTypes.string,
  overlayImage: PropTypes.string,
  props: PropTypes.instanceOf(Object),
};
