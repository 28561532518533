import { ButtonBase } from '@material-ui/core';
import moment from 'moment';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  XeleAutocomplete,
  XeleDialog,
  XeleInput,
  NoSearchResultComponent,
  XelePagination,
  XeleSelect,
  Spinner,
  ViewTypes,
  PermissionsComponent,
} from '../../../Components';
import { XeleDateRangePicker } from '../../../Components/Controls/DateRangePickerComponent/XeleDateRangePicker';
import { ViewTypesEnum } from '../../../Enums';
import {
  bottomBoxComponentUpdate,
  GlobalNavigate,
  showError,
  showSuccess,
  returnPropsByPermissions,
} from '../../../Helper';
import { useTitle } from '../../../Hooks';
import {
  CardReportsBuilder,
  TablesReportsBuilder,
} from './ReportsBuilderManagementView';
import { ReportBuilderPermissions } from '../../../Permissions';
import { ReportsType } from './ReportsManagementViewComponents';
import {
  DeleteReport,
  GetAllSystemReports,
} from '../../../Services/ReportsBuilderServices';
import { GetAllRoles } from '../../../Services/roleServices';
import { SearchUsersByName } from '../../../Services/UsersServices';

const parentTranslationPath = 'ReportsBuilderg';
const translationPath = '';

export const ReportsBuilderView = () => {
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t(`ReportsBuilder`));
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: null,
    orderBy: null,
  });
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [activeItem, setActiveItem] = useState(null);
  // eslint-disable-next-line max-len
  const [orderBy, setOrderBy] = useState(
    selectedOrderBy.filterBy
      ? selectedOrderBy
      : { filterBy: null, orderBy: null }
  );
  const [IdSDelected, setIdSDelected] = useState(null);
  const [reportDto, setReportDto] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState({
    activeFormType: 0,
  });
  const [Roles, setRoles] = useState({});
  const [canAddReport, setCanAddReport] = useState(false);

  const [loadingsRoles, setLoadingsRoles] = useState(false);
  const [isOpenOption, setIsisOpenOption] = useState(false);
  const [loadingsRolesUSER, setLoadingsRolesUSER] = useState(false);
  const searchTimer = useRef(null);
  const [viewTypes, setViewTypes] = useState(ViewTypesEnum.cards.key);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
    search: '',
    createdBy: null,
    roleId: null,
    fromDate: null,
    ownerId: null,
    toDate: null,
    filterBy: selectedOrderBy.filterBy,
    orderBy: 1,
  });

  const onTypeChanged = (activeType) => {
    localStorage.setItem('ReportsBuilderView', JSON.stringify(activeType));
    setViewTypes(activeType);
    setFilter((item) => ({ ...item, pageIndex: 0 }));
  };

  const searchHandler = (search) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({ ...item, pageIndex: 0, search }));
    }, 700);
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const getGetAllSystemReports = useCallback(async () => {
    setIsLoading(true);
    if (
      returnPropsByPermissions(
        ReportBuilderPermissions.ViewSystemReports.permissionsId
      )
    ) {
      const res = await GetAllSystemReports({
        ...filter,
        pageIndex: (filter.pageIndex === 0 && 1) || filter.pageIndex + 1,
      });
      if (!(res && res.status && res.status !== 200)) setReportDto(res || []);
      else setReportDto([]);
    }
    setIsLoading(false);
  }, [filter]);
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
    //  setFilter((item) => ({ ...item, filterBy: value }));
  };

  const getRoles = useCallback(async (value) => {
    setLoadingsRoles(true);
    const response = await GetAllRoles(1, 25, value);
    setRoles({
      result: (response && response.result) || [],
      totalCount: (response && response.totalCount) || 0,
    });
    setLoadingsRoles(false);
  }, []);

  const DeleteHandler = async () => {
    setIsLoading(true);
    const res = await DeleteReport(activeItem && activeItem.systemReportId);
    if (!(res && res.status && res.status !== 200)) {
      getGetAllSystemReports();
      showSuccess(t(`Reports-deleted-successfully`));
      setIsOpen(false);
    } else {
      showError(t(`Reports-delete-failed`));
      setIsLoading(false);
      setIsOpen(false);
    }
  };
  const SaveHandler = async () => {
    if (IdSDelected !== null)
      GlobalNavigate(
        `/home/Reports-Builder/add?id=${IdSDelected.systemReportCategoryTypeId}&Report=${IdSDelected.category}&spName=${IdSDelected.spName}&systemReportCategoryTypeId=${IdSDelected.systemReportCategoryTypeId}`
      );
    else showError(t(`Reports-failed`));
  };

  const haveAddReportPermissions = () => {
    if (
      returnPropsByPermissions(
        ReportBuilderPermissions.AddContactsReport.permissionsId
      )
    )
      return true;
    else if (
      returnPropsByPermissions(
        ReportBuilderPermissions.AddLeadsReport.permissionsId
      )
    )
      return true;
    else if (
      returnPropsByPermissions(
        ReportBuilderPermissions.AddUnitsReport.permissionsId
      )
    )
      return true;
    else if (
      returnPropsByPermissions(
        ReportBuilderPermissions.AddPropertiesReport.permissionsId
      )
    )
      return true;
    else if (
      returnPropsByPermissions(
        ReportBuilderPermissions.AddActivitiesReport.permissionsId
      )
    )
      return true;
    else if (
      returnPropsByPermissions(
        ReportBuilderPermissions.AddTransactioReport.permissionsId
      )
    )
      return true;
    else if (
      returnPropsByPermissions(
        ReportBuilderPermissions.AddUserLoginHistoryReport.permissionsId
      )
    )
      return true;
    else if (
      returnPropsByPermissions(
        ReportBuilderPermissions.AddRotationSchemesReport.permissionsId
      )
    )
      return true;
    else if (
      returnPropsByPermissions(ReportBuilderPermissions.Inquiries.permissionsId)
    )
      return true;
    else if (
      returnPropsByPermissions(
        ReportBuilderPermissions.CampaignReport.permissionsId
      )
    )
      return true;

    return false;
  };

  useEffect(() => {
    if (
      returnPropsByPermissions(
        ReportBuilderPermissions.ViewSystemReports.permissionsId
      )
    ) {
      bottomBoxComponentUpdate(
        <XelePagination
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={(reportDto && reportDto.totalCount) || 0}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }
  });

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };

  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }

    setFilter((item) => ({
      ...item,
      orderBy: selectedOrderBy.orderBy,
      filterBy: selectedOrderBy.filterBy,
    }));
  };
  const changeActiveFormType = (newValue) => {
    setSelected((item) => ({
      ...item,
      activeFormType: +newValue,
    }));
  };

  const getAllUsers = useCallback(async (name) => {
    setLoadingsRolesUSER(true);
    const res = await SearchUsersByName(name || '', 0, 10);
    if (!(res && res.status && res.status !== 200)) {
      setAllUsers({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAllUsers({
        result: [],
        totalCount: 0,
      });
    }
    setLoadingsRolesUSER(false);
  }, []);

  useEffect(() => {
    getGetAllSystemReports(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    getRoles();
    getAllUsers();
    setCanAddReport(haveAddReportPermissions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='ReportsBuilderView-css'>
        <Spinner isActive={isLoading} />
        <div className=' view-wrapper d-flex-column'>
          <div className='header-section'>
            <div className='filter-section'>
              <div className='filter-ButtonBase'>
                <div className='section px-2'>
                  {canAddReport && (
                    <ButtonBase
                      id='add-new-Reports-ref'
                      onClick={() => setIsisOpenOption(true)}
                      className='btns theme-solid bg-primary'
                    >
                      <span className='mdi mdi-plus mx-1 ' />
                      {t(`add-new-Reports`)}
                    </ButtonBase>
                  )}
                </div>
                <PermissionsComponent
                  permissionsList={Object.values(ReportBuilderPermissions)}
                  permissionsId={
                    ReportBuilderPermissions.ViewSystemReports.permissionsId
                  }
                >
                  <div className='sc-search d-flex px-2'>
                    <span className='mx-2 mt-1'>{t(`select`)}</span>
                    <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
                    <span className='px-2 d-flex'>
                      <span className='texts-large mt-1'>{t(`order-by`)}:</span>
                      <div className='px-2'>
                        <XeleSelect
                          idRef='filterByRef'
                          data={[
                            { id: 'CreatedOn', filterBy: 'created-date' },
                            { id: 'UpdateOn', filterBy: 'Updated-date' },
                          ]}
                          wrapperClasses='mb-3'
                          isRequired
                          value={selectedOrderBy.filterBy}
                          onSelectChanged={filterByChanged}
                          emptyItem={{
                            value: null,
                            text: 'select-filter-by',
                            isDisabled: false,
                          }}
                          valueInput='id'
                          textInput='filterBy'
                          parentTranslationPath={parentTranslationPath}
                        />
                      </div>
                      <div className='px-2'>
                        <XeleSelect
                          idRef='orderByRef'
                          data={[
                            { id: 1, orderBy: 'ascending' },
                            { id: 2, orderBy: 'descending' },
                          ]}
                          emptyItem={{
                            value: null,
                            text: 'select-sort-by',
                            isDisabled: false,
                          }}
                          value={selectedOrderBy.orderBy}
                          onSelectChanged={orderByChanged}
                          wrapperClasses='mb-3'
                          isRequired
                          valueInput='id'
                          textInput='orderBy'
                          parentTranslationPath={parentTranslationPath}
                        />
                      </div>
                      <div className='mt-1'>
                        <ButtonBase
                          className='btns theme-solid'
                          id='action_apply'
                          onClick={orderBySubmitted}
                          disabled={
                            !selectedOrderBy.filterBy ||
                            !selectedOrderBy.orderBy
                          }
                        >
                          <span>{t(`apply`)}</span>
                        </ButtonBase>
                      </div>
                    </span>
                  </div>
                </PermissionsComponent>
              </div>
              <div />
              <PermissionsComponent
                permissionsList={Object.values(ReportBuilderPermissions)}
                permissionsId={
                  ReportBuilderPermissions.ViewSystemReports.permissionsId
                }
              >
                <div className='section autocomplete-section'>
                  <div className='Rep d-flex-column px-2 w-100 p-relative '>
                    <div className='w-100 p-relative'>
                      <div className='sc-search d-flex m2-2'>
                        <XeleInput
                          idRef='SearcReportshRef'
                          label={t(`Search-in-Reports`)}
                          // value={searchInput}
                          beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                          inputPlaceholder={t(`type-Search-in-Reports`)}
                          parentTranslationPath={parentTranslationPath}
                          onInputChanged={(e) => {
                            searchHandler(e.target.value);
                          }}
                        />
                        <div className='min-30' />
                        <XeleAutocomplete
                          isLoading={loadingsRolesUSER}
                          inputPlaceholder={
                            (selected &&
                              selected.activeFormType === 1 &&
                              t(`search-owner`)) ||
                            (selected &&
                              selected.activeFormType === 2 &&
                              t(`search-createby`)) ||
                            t(`Selact-type-user-seach`)
                          }
                          multiple={false}
                          // eslint-disable-next-line max-len
                          data={
                            (selected.activeFormType === 0 && []) ||
                            (allUsers && allUsers.result) ||
                            []
                          }
                          displayLabel={(option) =>
                            (option &&
                              `${option.firstName} ${option.lastName}`) ||
                            ''
                          }
                          chipsLabel={(option) =>
                            (option && option.userName) || ''
                          }
                          withoutSearchButton
                          onInputKeyUp={(e) => {
                            const newValue = e.target.value;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getAllUsers(newValue);
                            }, 700);
                          }}
                          inputStartAdornment={
                            <XeleSelect
                              data={[
                                {
                                  key: 0,
                                  value: 'Select',
                                },
                                {
                                  key: 1,
                                  value: 'Owner',
                                },
                                {
                                  key: 2,
                                  value: 'CreatedBy',
                                },
                              ]}
                              value={selected.activeFormType}
                              valueInput='key'
                              textInput='value'
                              onSelectChanged={changeActiveFormType}
                              wrapperClasses='over-input-select w-auto'
                              idRef='relatedToTypeRef'
                              parentTranslationPath={parentTranslationPath}
                            />
                          }
                          onChange={(event, newValue) => {
                            console.log(
                              '🚀 ~ ReportsBuilderView ~ newValue:',
                              newValue
                            );
                            if (selected.activeFormType === 1) {
                              setFilter((item) => ({
                                ...item,
                                ownerId: newValue && newValue.applicationUserId,
                              }));
                              setFilter((item) => ({
                                ...item,
                                createdBy: null,
                              }));
                            } else if (selected.activeFormType === 2) {
                              setFilter((item) => ({
                                ...item,
                                createdBy: newValue && newValue.usersId,
                              }));
                              setFilter((item) => ({ ...item, ownerId: null }));
                            }
                          }}
                        />
                      </div>
                      <div className='sc-search d-flex px-2'>
                        <span className=' mt-1' />
                        <XeleAutocomplete
                          isLoading={loadingsRoles}
                          inputPlaceholder={t(`search-Seaqrchbyrols`)}
                          multiple={false}
                          inputStartAdornment={
                            <span className='mdi mdi-playlist-play mdi-24px c-gray-primary' />
                          }
                          data={(Roles && Roles.result) || []}
                          displayLabel={(option) =>
                            (option && option.rolesName) || ''
                          }
                          chipsLabel={(option) =>
                            (option && option.rolesName) || ''
                          }
                          withoutSearchButton
                          onInputKeyUp={(e) => {
                            const newValue = e.target.value;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getRoles(newValue);
                            }, 700);
                          }}
                          onChange={(event, newValue) => {
                            // eslint-disable-next-line max-len
                            setFilter((item) => ({
                              ...item,
                              roleId: newValue && newValue.rolesId,
                            }));
                          }}
                        />
                        <div className='min-30' />
                        <XeleDateRangePicker
                          onClearClicked={() => {
                            setDateFilter(dateRangeDefault);
                            setFilter((item) => ({
                              ...item,
                              fromDate: null,
                              toDate: null,
                            }));
                          }}
                          ranges={[dateFilter]}
                          parentTranslationPath={parentTranslationPath}
                          onDateChanged={(selectedDate) => {
                            setDateFilter({
                              startDate:
                                selectedDate.selection &&
                                selectedDate.selection.startDate,
                              endDate:
                                selectedDate.selection &&
                                selectedDate.selection.endDate,
                              key: 'selection',
                            });
                            setFilter((item) => ({
                              ...item,
                              fromDate: moment(
                                selectedDate.selection &&
                                  selectedDate.selection.startDate
                              ).add(24, 'hours'),
                              toDate: new Date(
                                moment(
                                  selectedDate.selection &&
                                    selectedDate.selection.endDate
                                ).endOf('day')
                              ),
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <ViewTypes
                    onTypeChanged={onTypeChanged}
                    initialActiveType={viewTypes}
                    activeTypes={[
                      ViewTypesEnum.tableView.key,
                      ViewTypesEnum.cards.key,
                    ]}
                    className='mb-3'
                  />
                </div>
              </PermissionsComponent>
            </div>
          </div>{' '}
        </div>
      </div>
      <>
        <PermissionsComponent
          permissionsList={Object.values(ReportBuilderPermissions)}
          permissionsId={
            ReportBuilderPermissions.ViewSystemReports.permissionsId
          }
        >
          {((reportDto && reportDto.result && reportDto.result.length === 0) ||
            (reportDto && reportDto.totalCount === 0)) &&
          filter.search !== '' ? (
            <NoSearchResultComponent />
          ) : (
            (reportDto && reportDto.totalCount === 0 && '') ||
            (viewTypes === ViewTypesEnum.tableView.key && (
              <>
                <div className='ReportsBuilder-data-view-wrapers'>
                  <TablesReportsBuilder
                    reportDto={(reportDto && reportDto.result) || []}
                    reportDtoFull={reportDto && reportDto}
                    filter={filter}
                    setActiveItem={(item) => {
                      setActiveItem(item);
                    }}
                    parentTranslationPath={parentTranslationPath}
                    isOpenChanged={() => setIsOpen(true)}
                  />
                </div>
              </>
            )) || (
              <>
                <div className='ReportsBuilder-data-view-wrapers'>
                  <CardReportsBuilder
                    reportDto={reportDto && reportDto.result}
                    setActiveItem={(item) => {
                      setActiveItem(item);
                    }}
                    parentTranslationPath={parentTranslationPath}
                    isOpenChanged={() => setIsOpen(true)}
                  />
                </div>
              </>
            )
          )}
        </PermissionsComponent>
      </>
      <XeleDialog
        titleText='confirm-message'
        saveText={t(`confirm`)}
        SmothMove
        saveType='button'
        maxWidth='sm'
        dialogContent={
          <div className='d-flex-column-center'>
            <Spinner isActive={isLoading} isAbsolute />
            <span className='mdi mdi-close-octagon c-danger mdi-48px' />
            <span className='fz-18px fw-bold'>
              {`${`${t(`Reports-delete-description`)}` || ''}`}
              <span className='d-flex-center  fz-22px fw-bold mt-2'>
                {' '}
                ( {activeItem && activeItem.systemReportName} ){' '}
              </span>
            </span>
          </div>
        }
        saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
        isOpen={isOpen}
        onSaveClicked={DeleteHandler}
        onCloseClicked={() => setIsOpen(false)}
        onCancelClicked={() => setIsOpen(false)}
        parentTranslationPath={parentTranslationPath}
      />
      <XeleDialog
        titleText={t(`Report-Type`)}
        saveText={`${t(`confirm`)} ${t(`to`)} ${
          IdSDelected != null
            ? IdSDelected && IdSDelected.systemReportCategoryTypeName
            : ''
        }`}
        saveType='button'
        SmothMove
        maxWidth='lg'
        dialogContent={
          <div className='d-flex-column-center'>
            <ReportsType
              parentTranslationPath={parentTranslationPath}
              IdSdelected={(item) => setIdSDelected(item)}
            />
          </div>
        }
        saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
        isOpen={isOpenOption}
        onSaveClicked={SaveHandler}
        saveIsDisabled={IdSDelected === null}
        onCloseClicked={() => {
          setIsisOpenOption(false);
          setIdSDelected(null);
        }}
        onCancelClicked={() => {
          setIsisOpenOption(false);
          setIdSDelected(null);
        }}
        parentTranslationPath={parentTranslationPath}
      />
    </>
  );
};
