import { GlobalOrderFilterStatus } from './GlobalOrderFilterStatus';

const initialState = localStorage.getItem('GlobalFilter') ?
  JSON.parse(localStorage.getItem('GlobalFilter')) :
  {
    usersFilter : {
      filterBy: null,
      orderBy: null,
    } , 
    candidatesFilter :{
      filterBy: null,
      orderBy: null,
    }

  };

export const GlobalOrderFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GlobalOrderFilterStatus.ERROR:
    case GlobalOrderFilterStatus.REQUEST:
      return state;

    case GlobalOrderFilterStatus.SUCCESS:
      return action.payload;

    case GlobalOrderFilterStatus.RESET:
      return initialState;

    default:
      return state;
  }
};
